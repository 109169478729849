import React from "react";

export default () => (
  <div
    style={{
      backgroundColor: "#afafaf",
      height: "100px",
    }}
  />
);
