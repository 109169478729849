import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  FileInput,
  FileField,
  NumberInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import uploadProps from "../../../dataprovider/uploadProps";
import UploadProgress from "../../../components/uploadProgress";
import Toolbar from "../../../components/toolbar";
import RichTextInput from "../../../components/richInputText";

export default (props) => {
  const [progress, setProgress] = useState();
  const transform = async (data) =>
    await uploadProps(data, setProgress, "WORKSHEETS");
  return (
    <>
      <Create {...props} transform={transform}>
        <SimpleForm
          redirect={`/worksheets`}
          toolbar={<Toolbar disabled={!!progress} />}
        >
          <ReferenceInput
            label="Category"
            source="category_id"
            reference="categories"
            perPage={9999}
            filter={{ is_worksheet: 1 }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="title" validate={required()} />
          <FileInput source="file" label="File" validate={required()}>
            <FileField source="src" title="title" />
          </FileInput>
          <RichTextInput
            source="description"
            title="Description"
            validate={required()}
          />
          <NumberInput source="priority" />
        </SimpleForm>
      </Create>
      <UploadProgress progress={progress} />
    </>
  );
};
