import React from "react";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  card: {
    marginBottom: "5px",
    marginRight: "5px",
  },
}));

export default ({ records }) => {
  const classes = useStyles();
  return records.map((remnant) => (
    <Card className={classes.card} key={remnant.id}>
      <CardContent>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1}>
            <Typography variant="p" component="p">
              {remnant.remnant_id}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  ));
};
