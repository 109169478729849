/* eslint-disable camelcase */
import React from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import EnhancedTableToolbar from "./subPurchasesToolbar";
import DeleteButton from "../../../../components/deleteButton";

const EnhancedTableHead = ({ onSelectAllClick, numSelected, rowCount }) => (
  <TableHead>
    <TableRow>
      <TableCell align="left" padding="default">
        ITEM
      </TableCell>
      <TableCell align="left" padding="default">
        HSN
      </TableCell>
      <TableCell align="left" padding="default">
        QUANTITY
      </TableCell>
      <TableCell align="left" padding="default">
        RATE
      </TableCell>
      <TableCell align="left" padding="default">
        TOTAL
      </TableCell>
    </TableRow>
  </TableHead>
);

export default ({ records, purchase_id, ...props }) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = records.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          purchase_id={purchase_id}
          {...props}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={records.length}
            />

            <TableBody>
              {records.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow
                    className={row.is_active && classes.inactiveRow}
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell align="left">{row.item}</TableCell>
                    <TableCell align="left">{row.hsn}</TableCell>
                    <TableCell align="left">{row.quantity}</TableCell>
                    <TableCell align="left">{row.rate}</TableCell>
                    <TableCell align="left">{row.total}</TableCell>

                    <TableCell align="left">
                      <DeleteButton resource="sub_purchases" id={row.id} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  inactiveRow: {
    background: "#004d40",
  },
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: "#1e2052",
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  imageBox: {
    maxWidth: "100px",
    maxHeight: "100px",
  },
  textMuted: {
    color: "#afafaf",
  },
}));
