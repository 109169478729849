import React, { useState, useEffect } from "react";
import {
  Datagrid,
  Filter,
  List,
  NumberField,
  Responsive,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  TopToolbar,
} from "react-admin";

import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BulkUpdateButton from "../../../components/bulkUpdateButton";
import PDFButton from "../../../components/pdfButton";
import OrderCreateButton from "./createButton";

const OrderFilter = ({ ...props }) => (
  <Filter {...props}>
    <TextInput source="id" label="Order Id" alwaysOn />
  </Filter>
);

const tabs = [
  { id: "pending", name: "PENDING" },
  { id: "preparing", name: "PREPARING" },
  { id: "on_hold", name: "ON_HOLD" },
  { id: "ready_for_dispatch", name: "READY_FOR_DISPATCH" },
  { id: "dispatched", name: "DISPATCHED" },
  { id: "delivered", name: "DELIVERED" },
];

function TabbedDatagrid(props) {
  const [states, setStates] = useState({
    pending: [],
    preparing: [],
    ready_for_dispatch: [],
    dispatched: [],
    on_hold: [],
    delivered: [],
  });

  function applyStates() {
    setStates({ ...states, [props.filterValues.status]: props.ids });
  }

  useEffect(() => {
    if (props.ids !== states[props.filterValues.status]) {
      applyStates();
    }
  });

  function handleChange(event, value) {
    const { filterValues, setFilters } = props;
    setFilters({ ...filterValues, status: value });
  }
  const { filterValues, resource } = props;
  const initValues =
    JSON.parse(localStorage.getItem(`${resource}_count`)) || {};

  return (
    <>
      <Tabs
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={`${choice.name} (${initValues[choice.name] || 0})`}
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <Responsive
        medium={
          <div>
            <DataGrid {...props} ids={states[filterValues.status]} />
          </div>
        }
      />
    </>
  );
}

function PostBulkActionButtons(props) {
  const { filterValues } = props;
  const status = { order_status: nextStatus(filterValues.status) };
  const currentStatus = filterValues.status.toUpperCase();
  return (
    <>
      {currentStatus === "PREPARING" && (
        <>
          <PDFButton
            label="Invoice"
            resource="orders"
            name="invoice"
            {...props}
          />
        </>
      )}
      {(currentStatus === "READY_FOR_DISPATCH" ||
        currentStatus === "DISPATCHED") && (
        <>
          <PDFButton
            label="Label"
            resource="orders"
            name="shipping-label"
            {...props}
          />
          <PDFButton
            label="Invoice"
            resource="orders"
            name="invoice"
            {...props}
          />
        </>
      )}
      {currentStatus !== "DELIVERED" && (
        <BulkUpdateButton label="Proceed" {...props} status={status} />
      )}
    </>
  );
}

export default function OrderList(props) {
  return (
    <List
      {...props}
      actions={<OrderActions {...props} />}
      filterDefaultValues={{ status: "preparing" }}
      sort={{ field: "updated_at", order: "DESC" }}
      perPage={25}
      filters={<OrderFilter />}
      bulkActionButtons={<PostBulkActionButtons {...{ status: "PREPARING" }} />}
    >
      <TabbedDatagrid />
    </List>
  );
}

const nextStatus = (current) => {
  const curr = current.toUpperCase();
  if (curr === "PENDING") return "PREPARING";
  if (curr === "PREPARING") return "READY_FOR_DISPATCH";
  if (curr === "ON_HOLD") return "READY_FOR_DISPATCH";
  if (curr === "READY_FOR_DISPATCH") return "DISPATCHED";
  if (curr === "DISPATCHED") return "DELIVERED";
};

const OrderActions = (props) => (
  <TopToolbar>
    <OrderCreateButton withGst label="Create Order" {...props} />
  </TopToolbar>
);

const DataGrid = (props, id) => (
  <Datagrid {...props}>
    <TextField source="id" />

    <TextField source="billing_name" label="Customer" />
    <NumberField
      source="sub_total"
      options={{ style: "currency", currency: "INR" }}
    />
    <NumberField
      source="shipping"
      options={{ style: "currency", currency: "INR" }}
    />
    <NumberField
      source="discount"
      options={{ style: "currency", currency: "INR" }}
    />
    <NumberField
      source="total"
      options={{ style: "currency", currency: "INR" }}
    />
    <TextField source="created_at" />
    <TextField source="delivery_date" />
    <ShowButton />
    <EditButton />
  </Datagrid>
);
