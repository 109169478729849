import Icon from "@material-ui/icons/LocalGroceryStore";
import List from "./list";
import Edit from "./edit";

export default {
  list: List,
  edit: Edit,
  icon: Icon,
  label: "Purchases",
  options: { label: "Purchases" },
  name: "purchases",
};
