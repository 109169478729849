import * as React from "react";
import { Fragment, useState } from "react";
import { Button, Confirm, useNotify, useUnselectAll } from "react-admin";
import { apiUrl } from "../constants";
import httpClient from "../dataprovider/httpClient";

const BulkUpdateButton = ({ selectedIds }) => {
  const [open, setOpen] = useState(false);

  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const logout = () => {
    httpClient(`${apiUrl}/logoutUsers`, {
      method: "POST",
      body: JSON.stringify({ selectedIds }),
    }).then(() => {
      unselectAll();
      notify("Logged out");
    });
  };
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = async () => {
    await logout();
    setOpen(false);
  };

  return (
    <Fragment>
      <Button label="Logout" onClick={handleClick} />
      <Confirm
        isOpen={open}
        title="Logout Users"
        content="Are you sure you want to logout user(s)?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default BulkUpdateButton;
