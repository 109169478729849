/* eslint-disable no-return-await */
import React, { useState } from "react";
import {
  Edit,
  NumberInput,
  TextInput,
  BooleanInput,
  FileInput,
  FileField,
  TabbedForm,
  FormTab,
  required,
  FormDataConsumer,
  ReferenceManyField,
  ReferenceArrayInput,
  SelectArrayInput,
  TopToolbar,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import uploadProps from "../../../dataprovider/uploadProps";
import RichTextInput from "../../../components/richInputText";
import Toolbar from "../../../components/toolbar";
import UploadProgress from "../../../components/uploadProgress";
import VariantButton from "../productVariant/createButton";
import FieldButton from "../productField/createButton";
import VariantList from "../productVariant/list";
import FieldList from "../productField/list";
import CategoryReferenceInput from "../../../components/createButton";
import SubCatReferenceInput from "../subCategory/createButton";

const ProductEditActions = (props) => (
  <TopToolbar>
    <VariantButton {...props} />
    <FieldButton {...props} />
  </TopToolbar>
);

const useStyles = makeStyles((theme) => ({
  fullWidth: { width: "100%" },
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
}));

export default ({ history, ...props }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState();
  const transform = async (data) =>
    await uploadProps(data, setProgress, "PRODUCTS");
  return (
    <>
      <Edit
        {...props}
        undoable={false}
        actions={<ProductEditActions history={history} />}
        transform={transform}
      >
        <TabbedForm
          redirect={false}
          toolbar={<Toolbar disabled={!!progress} />}
        >
          <FormTab label="Details">
            <CategoryReferenceInput
              source="category_id"
              reference="categories"
              perPage={9999}
              field="name"
            />
            <FormDataConsumer>
              {({ formData }) =>
                formData.category_id && (
                  <SubCatReferenceInput
                    source="sub_category_id"
                    reference="sub_categories"
                    resource="sub_categories"
                    perPage={9999}
                    filter={{ categories_id: formData.category_id }}
                    field="name"
                  />
                )
              }
            </FormDataConsumer>
            <TextInput source="title" validate={required()} />
            <TextInput
              source="supc"
              label="SUPC (eg. NAMEPLATE01)"
              formClassName={classes.inlineBlock}
              validate={required()}
            />
            <NumberInput
              source="stock"
              formClassName={classes.inlineBlock}
              validate={required()}
            />
            <NumberInput
              source="cost_price"
              label="Cost Price(Rs)"
              formClassName={classes.inlineBlock}
              validate={required()}
            />
            <NumberInput
              source="selling_price"
              label="Selling Price(Rs)"
              validate={required()}
            />
            <NumberInput source="gst" label="GST(%)" validate={required()} />
            <FormDataConsumer>
              {({ formData }) => (
                <TextInput
                  label={"SP+GST (Rs)"}
                  value={Math.round(
                    (formData.selling_price || 0) *
                      (1 + (formData.gst || 0) / 100)
                  )}
                  disabled
                />
              )}
            </FormDataConsumer>
            <NumberInput
              source="mrp"
              label="MRP(Rs)"
              formClassName={classes.inlineBlock}
              validate={required()}
            />
            <TextInput source="hsn" validate={required()} />
            <NumberInput source="priority" validate={required()} />
            <BooleanInput source="is_enabled" label="Activate" />
            <BooleanInput source="on_website" label="Show on Website" />
            <BooleanInput source="show_stock" label="Show Stock on Website" />
          </FormTab>
          <FormTab label="Image">
            {[1, 2, 3, 4, 5, 6, 7].map((i) => [
              <FileInput
                source={`image_${i}`}
                label={`image ${i}`}
                accept="image/*"
                placeholder={<p>Drop your file here</p>}
                key={`${i}.1`}
              >
                <FileField source="src" title="title" />
              </FileInput>,
              <FormDataConsumer key={`${i}.2`}>
                {({ formData }) =>
                  formData[`image_${i}`] &&
                  typeof formData[`image_${i}`] === "string" && (
                    <img
                      src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${
                        formData[`image_${i}`]
                      }`}
                      alt={formData[`image_${i}`]}
                    />
                  )
                }
              </FormDataConsumer>,
            ])}
            <TextInput source="video" label="Video Id" />
          </FormTab>
          <FormTab label="Description">
            <RichTextInput
              source="detailed_description"
              title="Detailed Description"
              validate={required()}
            />
            <RichTextInput source="contents" title="Contents in the box" />
            <RichTextInput
              source="s_description"
              title="Special Need Description"
            />
            <ReferenceArrayInput
              perPage={99999}
              source="tags"
              reference="tags"
              label="Tags"
            >
              <SelectArrayInput optionText="id" />
            </ReferenceArrayInput>
          </FormTab>
          <FormTab label="Size">
            <NumberInput source="width" label="Width (in)" />
            <NumberInput source="height" label="Height (in)" />
            <NumberInput source="weight" label="Weight (gm)" />
            <TextInput source="size" label="Any Other Size" />
          </FormTab>
          <FormTab label="Fields">
            <ReferenceManyField
              className={classes.fullWidth}
              label=" "
              reference="product_fields"
              target="product_id"
              sort={{ field: "priority", order: "ASC" }}
            >
              <FieldList />
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Variants">
            <ReferenceManyField
              className={classes.fullWidth}
              label=" "
              reference="product_variants"
              target="variant_id"
            >
              <VariantList />
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>
      </Edit>
      <UploadProgress progress={progress} />
    </>
  );
};
