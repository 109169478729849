import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Pagination,
  SelectInput,
  TextInput,
  Filter,
  NumberInput,
} from "react-admin";
import BulkUpdate from "./bulkUpdate";
const ProductPagination = (props) => <Pagination rowsPerPageOptions={[50, 100, 200]} {...props} />;

const ProductFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      source="product_type"
      choices={[
        { id: "3D_Board_Market_Quality", name: "3D_Board_Market_Quality" },
        {
          id: "3D_Board_Premium_Quality",
          name: "3D_Board_Premium_Quality",
        },
        { id: "Groove_Boards", name: "Groove_Boards" },
        { id: "Full_Sheet_Jaali", name: "Full_Sheet_Jaali" },
        { id: "Square_Jaali", name: "Square_Jaali" },
        { id: "Circle_Jaali", name: "Circle_Jaali" },
        { id: "Nature_Jaali", name: "Nature_Jaali" },
      ]}
      alwaysOn
    />
    <TextInput source="material_type" alwaysOn />
    <NumberInput source="material_thickness" alwaysOn />
  </Filter>
);
const ProductBulkActionButtons = (props) => <BulkUpdate {...props} />;

export default (props) => (
  <List
    {...props}
    perPage={50}
    pagination={<ProductPagination />}
    filters={<ProductFilter />}
    bulkActionButtons={<ProductBulkActionButtons />}
  >
    <Datagrid rowClick="edit">
      <TextField source="product_type" />
      <TextField source="design" />
      <TextField source="material_type" label="Type " />
      <NumberField
        source="material_thickness"
        label="Thickness"
        options={{ style: "unit", unit: "millimeter" }}
      />
      <TextField source="hsn" label="HSN" />
      <NumberField source="dimension_type" />
      <NumberField source="width" options={{ style: "unit", unit: "inch" }} />
      <NumberField source="height" options={{ style: "unit", unit: "inch" }} />
      <NumberField source="price" options={{ style: "currency", currency: "INR" }} />
    </Datagrid>
  </List>
);
