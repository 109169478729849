import Edit from "./edit";
import List from "./list";

export default {
  edit: Edit,
  list: List,
  label: "Invoices",
  options: { label: "Invoices" },
  name: "job_invoices",
};

export const editJobInvoice = {
  edit: Edit,
  name: "edit_job_invoices",
};
