// import React from "react";

// export default () => {

// };

import React, { useEffect, useState } from "react";
import MuiGridList from "@material-ui/core/GridList";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { apiHelper } from "../../../dataprovider/uploadProps";

const useStyles = makeStyles((theme) => ({
  gridList: {
    marginTop: 10,
    borderBottom: "1px solid #fff",
  },
  tileBar: {
    background: "",
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: "100%",
  },
  price: {
    display: "inline",
    fontSize: "1em",
  },
  link: {
    color: "#fff",
  },
  tile: {
    padding: theme.spacing(2),
    maxHeight: "350px",
  },
}));

const getColsForWidth = (width) => {
  if (width === "xs") return 3;
  if (width === "sm") return 4;
  if (width === "md") return 5;
  if (width === "lg") return 6;
  return 5;
};

const LoadedGridList = ({ width, clientId }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (clientId) {
      apiHelper(`client-list?clientId=${clientId}`, {}, "GET")
        .then((resp) => {
          console.log(resp);
          setData(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [clientId]);

  if (data.length === 0)
    return (
      <Typography variant="h5" style={{ padding: "3rem 1rem" }}>
        No Lists Found
      </Typography>
    );
  return (
    <Box width={"calc(100% - 1em)"}>
      {data.map((d) => (
        <MuiGridList cellHeight={390} cols={getColsForWidth(width)} className={classes.gridList}>
          <div
            style={{
              width: "100%",
              maxHeight: "30px",
              padding: "2rem",
              margin: "10px 0",
              backgroundColor: "#303030",
            }}
          >
            <Typography variant="h5">{d.name}</Typography>
          </div>
          {d.list_items.length > 0
            ? d.list_items.map((id) => {
                const { asset } = id || {};
                return (
                  <div style={{ width: "300px" }}>
                    <div
                      style={{
                        height: "300px",
                        width: "300px",
                      }}
                    >
                      <a href={`#/assets/${asset.id}`}>
                        <img
                          src={asset.imagev2}
                          alt={asset.name}
                          style={{ maxHeight: "100%", maxWidth: "100%" }}
                        />
                      </a>
                    </div>
                    <div
                      style={{
                        height: "50px",
                        width: "300px",
                      }}
                    >
                      <Typography variant="body2" gutterBottom style={{ color: "white" }}>
                        {" "}
                        {asset.name}
                      </Typography>
                      <Typography variant="body1" gutterBottom style={{ color: "white" }}>
                        {asset.id}
                      </Typography>

                      <br />
                    </div>
                  </div>
                );
              })
            : null}
        </MuiGridList>
      ))}
    </Box>
  );
};

export default withWidth()(LoadedGridList);
