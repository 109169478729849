import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: "red",
  },
}));

export const TextFieldAdapter = ({ input, options, meta, label, multiple, ...rest }) => {
  const classes = useStyles();
  return (
    <TextField
      {...input}
      {...rest}
      onChange={(e) => input.onChange(e.target.value)}
      className={classes.formControl}
      errorText={meta.touched ? meta.error : ""}
      label={label}
      variant="filled"
    />
  );
};
