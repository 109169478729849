import React, { useState } from "react";
import {
  Button,
  useUpdateMany,
  useUnselectAll,
  useNotify,
  useRefresh,
} from "react-admin";
import IconSave from "@material-ui/icons/Save";
import IconCancel from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

function ConfirmButton({ resource, selectedIds, options }) {
  const [showDialog, setShowDialog] = useState(false);
  const [status, setStatus] = useState();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const classes = useStyles();
  const handleChange = (e) => {
    setStatus(e.target.value);
  };
  const handleClick = () => {
    setShowDialog(true);
  };
  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const [updateMany, { loading }] = useUpdateMany(
    resource,
    selectedIds,
    { status },
    {
      onSuccess: () => {
        refresh();
        notify("Updated");
        unselectAll(resource);
      },
      onFailure: (error) => notify("Error: Not updated", "warning"),
    }
  );
  return (
    <>
      <Button onClick={handleClick} label="Proceed" />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Change Status"
      >
        <DialogTitle>Change Status</DialogTitle>
        <DialogContent>
          <Select
            value={status || ""}
            onChange={handleChange}
            inputProps={{
              name: "status",
              id: "status",
            }}
            className={classes.select}
            variant="filled"
          >
            {options.map((o) => (
              <MenuItem key={o.id} value={o.id}>
                {o.name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleCloseClick}
            disabled={loading}
            className={classes.button}
          >
            <IconCancel />
          </Button>
          <Button
            label="ra.action.confirm"
            onClick={updateMany}
            variant="contained"
            disabled={!status}
            className={classes.button}
          >
            <IconSave />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    height: "50px",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default ConfirmButton;
