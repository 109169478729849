import React from "react";
import { Create, TextInput, SimpleForm, NumberInput, SelectInput } from "react-admin";

export default (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <SelectInput
        source="product_type"
        choices={[
          { id: "3D_Board_Market_Quality", name: "3D_Board_Market_Quality" },
          {
            id: "3D_Board_Premium_Quality",
            name: "3D_Board_Premium_Quality",
          },
          { id: "Groove_Boards", name: "Groove_Boards" },
          { id: "Full_Sheet_Jaali", name: "Full_Sheet_Jaali" },
          { id: "Square_Jaali", name: "Square_Jaali" },
          { id: "Circle_Jaali", name: "Circle_Jaali" },
          { id: "Nature_Jaali", name: "Nature_Jaali" },
        ]}
      />

      <TextInput source="design" />
      <TextInput source="material_type" />
      <NumberInput source="material_thickness" label="Thickness (mm)" />
      <TextInput
        source="hsn"
        format={(v) => (v ? v.toUpperCase() : "")}
        parse={(v) => (v ? v.toUpperCase() : "")}
      />
      <SelectInput
        source="dimension_type"
        choices={[
          { id: "in", name: "in" },
          {
            id: "mm",
            name: "mm",
          },
        ]}
      />
      <NumberInput source="width" label="Width" />
      <NumberInput source="height" label="Height" />
      <NumberInput source="price" />
    </SimpleForm>
  </Create>
);
