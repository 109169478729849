import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useUpdate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
const styles = () => ({
  colorSwitchBase: {
    color: red[500],
    "&$colorChecked": {
      color: green[500],
      "& + $colorBar": {
        backgroundColor: green[500],
      },
    },
  },
  colorBar: {
    color: red[500],
  },
  colorChecked: {
    color: green[500],
  },
});
const ClientToggle = ({ data, classes }) => {
  const [update] = useUpdate();

  const [full, setFull] = useState(data.full_access);

  const updateFunc = (id, diff, prevData = {}) => {
    update("clients", id, diff, prevData);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={full}
          onChange={() => {
            updateFunc(data.id, { full_access: !full }, {});
            setFull(!full);
          }}
          value="checkedB"
          classes={{
            switchBase: classes.colorSwitchBase,
            checked: classes.colorChecked,
            bar: classes.colorBar,
          }}
          color="primary"
        />
      }
      label={<span style={{ fontSize: 15 }}>Full Access</span>}
      style={{ color: "white" }}
    />
  );
};

export default withStyles(styles)(ClientToggle);
