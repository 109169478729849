import React, { useState, useRef } from "react";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/core/styles";
import Viewer from "react-viewer";
import ResourceButton from "../../resource/createButton";
import attachment from "./attachment.jpg";
import DeleteButton from "../../../../components/deleteButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "360px",
    padding: theme.spacing(2),
  },
  img: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
export default ({ records, title, id, category }) => {
  const [open, setOpen] = useState(false);
  const [curr, setCurr] = useState(0);
  const Ref = useRef();

  const toggle = (val) => {
    setOpen(val);
  };
  const handleToggle = (i) => {
    setCurr(i);
    toggle(true);
  };
  const classes = useStyles();
  const permissions =
    (localStorage.getItem("access") || "") !== "jaali_production";
  return (
    <Card className={classes.root} ref={Ref} id="Master">
      <Viewer
        visible={open}
        onClose={() => toggle(false)}
        activeIndex={curr}
        images={records.map((res) => ({
          src: `https://drive.google.com/thumbnail?authuser=0&sz=w1100&id=${res.resource}`,
          alt: res.name,
        }))}
      />

      <Box display={{ xs: "flex" }}>
        <Box flexGrow={1}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            {title}
          </Typography>
        </Box>
        <Box>
          <ResourceButton id={id} category={category} />
        </Box>
      </Box>
      {records.map((row, i) => (
        <>
          <Box display={{ xs: "block", sm: "flex" }} key={row.name}>
            <Box flex={4}>
              <Typography variant="caption" display="block">
                <b>{row.name}</b>
              </Typography>
              <Typography variant="subtitle1" display="block">
                <b>{moment(row.created_at).format("lll")}</b>
              </Typography>
            </Box>
            <Box flex={0.5}>
              <IconButton
                color="primary"
                href={`https://drive.google.com/thumbnail?authuser=0&sz=w700&id=${row.resource}`}
                
                size="small"
              >
                <DownloadIcon />
              </IconButton>
            </Box>
            {permissions && (
              <Box flex={0.5}>
                <DeleteButton resource="resources" id={row.id} />
              </Box>
            )}
          </Box>
          <Box display={{ xs: "block", sm: "flex" }} key={row.name}>
            <Box flex={1}>
              {row.type !== "FILE" ? (
                <Button onClick={() => handleToggle(i)}>
                  <img
                    src={`https://drive.google.com/thumbnail?authuser=0&sz=w350&id=${row.resource}`}
                    alt={row.resource}
                    width="100%"
                  />
                </Button>
              ) : (
                <Button
                  href={`https://drive.google.com/thumbnail?authuser=0&sz=w700&id=${row.resource}`}
                >
                  <img src={attachment} alt={row.resource} height="100px" />
                </Button>
              )}
            </Box>
          </Box>
          <Divider />
        </>
      ))}
    </Card>
  );
};
