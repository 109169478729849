/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
// /* eslint-disable no-unused-vars */
import React from "react";
import { AgGridReact as List, AgGridColumn as Field } from "ag-grid-react";
import { EditButton, useListContext, useNotify } from "react-admin";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { callApi } from "../../dataprovider/miscApis";
// import { callApi } from "../../dataprovider/miscApis";

export default () => {
  const { data, rowHeight, fields, hasEdit, resource } = useListContext();
  if (!data) return null;

  const notify = useNotify();

  const getWidth = () => {
    const values = {
      xs: 0,
      sm: 580,
      md: 900,
      lg: 1200,
      xl: 1800,
    };
    return values.lg;
  };
  const rowsData = Object.values(data).sort((a, b) => a.priority - b.priority);

  const handleUpdate = async (orderedData) => {
    await callApi(`${resource}_order`, { orderedData })
      .then(() => {})
      .catch((err) => {
        notify(err.message, { type: "warning" });
      });
  };
  const onRowDragEnd = (e) => {
    const d = e.api.rowModel.rowsToDisplay.map((r) => r.data.id);
    handleUpdate(d);
  };
  const EditButtonComp = ({ data: record }) => <EditButton resource={resource} record={record} />;
  const rowStyle = { background: "black" };
  return (
    <div className="ag-theme-material" style={{ height: "calc(100vh)", width: `${getWidth()}px` }}>
      <List
        defaultColDef={{
          initialWidth: 220,
          resizable: true,
        }}
        rowData={rowsData}
        suppressMoveWhenRowDragging={false}
        suppressRowHoverHighlight
        animateRows
        rowDragManaged
        onRowDragEnd={onRowDragEnd}
        rowHeight={rowHeight}
        rowStyle={rowStyle}
        autoHeight
      >
        {fields.map(({ source, label, ...fieldProps }) => (
          <Field field={source} headerName={label} key={source} {...fieldProps} />
        ))}
        {hasEdit && <Field headerName="Action" cellRendererFramework={EditButtonComp} />}
      </List>
    </div>
  );
};
