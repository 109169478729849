import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  TextInput,
  SimpleForm,
  EditButton,
} from "react-admin";
import Icon from "@material-ui/icons/NoteAdd";

const NoteList = (props) => (
  <>
    <List {...props}>
      <Datagrid>
        <TextField source="note" />
        <EditButton />
      </Datagrid>
    </List>
  </>
);

const NoteCreate = (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm redirect="/notes">
      <TextInput source="note" multiline fullWidth />
    </SimpleForm>
  </Create>
);

const NoteEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect="/notes">
      <TextInput source="note" multiline fullWidth />
    </SimpleForm>
  </Edit>
);

export default {
  list: NoteList,
  edit: NoteEdit,
  create: NoteCreate,
  icon: Icon,
  label: "Notes",
  options: { label: "Notes" },
  name: "notes",
};
