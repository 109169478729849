import React, { useState } from "react";
import {
  Button,
  SaveButton,
  useNotify,
  useRefresh,
  FormWithRedirect,
  useUpdate,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import { makeStyles } from "@material-ui/core/styles";
import uploadProps from "../../../dataprovider/uploadProps";
import UploadProgress from "../../../components/uploadProgress";
import ImageInput from "../../../components/imageInput";

function ResourceQuickCreateButton({ data, id }) {
  const [progress, setProgress] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [update, { loading }] = useUpdate("jobs", id);

  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    const d = await uploadProps(values, setProgress, "JOBS");
    update(
      { payload: { data: { ...data, image: d.image } } },
      {
        onSuccess: () => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ({ err }) => {
          notify(err.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="Cover Image">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Resource"
      >
        <DialogTitle>Add Cover Image</DialogTitle>

        <FormWithRedirect
          resource="jobs"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <ImageInput source="image" />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
      <UploadProgress progress={progress} />
    </>
  );
}

export default ResourceQuickCreateButton;
