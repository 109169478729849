import React from "react";
import { Edit, SimpleForm, required, TextInput } from "react-admin";

export default (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect="/areas" margin="none">
      <TextInput source="id" label="name" validate={required()} />
    </SimpleForm>
  </Edit>
);
