import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import ClientToggle from "./clientToggle";
const optionRenderer = (choice) => {
  if (!choice.phone) {
    return "";
  }
  return `${choice.first_name} ${choice.last_name} ${choice.phone}`;
};
const ClientFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="client_id" reference="clients" perPage={10} fullWidth alwaysOn>
      <AutocompleteInput optionText={optionRenderer} resettable />
    </ReferenceInput>
  </Filter>
);

const TagsField = ({ record }) => {
  return <ul>{record.title && record.title.map((item) => <li key={item}>{item}</li>)}</ul>;
};
const postRowStyle = (record, index) => ({
  backgroundColor: record.source === "APP" ? "#2e5a29aa" : "",
});
export default (props) => (
  <List {...props} filters={<ClientFilter />}>
    <Datagrid rowStyle={postRowStyle} rowClick="edit">
      <FunctionField
        source="first_name"
        label="Name"
        render={({ first_name, last_name }) => `${first_name} ${last_name || ""}`}
      />

      <TextField source="firm_name" />
      <TextField source="phone" />
      <TextField source="email" />
      <TextField source="source" />
      <TagsField source="title" />
      <FunctionField
        source="full_access"
        label="Access"
        render={(data) => (
          <>
            <ClientToggle data={data} />
          </>
        )}
      />
      {/* <BooleanField source="full_access" /> */}
      <EditButton />
    </Datagrid>
  </List>
);
