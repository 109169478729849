import React from "react";
import { Datagrid, TextField, NumberField, FunctionField, Button } from "react-admin";
import PrintIcon from "@material-ui/icons/Print";

export default (props) => (
  <Datagrid {...props}>
    <TextField source="job_id" />
    <FunctionField
      label="Name"
      render={(record) =>
        record.job &&
        record.job.client &&
        `${record.job.client.first_name} ${record.job.client.last_name || ""}`
      }
    />
    <NumberField source="total" options={{ style: "currency", currency: "INR" }} />
    <NumberField source="shipping" options={{ style: "currency", currency: "INR" }} />
    <NumberField source="pack" label="Packaging" options={{ style: "currency", currency: "INR" }} />
    <NumberField source="misc" label="Miscellaneous" options={{ style: "currency", currency: "INR" }} />
    <NumberField source="discount" options={{ style: "currency", currency: "INR" }} />
    <NumberField source="gst" label="GST@18" options={{ style: "currency", currency: "INR" }} />
    <NumberField source="grand_total" options={{ style: "currency", currency: "INR" }} />
    <TextField source="created_at" label="Date" />
    <FunctionField
      render={(record) => (
        <Button
          onClick={() => {
            window.open(`/#/print-quotation?id=${record.id}`);
          }}
        >
          <PrintIcon />
        </Button>
      )}
    />
  </Datagrid>
);
