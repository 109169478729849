/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import axios from "axios";
import { apiUrl } from "../constants";
import httpClient from "./httpClient";

export default async (data, setProgress, resource) => {
  const obj = data;
  const gCreds = await httpClient(`${apiUrl}/g-token?resource=${resource}`).then(
    ({ json }) => json
  );
  for (const key of uploadKeys) {
    if (obj[key] instanceof Object) {
      setProgress({ name: obj[key].title, value: 0 });
      const file = await convertFileToBase64(obj[key]);
      obj[key] = await gUpload(file, obj[key].title, gCreds, setProgress);
    }
  }
  return obj;
};

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const gUpload = async (file, title, gCreds, setProgress) => {
  const blob = b64toBlob(file.replace(/^.*,/, ""), file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]);

  const { token, folder } = gCreds;

  const metadata = {
    name: title,
    parents: [folder],
  };
  const form = new FormData();
  form.append("metadata", new Blob([JSON.stringify(metadata)], { type: "application/json" }));
  form.append("file", blob);
  return axios
    .request({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "post",
      url: "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id",
      data: form,
      onUploadProgress: (p) => {
        setProgress({ name: title, value: (p.loaded / p.total) * 100 });
      },
    })
    .then(({ data }) => {
      setProgress();
      return data.id;
    });
};

export const downloadALL = async () => {
  const gCreds = await httpClient(`${apiUrl}/g-token?resource=ADVERTISEMENTS`).then(
    ({ json }) => json
  );
  await downloadImages(gCreds);
};

export const downloadImages = async (gCreds) => {
  const { token } = gCreds;
  const id = "1TqJretgnPtmIP_JbL-5opZtDvppH9Gza";
  return axios
    .request({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "get",
      url: `https://www.googleapis.com/drive/v3/files?q=%22${id}%22%20in%20parents&pageSize=1000`,
      responseType: "blob",
    })
    .then((response) => {
      // create file link in browser's memory
      console.log(response.data);
      // const href = URL.createObjectURL(response.data);
      // const fileExtension = mime.extension(response.data.type);
      // // create "a" HTML element with href to file & click
      // const link = document.createElement("a");
      // link.href = href;
      // link.setAttribute("download", `${id}.${fileExtension}`); //or any other extension
      // document.body.appendChild(link);
      // link.click();

      // // clean up "a" element & remove ObjectURL
      // document.body.removeChild(link);
      // URL.revokeObjectURL(href);
      // return `${id}.${fileExtension}`;
    });
};

const uploadKeys = [
  "resource",
  "image_1",
  "image_2",
  "image_3",
  "image_4",
  "image_5",
  "image_6",
  "image_7",
  "image",
  "file",
];

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    console.log(file.rawFile);
    reader.readAsDataURL(file.rawFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const uploadMedia = async (file, location) => {
  const formData = new FormData();
  formData.append("location", location);
  formData.append("file", file.rawFile);
  // formData.append("_method", "PUT");

  const token = localStorage.getItem("admin_token");
  return axios({
    method: "post",
    url: `${apiUrl}/media/upload`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${token}`,
    },
  }).then((response) => response.data);
};

export const apiHelper = async (location, data, method = "POST") => {
  const token = localStorage.getItem("admin_token");
  return axios({
    method: method,
    url: `${apiUrl}/${location}`,
    data,
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  }).then((response) => response.data);
};
