import React, { useState } from "react";
import {
  required,
  Button,
  SaveButton,
  useCreate,
  useNotify,
  useRefresh,
  FormWithRedirect,
  NumberInput,
  TextInput,
} from "react-admin";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteButton from "../../../components/deleteButton";

function ImageQuickCreateButton({ id, history, transports }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("job_transports");
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: { ...values, job_id: id } } },
      {
        onSuccess: () => {
          refresh();
          // history.push(`/jobs/${id}`);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };
  const classes = useStyles();
  return (
    <>
      <Button
        onClick={handleClick}
        label={`Transport (${transports && transports.length})`}
      ></Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Transport Entry"
      >
        <DialogTitle>Create Transport Entry</DialogTitle>

        <FormWithRedirect
          resource="job_transports"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <Transports transports={transports} />
                <TextInput
                  source="delivery_person"
                  fullWidth
                  className={classes.input}
                  validate={required()}
                />
                <TextInput
                  source="from"
                  fullWidth
                  className={classes.input}
                  validate={required()}
                />
                <TextInput
                  source="to"
                  className={classes.input}
                  validate={required()}
                />
                <NumberInput
                  source="amount"
                  className={classes.input2}
                  validate={required()}
                />
                <TextInput source="comment" className={classes.input2} />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

const Transports = ({ transports }) => {
  const classes = useStyles();
  if (transports.length === 0) {
    return null;
  }
  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableRow>
          <TableCell>Delivery Person</TableCell>
          <TableCell>From</TableCell>
          <TableCell>To</TableCell>
          <TableCell align="right">Amount</TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transports.map((row) => (
          <TableRow key={row.id}>
            <TableCell component="th" scope="row">
              {row.delivery_person}
            </TableCell>
            <TableCell>{row.from}</TableCell>
            <TableCell>{row.to}</TableCell>
            <TableCell align="right">{row.amount}</TableCell>
            <TableCell align="right">
              <DeleteButton resource="job_transports" id={row.id} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    width: "31%",
    marginRight: "8px",
  },
  input2: {
    width: "48%",
    marginRight: "8px",
  },
  table: { marginBottom: theme.spacing(8) },
}));

export default ImageQuickCreateButton;
