import React, { useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import { Button } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import { apiHelper } from "../../../dataprovider/uploadProps";

function VariantButton(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [id, setId] = useState("");
  const { data, history } = props;
  const notify = useNotify();
  const refresh = useRefresh();

  function handleCreate() {
    apiHelper(`asset_variant/${data.id}`, {
      variant_id: id,
    })
      .then(() => {
        history.replace(`/assets/${data.id}/1`);
        setShowDialog(false);
        refresh();
      })
      .catch(() => {
        notify("Error: Variant not created", "warning");
      });
  }

  function handleClick() {
    setShowDialog(true);
  }

  function handleChange(e) {
    setId(e.target.value);
  }

  return (
    <>
      <Button color="primary" onClick={handleClick} size="small">
        <Add /> Variant
      </Button>
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-label="Create Jaali Product Subjob"
        style={{ minWidth: "200px" }}
      >
        <DialogTitle>Enter Asset Variant ID</DialogTitle>
        <div style={{ padding: "20px", display: "flex", flexDirection: "column" }}>
          <Input value={id} onChange={handleChange} />
        </div>
        <div style={{ padding: "20px", display: "flex", flexDirection: "column" }}>
          <Button label="Submit" onClick={handleCreate} disabled={false}>
            Create Variant
          </Button>
        </div>
      </Dialog>
    </>
  );
}

export default VariantButton;
