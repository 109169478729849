/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { useUpdate, useRefresh, useNotify, useListContext } from "react-admin";
import { ListView } from "../../../components/listView";

export default (props) => {
  const [update] = useUpdate();
  const refresh = useRefresh();
  const { loading } = useListContext();

  const notify = useNotify;

  const updateFunc = (id, diff, prevData = {}) => {
    update(props.resource, id, diff, prevData, {
      onSuccess: () => {
        refresh();
      },
      onFailure: ({ err }) => {
        notify(err.message, "error");
      },
    });
  };

  const ImageFieldColumn = ({ data: record }) =>
    record.imagev2 ? <img src={record.imagev2} height="120px" alt={record.name} /> : null;
  const fields = [
    {
      source: "name",
      label: "Image",
      rowDrag: true,
      width: 150,
      cellRendererFramework: ImageFieldColumn,
      cellStyle: () => ({ lineHeight: 0, color: "white" }),
    },
    { source: "name", cellStyle: () => ({ color: "white" }) },
    { source: "type", cellStyle: () => ({ color: "white" }) },
    {
      source: "display_on_app",
      label: "Display On App",
      width: 120,
      cellRenderer: (params) => (params.value ? "YES" : "NO"),
      cellStyle: (params) => (params.value ? { color: "green" } : { color: "red" }),
    },
  ];

  if (loading) {
    return null;
  }

  return (
    <ListView
      {...props}
      sort={{ field: "priority", order: "ASC" }}
      perPage={999}
      update={updateFunc}
      fields={fields}
      hasEdit
      rowHeight={120}
    />
  );
};
