import React, { useState } from "react";
import {
  required,
  Button,
  SaveButton,
  useCreate,
  useNotify,
  useRefresh,
  FormWithRedirect,
  SelectInput,
  NumberInput,
  TextInput,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

function ImageQuickCreateButton({ id, history }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("job_payments");
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: { ...values, job_id: id } } },
      {
        onSuccess: () => {
          setShowDialog(false);
          refresh();
          history.push(`/jobs/${id}`);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="Payment">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Comment"
      >
        <DialogTitle>Add Payment</DialogTitle>

        <FormWithRedirect
          resource="job_payments"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <SelectInput
                  fullWidth
                  source="mode"
                  choices={[
                    { id: "CASH", name: "CASH" },
                    { id: "CREDIT", name: "CREDIT" },
                    { id: "CHEQUE", name: "CHEQUE" },
                    { id: "UPI", name: "UPI" },
                    { id: "NET-B", name: "NET-B" },
                    { id: "PAYTM", name: "PAYTM" },
                    { id: "TDS", name: "TDS" },
                  ]}
                  validate={required()}
                />
                <NumberInput source="amount" fullWidth validate={required()} />
                <TextInput source="comment" fullWidth />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default ImageQuickCreateButton;
