import React, { useEffect, useState } from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import "./styles.css";
import Logo from "./logo.png";
import { useDataProvider } from "react-admin";
import { RupeeValue } from "../jobInvoice/lablePrint";
import TwoPoints from "../job/components/svgs/twoPoints";
import FourPoints from "../job/components/svgs/fourPoints";
import SixPoints from "../job/components/svgs/sixPoints";
import EightPoints from "../job/components/svgs/eightPoints";

function LabelPrint(props) {
  let params = new URLSearchParams(props.location.search);
  const id = params.get("id");
  const dataProvider = useDataProvider();
  const [q, setQuotation] = useState({});

  useEffect(() => {
    dataProvider
      .getOne("job_quotations", { id })
      .then(({ data }) => {
        setQuotation(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const client = JSON.parse(q?.client || "{}");
  const subJobs = JSON.parse(q?.sub_jobs || "[]");
  const title = `Q${q.job_id}-${client.first_name || ""}-${client.last_name || ""}-${
    client.firm_name || ""
  }`.toUpperCase();

  const groupBy = (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj["subjob_title"];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  const subJobMap = groupBy(subJobs);

  const calcDimension = (dimensions, type) => {
    const dimension = JSON.parse(dimensions || "{}");
    if (type === "2_POINTS") {
      const onDimension = dimension[dimension.on] || {};
      return onDimension.width
        ? `${onDimension.width}${dimension.on}x${onDimension.height}${dimension.on}`
        : "";
    } else {
      const onDimension = dimension[dimension.on] || {};
      return onDimension.w2
        ? `${onDimension.w2}${dimension.on}x${onDimension.h1}${dimension.on}`
        : "";
    }
    return "";
  };

  return (
    <div className="bodydiv">
      <div id="wrapper"></div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <header class="clearfix">
        <div id="logo">
          <img src={Logo} />
        </div>

        <h1 className="title">QUOTATION - #{q["job_id"]} </h1>

        <div class="address-to">
          <h3>
            <div>
              <b>To</b>
            </div>
            <div>
              {client.first_name} {client.last_name}
            </div>
            {client.firm_name && <div>{client.firm_name}</div>}
            {client.address && <div>{client.address}</div>}
            <div>{client.phone}</div>
          </h3>
        </div>
        <div class="quote-date">
          <div>
            <h3>Date: {moment(q["created_at"]).format("DD-MM-YYYY hh:ss")}</h3>
          </div>
        </div>
      </header>
      <main style={{ padding: "10px", paddingBottom: "4rem" }}>
        {q.cover_image && (
          <div class="coverImg">
            <div>Job Image</div>
            <img
              src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${q["cover_image"]}`}
              alt="product"
              style={{ maxWidth: "180px", maxHeight: "180px" }}
            />
          </div>
        )}
        <div className="qtablediv">
          <table className="qtable">
            <thead>
              <tr>
                <th class="service id">ID</th>
                <th colspan="4">DESCRIPTION</th>
                <th colspan="4">IMG</th>
                <th>QTY</th>
                <th>RATE</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(subJobMap)
                .sort()
                .map((subJobTitle) => {
                  return (
                    <>
                      {subJobTitle !== "undefined" &&
                        subJobTitle != null &&
                        subJobTitle !== "null" && (
                          <tr>
                            <td colSpan={13} rowSpan={1}>
                              <h3 style={{ lineHeight: "1px" }}>{subJobTitle}</h3>
                            </td>
                          </tr>
                        )}
                      {subJobMap[subJobTitle].map((sub, index) => (
                        <tr>
                          <td class="qty">{index + 1}</td>
                          <td class="service" colspan="4">
                            {sub["material_thickness"]}mm {sub["material_type"]} {sub?.processes}{" "}
                            {calcDimension(sub.dimensions, sub.type)} {sub?.design}{" "}
                            {sub?.material_status && sub?.material_status === "OURS"
                              ? "With Material"
                              : "Without Material"}{" "}
                            <br />
                            {sub?.comment}
                          </td>
                          <td colspan="4">
                            {sub?.image ? (
                              <img
                                src={`https://drive.google.com/thumbnail?sz=w150&id=${sub["image"]}`}
                                alt="product"
                              />
                            ) : (
                              <>
                                {sub.type === "2_POINTS" && (
                                  <TwoPoints dimensions={JSON.parse(sub.dimensions || "{}")} />
                                )}
                                {sub.type === "4_POINTS" && (
                                  <FourPoints dimensions={JSON.parse(sub.dimensions || "{}")} />
                                )}
                                {sub.type === "6_POINTS" && (
                                  <SixPoints dimensions={JSON.parse(sub.dimensions || "{}")} />
                                )}
                                {sub.type === "8_POINTS" && (
                                  <EightPoints dimensions={JSON.parse(sub.dimensions || "{}")} />
                                )}
                              </>
                            )}
                          </td>
                          <td class="qty">{sub["quantity"]}</td>
                          <td class="rate qty">
                            <RupeeValue value={sub["rate"]} />
                          </td>
                          <td class="unit">
                            <RupeeValue value={Math.round(sub["quantity"] * sub["rate"])} />
                          </td>
                        </tr>
                      ))}
                    </>
                  );
                })}

              {q.subTotal !== q.total ? (
                <tr>
                  <td colspan="10"></td>
                  <td colspan="1" class="grand">
                    SUBTOTAL
                  </td>
                  <td class="total">
                    <RupeeValue value={q["total"]} />
                  </td>
                </tr>
              ) : null}
              {q.discount ? (
                <tr>
                  <td colspan="10"></td>
                  <td colspan="1" class="grand">
                    DISCOUNT
                  </td>
                  <td class="total">
                    <RupeeValue value={q["discount"]} />
                  </td>
                </tr>
              ) : null}
              {q.pack ? (
                <tr>
                  <td colspan="10"></td>
                  <td colspan="1" class="grand">
                    PACKAGING
                  </td>
                  <td class="total">
                    <RupeeValue value={q["pack"]} />
                  </td>
                </tr>
              ) : null}
              {q.misc ? (
                <tr>
                  <td colspan="10"></td>
                  <td colspan="1" class="grand">
                    MISCELLANEOUS
                  </td>
                  <td class="total">
                    <RupeeValue value={q["misc"]} />
                  </td>
                </tr>
              ) : null}
              {q.shipping ? (
                <tr>
                  <td colspan="10"></td>
                  <td colspan="1" class="grand">
                    SHIPPING
                  </td>
                  <td class="total">
                    <RupeeValue value={q["shipping"]} />
                  </td>
                </tr>
              ) : null}
              {q.gst ? (
                <tr>
                  <td colspan="10"></td>
                  <td colspan="1" class="grand">
                    GST@18%
                  </td>
                  <td class="total">
                    <RupeeValue value={q["gst"]} />
                  </td>
                </tr>
              ) : null}
              <tr>
                <td colspan="10"></td>
                <td colspan="1" class="grand">
                  TOTAL
                </td>
                <td class="total">
                  <RupeeValue value={q["grand_total"]} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="notices">
          <div>TERMS AND CONDITIONS:</div>
          <div class="notice">
            <ol>
              <li>
                Delivery witinin {q["dtod"]}-{q["dtod"] + 1} working days after confirmation of work
                order
              </li>
              <li>Freight Charges extra at actuals.</li>
              <li>{q["advance"]}% Advance payment & bal on or before delivery.</li>
              <li>
                In case of any rejection, our liability will be restricted to Router or Laser
                cutting processing charges only. The same has to be communicated to us in writing
                within 6-7 days from the date of dispatch. No deductions from material cost will be
                accepted under any circumstances.
              </li>
              <li>
                <b>Bank Details</b>:- <b>A/c Name</b> : Jaali.in <b>A/c No.</b> : 10541131003463,{" "}
                <b>Bank Name</b> : Punjab National Bank
                <b>Branch</b> : Camp, <b>IFSC Code</b> : PUNB0038600, <b>A/c Type</b> : Current
                Account
              </li>
              {q.gst_inclusive && <li>Taxes @ 18% GST Extra</li>}
            </ol>
          </div>
        </div>
      </main>
      <footer className="footerDiv">
        <div>
          <b>Quresh</b>: +91 9923 80 8585 <b>Murtaza</b>: +91 9923 80 8989 <b>Email</b>:
          jaali.in@gmail.com
        </div>
        <div>
          <b>Postal Address</b>: #13, Iqra Bldg., 2390-B, New Modikhana, Near Poona College, Pune -
          411001.
        </div>
      </footer>
    </div>
  );
}

export default LabelPrint;
