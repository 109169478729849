import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Form, Field } from "react-final-form";
import { getYears } from "../../../../common";
import { tabs } from "../../job/tabs";
import { apiHelper } from "../../../../dataprovider/uploadProps";
import { ReactSelectAdapter } from "../../../../components/adapters/selectAdapter";
import { TextFieldAdapter } from "../../../../components/adapters/textAdapter";
import RemnantButton from "../../subJob/components/materialButton";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

const required = (value) => (value ? undefined : "Required");

export default function FormForReport({ setData }) {
  const classes = useStyles();

  function generateChart(details) {
    return apiHelper(`material-sqft-report`, {
      ...details,
    })
      .then((resp) => {
        setData(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Form
      onSubmit={generateChart}
      // validate={validate}
      render={({ handleSubmit, form }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Paper className={classes.paper}>
              <RemnantButton change={form.change} />
              <Field
                name="material_type"
                component={TextFieldAdapter}
                validate={required}
                label="Material Type"
                disabled
              />
              <Field
                name="material_thickness"
                component={TextFieldAdapter}
                validate={required}
                label="Material thickness"
                disabled
              />
              <Field
                name="status"
                component={ReactSelectAdapter}
                options={tabs.map((p) => ({ label: p.name, value: p.id }))}
                multiple
                format={(value) => value || []}
                label="Job Status"
              />
              <Field
                name="year"
                component={ReactSelectAdapter}
                options={getYears().map((p) => ({ label: p, value: p }))}
                validate={required}
                label="Year"
              />

              <Button variant="contained" color="primary" type="submit">
                Generate
              </Button>
            </Paper>
          </form>
        );
      }}
    />
  );
}
