import React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  EditButton,
} from "react-admin";

export default (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <FunctionField
        label="File"
        render={(record) =>
          record.file && (
            <img
              src={`https://drive.google.com/thumbnail?authuser=0&sz=w300&id=${record.file}`}
              alt={record.file}
            />
          )
        }
      />
      <TextField source="priority" />
      <EditButton />
    </Datagrid>
  </List>
);
