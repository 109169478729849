import React from "react";
import { TextInput, Create, SelectInput, SimpleForm } from "react-admin";
import menu from "./menu";

export default (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="mobile" />
      <TextInput source="password" />
      <SelectInput label="Access" source="access" choices={menu} />
    </SimpleForm>
  </Create>
);
