import React from "react";
import { List, Datagrid, NumberField, FunctionField } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Download from "@material-ui/icons/GetApp";

const SubOrderShow = (props) => {
  const params = {
    basePath: "/sub_orders",
    resource: "sub_orders",
    location: props.location,
    hasCreate: false,
    hasEdit: true,
    hasList: true,
    hasShow: false,
    match: props.match,
  };
  return (
    <List {...params} filterDefaultValues={{ order_id: props.id }}>
      <Datagrid expand={<FieldPanel />}>
        <FunctionField
          render={(record) =>
            typeof record.product === "string" && (
              <span style={{ maxWidth: "250px" }}>{record.product}</span>
            )
          }
        />
        <FunctionField
          render={(record) =>
            record.product && record.product.image_1 ? (
              <img
                src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${record.product.image_1}`}
                alt={record.supc}
              />
            ) : null
          }
        />

        <FunctionField
          render={(record) =>
            record.product && record.product.title ? (
              <span>{record.product.title}</span>
            ) : null
          }
        />

        <NumberField
          source="quantity"
          options={{ style: "currency", currency: "INR" }}
        />
        <NumberField
          source="price"
          options={{ style: "currency", currency: "INR" }}
        />
        <NumberField
          source="total"
          options={{ style: "currency", currency: "INR" }}
        />
      </Datagrid>
    </List>
  );
};

const FieldPanel = ({ id, record, resource }) => {
  if (record.fields.length > 0) {
    return (
      <Paper>
        <Table>
          <TableBody>
            {record.fields.map((field) => (
              <TableRow key={field.data}>
                <TableCell>{field.label}</TableCell>
                <TableCell>
                  {field.type === "IMAGE" ? (
                    <img
                      src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${field.data}`}
                      alt="input"
                    />
                  ) : (
                    field.data
                  )}
                </TableCell>
                <TableCell>
                  {field.type === "IMAGE" ? (
                    <Button
                      variant="fab"
                      color="secondary"
                      aria-label="Edit"
                      href={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${field.data}`}
                      download
                      target="_blank"
                    >
                      <Download />
                    </Button>
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
  return "";
};

export default SubOrderShow;
