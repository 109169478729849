import React, { useState } from "react";
import {
  Edit,
  Create,
  TextInput,
  SimpleForm,
  required,
  ImageInput,
  ImageField,
  Toolbar,
  FileInput,
  FileField,
  FormDataConsumer,
  SelectInput,
  BooleanInput,
} from "react-admin";
import Icon from "@material-ui/icons/Dehaze";

import uploadProps, { uploadMedia } from "../../../dataprovider/uploadProps";
import UploadProgress from "../../../components/uploadProgress";
import CategoryList from "./list";

const CategoryCreate = (props) => {
  const transform = async (data) => {
    const obj = data;
    if (data.image instanceof Object) {
      const resp = await uploadMedia(data.image, "AssetCategories");
      obj.imagev2 = resp.data;
    }
    return await uploadProps(data, setProgress, "ASSETCATEGORIES");
  };
  const [progress, setProgress] = useState();
  return (
    <>
      <Create {...props} undoable={false} transform={transform}>
        <SimpleForm redirect="/asset_categories">
          <TextInput source="name" validate={required()} />
          <SelectInput
            source="type"
            choices={[
              { id: "PRODUCTS", name: "PRODUCTS" },
              { id: "CONCEPTS", name: "CONCEPTS" },
            ]}
            validate={required()}
          />
          <TextInput source="description" title="Description" multiline />
          <ImageInput
            source="image"
            accept="image/*"
            multiple={false}
            placeholder={<p>Drop your file here</p>}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <BooleanInput source="display_on_app" />
          <BooleanInput source="is_dark_mode" />
        </SimpleForm>
      </Create>
      <UploadProgress progress={progress} />
    </>
  );
};

const CategoryEdit = (props) => {
  const [progress, setProgress] = useState();
  const transform = async (data) => {
    const obj = data;
    if (data.image instanceof Object) {
      const resp = await uploadMedia(data.image, "AssetCategories");
      obj.imagev2 = resp.data;
    }
    return await uploadProps(data, setProgress, "ASSETCATEGORIES");
  };
  return (
    <>
      <Edit {...props} undoable={false} transform={transform}>
        <SimpleForm redirect="/asset_categories" toolbar={<Toolbar disabled={!!progress} />}>
          <TextInput source="name" validate={required()} />
          <SelectInput
            source="type"
            choices={[
              { id: "PRODUCTS", name: "PRODUCTS" },
              { id: "CONCEPTS", name: "CONCEPTS" },
            ]}
            validate={required()}
          />
          <TextInput source="description" title="Description" multiline />
          <FileInput source="image" accept="image/*" placeholder={<p>Drop your file here</p>}>
            <FileField source="src" title="title" />
          </FileInput>
          <FormDataConsumer>
            {({ formData }) =>
              formData.image &&
              typeof formData.image === "string" && (
                <img
                  src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${formData.image}`}
                  alt={formData.image}
                  style={{ position: "relative" }}
                />
              )
            }
          </FormDataConsumer>
          <BooleanInput source="display_on_app" />
          <BooleanInput source="is_dark_mode" />
        </SimpleForm>
      </Edit>

      <UploadProgress progress={progress} />
    </>
  );
};

export default {
  list: CategoryList,
  edit: CategoryEdit,
  create: CategoryCreate,
  icon: Icon,
  label: "Asset Categories",
  options: { label: "Asset Categories" },
  name: "asset_categories",
};
