import React, { Fragment } from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

export default props => (
  <List {...props} bulkActionButtons={<ActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="tag1" label="Tag 1" />
      <TextField source="tag2" label="Tag 2" />
      <TextField source="tag3" label="Tag 3" />
      <TextField source="tag4" label="Tag 4" />
      <EditButton />
    </Datagrid>
  </List>
);

const ActionButtons = () => <Fragment></Fragment>;
