import React, { useState } from "react";

import {
  required,
  Button,
  SaveButton,
  useCreate,
  useNotify,
  useRefresh,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  NumberInput,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

function SubOrderQuickCreateButton({ data }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("sub_orders");
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    values.order_id = data.id;
    values.with_gst = data.with_gst;
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="Add Item">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create SubOrder"
      >
        <DialogTitle>Create SubOrder</DialogTitle>

        <FormWithRedirect
          resource="sub_orders"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <ReferenceInput
                  label="Product"
                  source="product_id"
                  reference="products"
                  validate={required()}
                  perPage={9999}
                  sort={{ field: "title", order: "ASC" }}
                  fullWidth
                >
                  <SelectInput optionText="title" />
                </ReferenceInput>
                <NumberInput
                  source="quantity"
                  fullWidth
                  validate={required()}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default SubOrderQuickCreateButton;
