import React, { useState } from "react";
import {
  required,
  Button,
  SaveButton,
  useCreate,
  useNotify,
  useRefresh,
  FormWithRedirect,
  FileInput,
  FileField,
  RadioButtonGroupInput,
  FormDataConsumer,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import { makeStyles } from "@material-ui/core/styles";
import uploadProps from "../../../dataprovider/uploadProps";
import UploadProgress from "../../../components/uploadProgress";
import ImageInput from "../../../components/imageInput";

function ResourceQuickCreateButton({ id, category }) {
  const [progress, setProgress] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("resources");
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    const { title } = values.resource;
    const x = values;
    console.log(x);
    const data = await uploadProps(values, setProgress, "RESOURCES");
    data.name = title;
    data.job_id = id;
    data.category = category;
    create(
      { payload: { data } },
      {
        onSuccess: () => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="Upload">
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create Resource">
        <DialogTitle>Add Resource</DialogTitle>

        <FormWithRedirect
          resource="resources"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <RadioButtonGroupInput
                  source="type"
                  choices={[
                    { id: "IMAGE", name: "IMAGE/PDF" },
                    { id: "FILE", name: "FILE" },
                  ]}
                  initialValue="IMAGE"
                  validate={required()}
                />
                <FormDataConsumer>
                  {({ formData }) =>
                    formData.type === "IMAGE" ? (
                      <ImageInput source="resource" label="Resource" />
                    ) : (
                      <FileInput source="resource" label="Resource" validate={required()}>
                        <FileField source="src" title="title" />
                      </FileInput>
                    )
                  }
                </FormDataConsumer>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
      <UploadProgress progress={progress} />
    </>
  );
}

export default ResourceQuickCreateButton;
