import React from "react";
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  NumberInput,
} from "react-admin";
import RichTextInput from "../../../components/richInputText";

export default (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm redirect={`/tags`}>
      <TextInput source="id" label="name" validate={required()} />
      <TextInput source="category" label="category" validate={required()} />
      <RichTextInput source="description" title="Description" />
      <NumberInput source="priority" />
    </SimpleForm>
  </Create>
);
