import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  TextInput,
  SimpleForm,
  required,
  EditButton,
  BooleanInput,
  BooleanField,
} from "react-admin";
import Icon from "@material-ui/icons/Dehaze";
import RichTextInput from "../../../components/richInputText";

const CategoryList = (props) => (
  <React.Fragment>
    <List {...props}>
      <Datagrid>
        <TextField source="name" />
        <BooleanField source="is_worksheet" />
        <EditButton />
      </Datagrid>
    </List>
  </React.Fragment>
);

const CategoryCreate = (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm redirect={`/categories`}>
      <TextInput source="name" validate={required()} />
      <RichTextInput source="description" title="Description" />
      <BooleanInput source="is_worksheet" />
    </SimpleForm>
  </Create>
);

const CategoryEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect={`/categories`}>
      <TextInput source="name" validate={required()} />
      <RichTextInput source="description" title="Description" />
      <BooleanInput source="is_worksheet" />
    </SimpleForm>
  </Edit>
);

export default {
  list: CategoryList,
  edit: CategoryEdit,
  create: CategoryCreate,
  icon: Icon,
  label: "Categories",
  options: { label: "Categories" },
  name: "categories",
};
