import React from "react";
import { List, Datagrid, TextField, EditButton, ReferenceField, BooleanField } from "react-admin";

export default (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Name" />
      <ReferenceField source="category_id" reference="asset_categories">
        <TextField source="name" label="Category" />
      </ReferenceField>
      <BooleanField source="is_filter" />
      <EditButton />
    </Datagrid>
  </List>
);
