import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles(() => ({
  card: {
    marginBottom: "10px",
  },
}));

export default ({ records }) => {
  const classes = useStyles();
  return records.map((comm) => (
    <Card className={classes.card} key={comm.id}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary">
          {moment(comm.created_at).format("lll")}
        </Typography>
        <Typography variant="h6" component="p">
          {comm.comment}
        </Typography>
        <Typography variant="body2" component="p" color="textSecondary">
          - {comm.name}
        </Typography>
      </CardContent>
    </Card>
  ));
};
