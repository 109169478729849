import React from "react";
import { useNotify, useDataProvider, Button } from "react-admin";

function CopyButton(props) {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  function handleClick() {
    const { selectedIds, history } = props;
    dataProvider
      .getOne("product_copy", { id: selectedIds[0] })
      .then(({ data }) => {
        history.push(`/products/${data.id}`);
      })
      .catch(() => {
        notify("Error: field not created", "warning");
      });
  }
  const { selectedIds } = props;

  return (
    selectedIds.length === 1 && <Button label="Copy" onClick={handleClick} />
  );
}

export default CopyButton;
