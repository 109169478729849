import List from "./list";
import Create from "./create";
import Edit from "./edit";
import Icon from "@material-ui/icons/Note";

export default {
  list: List,
  create: Create,
  edit: Edit,
  icon: Icon,
  label: "Worksheets",
  options: { label: "Worksheets" },
  name: "worksheets",
};
