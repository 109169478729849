import React from "react";
import { Datagrid, TextField, FunctionField, DateField, usePermissions } from "react-admin";

import Icon from "@material-ui/icons/Stop";
// import PanoramaWideAngleSelectIcon from '@mui/icons-material/PanoramaWideAngleSelect';
import { tabs, tabsProduction } from "./tabs";

const postRowStyle = (record = {}, tabs) => {
  const color = tabs.find((f) => f.id === record.status) || {};
  return {
    backgroundColor: color.color || "#eeeeee",
  };
};

export default (props) => {
  const { permissions } = usePermissions();
  const show = !(permissions === "jaali_production");
  return (
    <Datagrid
      {...props}
      rowClick="edit"
      rowStyle={(record) =>
        show ? postRowStyle(record, tabs) : postRowStyle(record, tabsProduction)
      }
    >
      <TextField source="id" />
      <FunctionField
        label="Image"
        render={(record) =>
          record.image && (
            <img
              src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${record.image}`}
              alt={record.supc}
            />
          )
        }
      />
      <FunctionField
        label="Name"
        render={(record) =>
          record.client && (
            <span>
              <b>
                {record.client.first_name} {record.client.last_name || ""}
              </b>
              <br />
              <b>fn</b>: {record.client.firm_name || ""}
              <br />
              <b>sn</b>: {record.site_name || ""}
            </span>
          )
        }
      />

      <TextField source="client.phone" />
      <DateField source="created_at" label="Order Date" />
      <DateField source="delivery_date" label="Delivery Date" />
      <FunctionField label="ArtWork" render={(record) => <ArtStatus {...record} />} />
      {show && <FunctionField label="Payment" render={(record) => <PaymentStatus {...record} />} />}
    </Datagrid>
  );
};

const ArtStatus = (record) => {
  const artStatusNull = parseInt(record.artstatus_null, 10);
  const artApproved = parseInt(record.artapproved, 10);
  const count = parseInt(record.count, 10);
  if (artStatusNull === count) {
    return <Icon style={{ color: "#f44336", fontSize: "60px" }} />;
  }
  if (artApproved === count) {
    return <Icon style={{ color: "#9ccc65", fontSize: "60px" }} />;
  }
  return <Icon style={{ color: "orange", fontSize: "60px" }} />;
};

const PaymentStatus = (record) => {
  const { grand_total, paid } = record;

  if (paid === 0) {
    return <Icon style={{ color: "#f44336", fontSize: "60px" }} />;
  }
  if (grand_total === paid) {
    return <Icon style={{ color: "#9ccc65", fontSize: "60px" }} />;
  }
  return <Icon style={{ color: "orange", fontSize: "60px" }} />;
};
