import React, { useRef, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useInput } from "react-admin";

function ImageInput(props) {
  const ref = useRef();
  const [image, setImage] = useState();
  const classes = useStyles();
  const {
    input: { onChange },
  } = useInput(props);
  const pasteHandler = (e) => {
    let blob;
    if (e.clipboardData && e.clipboardData.items.length > 0) {
      blob = transformImages(e.clipboardData);
      if (blob) {
        const URL = window.URL || window.webkitURL;
        const src = URL.createObjectURL(blob);
        onChange({
          rawFile: blob,
          src,
          title: blob.name,
        });
        setImage(src);
      }
    }
  };
  useEffect(() => {
    let curr;
    if (ref && ref.current) {
      curr = ref.current;
      curr.addEventListener("paste", pasteHandler);
    }

    return () => {
      curr.removeEventListener("paste", pasteHandler);
    };
  }, [ref, pasteHandler]);

  const acceptedFiles = [
    "image/gif",
    "image/png",
    "image/jpeg",
    "image/bmp",
    "application/pdf",
  ];
  const isValidFormat = (fileType) => acceptedFiles.includes(fileType);
  const transformImages = (data) => {
    // NOTE: This needs to be a for loop, it's a list like object
    for (let i = 0; i < data.items.length; i++) {
      if (isValidFormat(data.items[i].type) !== false) {
        let blob = data.items[i].getAsFile();
        if (blob) {
          return blob;
        }
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <div className={classes.dragdrop} ref={ref}>
        {image ? (
          <img src={image} key={image} alt={`Pasted: ${image}`} width="200px" />
        ) : (
          <div className={classes.message}>
            Copy an image from your file system and paste it here
          </div>
        )}
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  dragdrop: {
    padding: "2rem",
    textAlign: "center",
    background: "#303030",
    borderRadius: "0",
  },
  message: {
    fontFamily: "Segoe UI,Arial,sans-serif",
  },
}));

export default ImageInput;
