import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { ReferenceInput, AutocompleteInput, BooleanInput } from "react-admin";

export default () => (
  <Box flex={2}>
    <Typography variant="h6">DESIGN</Typography>
    <Box flex={1} mr={{ xs: 0, sm: "1em" }}>
      <ReferenceInput
        source="design"
        reference="assets"
        resource="sub_jobs"
        filterToQuery={(searchText) => ({ id: searchText })}
        fullWidth
        allowEmpty
        helperText={false}
      >
        <AutocompleteInput optionText="id" />
      </ReferenceInput>
      <BooleanInput
        source="is_active"
        label="SubJob is Active"
        resource="sub_jobs"
        helperText={false}
      />
    </Box>
  </Box>
);
