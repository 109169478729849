import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  TextInput,
  NumberInput,
  SelectInput,
  FormDataConsumer,
} from "react-admin";
import TextField from "./textField";
import MaterialsButton from "./materialButton";

export default ({ formProps, calcMaterial }) => (
  <>
    <Typography variant="h6">
      MATERIAL <MaterialsButton change={formProps.form.change} />
    </Typography>
    <Box display={{ xs: "block", sm: "flex" }}>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <SelectInput
          source="material_status"
          label="Status"
          resource="sub_jobs"
          choices={[
            { id: "CLIENT", name: "Client" },
            { id: "OURS", name: "Ours" },
            { id: "BOTH", name: "Both" },
          ]}
          onChange={(e) =>
            e.target.value === "CLIENT" &&
            formProps.form.change("material_cost", 0)
          }
          fullWidth
          helperText={false}
        />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <TextInput
          source="material_type"
          label="Type"
          resource="sub_jobs"
          fullWidth
          format={(v) => (v ? v.toUpperCase() : "")}
          parse={(v) => (v ? v.toUpperCase() : "")}
          helperText={false}
        />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <NumberInput
          source="material_thickness"
          label="Thickness"
          resource="sub_jobs"
          fullWidth
          helperText={false}
        />
      </Box>
      <FormDataConsumer {...formProps}>
        {({ formData }) =>
          formData.material_status !== "CLIENT" ? (
            <>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <NumberInput
                  source="material_cost"
                  resource="sub_jobs"
                  label="Cost"
                  helperText={false}
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <NumberInput
                  source="material_sqft"
                  resource="sub_jobs"
                  fullWidth
                  helperText={false}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}></Box>
              <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                <TextField
                  label="Material Total"
                  value={calcMaterial(formData)}
                />
              </Box>
            </>
          ) : (
            <Box flex={4}></Box>
          )
        }
      </FormDataConsumer>
    </Box>
  </>
);
