import React from "react";
import MuiGridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import withWidth from "@material-ui/core/withWidth";
import { useListContext } from "react-admin";

import GridListForm from "./gridListForm";

const useStyles = makeStyles((theme) => ({
  gridList: {
    margin: 0,
  },
  tileBar: {
    background: "",
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: "100%",
  },
  price: {
    display: "inline",
    fontSize: "1em",
  },
  link: {
    color: "#fff",
  },
  tile: {
    padding: theme.spacing(2),
    maxHeight: "350px",
  },
}));

const getColsForWidth = (width) => {
  if (width === "xs") return 2;
  if (width === "sm") return 3;
  if (width === "md") return 4;
  if (width === "lg") return 5;
  return 5;
};

const LoadingGridList = ({ width }) => {
  const classes = useStyles();
  return (
    <MuiGridList cols={getColsForWidth(width)} className={classes.gridList}>
      <GridListTile>
        <div className={classes.placeholder} />
      </GridListTile>
    </MuiGridList>
  );
};

const LoadedGridList = ({ width }) => {
  const { ids, data } = useListContext();
  const classes = useStyles();

  if (!ids || !data) return null;

  return (
    <MuiGridList cellHeight={390} cols={getColsForWidth(width)} className={classes.gridList}>
      {ids.map((id) => (
        <div style={{ width: "300px" }}>
          <div
            style={{
              height: "300px",
              width: "300px",
            }}
          >
            <a href={`#/assets/${data[id].id}`}>
              <img
                src={data[id].imagev2}
                alt={data[id].name}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              />
            </a>
          </div>
          <div
            style={{
              height: "50px",
              width: "300px",
            }}
          >
            <Typography variant="body2" gutterBottom style={{ color: "white" }}>
              {" "}
              {data[id].name}
            </Typography>
            <Typography variant="body1" gutterBottom style={{ color: "white" }}>
              {data[id].id}
            </Typography>

            <br />
          </div>
          <GridListForm data={data[id]} />
        </div>
      ))}
    </MuiGridList>
  );
};

const GridList = ({ width }) => {
  const { loaded } = useListContext();
  return loaded ? <LoadedGridList width={width} /> : <LoadingGridList width={width} />;
};

export default withWidth()(GridList);
