import * as React from "react";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LocalOfferIcon from "@material-ui/icons/LocalOfferOutlined";

import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  useGetList,
} from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      width: "15em",
      marginRight: "1em",
      overflow: "initial",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Aside = () => {
  const { data, ids } = useGetList(
    "jaali_tags",
    { page: 1, perPage: 100 },
    { field: "id", order: "ASC" },
    {}
  );
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <FilterLiveSearch />

        <FilterList
          label="Tags"
          icon={<LocalOfferIcon />}
        >
          {ids &&
            data &&
            ids.map((id) => (
              <FilterListItem
                label={data[id].id}
                key={data[id].id}
                value={{ tag: data[id].id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default Aside;
