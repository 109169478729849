import React from "react";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useLogin, useNotify } from "react-admin";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// Configure Firebase.
const config = {
  apiKey: "AIzaSyB-T3-CykEQYZdX8SWbDHZK0594l0e3izM",
  authDomain: "dhariwalaadmin.firebaseapp.com",
  databaseURL: "https://dhariwalaadmin.firebaseio.com",
  projectId: "dhariwalaadmin",
  storageBucket: "dhariwalaadmin.appspot.com",
  messagingSenderId: "1013443822756",
  appId: "1:1013443822756:web:a46606b06b9ca74f7f5231",
};
firebase.initializeApp(config);

export default function LoginPage() {
  const classes = useStyles();
  const login = useLogin();
  const notify = useNotify();
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          Jaali Admin Sign in
        </Typography>
        <StyledFirebaseAuth
          uiConfig={{
            signInFlow: "popup",

            signInOptions: [
              {
                provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                recaptchaParameters: {
                  type: "image",
                  size: "invisible",
                  badge: "bottomright",
                },
                defaultCountry: "IN",
                // whitelistedCountries: ['IN', 'AE', 'US', 'SG`'],
              },
            ],
            callbacks: {
              signInSuccessWithAuthResult: (authResult) => {
                const { phoneNumber, Aa } = authResult.user;
                login({ phoneNumber, idToken: Aa }).catch(() =>
                  notify("Invalid phoneNumber")
                );
                // Avoid redirects after sign-in.
                return false;
              },
              signInFailure: () => {},
              uiShown: () => {},
            },
          }}
          firebaseAuth={firebase.auth()}
        />
      </div>
    </Container>
  );
}
