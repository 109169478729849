import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import FormForReport from "./form";
import Paper from "@material-ui/core/Paper";
import { ReportChart } from "./chart";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function ProcessVertical() {
  const classes = useStyles();

  const [data, setData] = useState([]);
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={4}>
            <FormForReport setData={setData} />
          </Grid>
          {data.length > 0 && (
            <Grid item xs={8}>
              <Paper className={classes.paper}>
                <ReportChart
                  columns={["Month", "Total"]}
                  data={data.map((d) => [d.month, d.total])}
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
