import React from "react";
import { Button, ReferenceInput, AutocompleteInput } from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/icons/Stop";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}));

const ClientReferenceInput = ({
  history,
  optionRenderer,
  client,
  ...props
}) => {
  const classes = useStyles();
  const permissions =
    (localStorage.getItem("access") || "") !== "jaali_production";
  const PaymentStatus = () => {
    const { paymentPriority = "ADVANCE" } = client || {}; 

    if (paymentPriority === "ADVANCE") {
      return <Icon style={{ color: "#FFA500" }} />;
    }
    if (paymentPriority === "FOLLOWUP") {
      return <Icon style={{ color: "#ADD8E6" }} />;
    }
    if (paymentPriority === "NOADVANCE") {
      return <Icon style={{ color: "#00FF00" }} />;
    }
    return <Icon style={{ color: "grey" }} />;
  };

  return (
    <div className={classes.root}>
      <ReferenceInput {...props} helperText={false}>
        <AutocompleteInput optionText={optionRenderer} />
      </ReferenceInput>
      <PaymentStatus />
      {permissions && (
        <Button
          onClick={() => history.push("/clients/create")}
          label='ra.action.create'
        >
          <IconContentAdd />
        </Button>
      )}
    </div>
  );
};

export default ClientReferenceInput;
