import React, { useState } from "react";
import {
  Edit,
  TextInput,
  TabbedForm,
  FormTab,
  ReferenceArrayInput,
  SelectArrayInput,
  FileInput,
  FileField,
  FormDataConsumer,
  TopToolbar,
  ReferenceManyField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import UploadProgress from "../../../components/uploadProgress";
import Toolbar from "../../../components/toolbar";
import uploadProps, { uploadMedia } from "../../../dataprovider/uploadProps";
import CategoryReferenceInput from "../../../components/createButton";
import RichTextInput from "../../../components/richInputText";
import VariantButton from "../assetVariant/createVariantButton";
import FieldList from "../assetVariant/list";

const useStyles = makeStyles((theme) => ({
  fullWidth: { width: "100%" },
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
}));
export default (props) => {
  const [progress, setProgress] = useState();
  const classes = useStyles();
  const transform = async (data) => {
    const obj = data;
    if (data.image instanceof Object) {
      const resp = await uploadMedia(data.image, "Assets");
      obj.imagev2 = resp.data;
    }
    return await uploadProps(data, setProgress, "ASSETS");
  };
  const AssetEditActions = (props) => (
    <TopToolbar>
      <VariantButton {...props} />
    </TopToolbar>
  );
  return (
    <>
      <Edit
        {...props}
        undoable={false}
        transform={transform}
        actions={<AssetEditActions history={props.history} />}
      >
        <TabbedForm redirect={false} toolbar={<Toolbar disabled={!!progress} />}>
          <FormTab label="Details">
            <TextInput source="id" />
            <TextInput source="name" />
            <CategoryReferenceInput
              source="category_id"
              reference="asset_categories"
              perPage={9999}
              field="name"
            />
            <ReferenceArrayInput perPage={99999} source="tags" reference="jaali_tags" label="Tags">
              <SelectArrayInput optionText="id" />
            </ReferenceArrayInput>
            <FileInput source="image" accept="image/*" placeholder={<p>Drop your file here</p>}>
              <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData }) =>
                formData.image &&
                typeof formData.image === "string" && (
                  <img
                    src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${formData.image}`}
                    alt={formData.image}
                    style={{ position: "relative" }}
                  />
                )
              }
            </FormDataConsumer>
            <FileInput source="file" placeholder={<p>Drop your file here</p>}>
              <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData }) =>
                formData.file &&
                typeof formData.file === "string" && (
                  <img
                    src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${formData.file}`}
                    alt={formData.file}
                    style={{ position: "relative" }}
                  />
                )
              }
            </FormDataConsumer>
            <RichTextInput source="description" title="Description" />
          </FormTab>
          <FormTab label="Variants">
            <ReferenceManyField
              className={classes.fullWidth}
              label=" "
              reference="asset_variants"
              target="parent_id"
              perPage={50}
            >
              <FieldList />
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>
      </Edit>
      <UploadProgress progress={progress} />
    </>
  );
};
