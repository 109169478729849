import * as React from "react";
import { RouteWithoutLayout } from "react-admin";
import { Route } from "react-router-dom";
import QuotationPrint from "./containers/jaali/quotation/lablePrint";
import InvoicePrint from "./containers/jaali/jobInvoice/lablePrint";
import ChallanPrint from "./containers/jaali/jobChallan/lablePrint";

import ProcessVertical from "./containers/jaali/reports/processVerticalReport";
import MaterialSqftReport from "./containers/jaali/reports/materialSqftReport";
import JobPaymentReport from "./containers/jaali/reports/jobPaymentReport";

export default [
  <RouteWithoutLayout exact path="/print-quotation" component={QuotationPrint} noLayout />,
  <RouteWithoutLayout exact path="/print-invoice" component={InvoicePrint} noLayout />,
  <RouteWithoutLayout exact path="/print-challan" component={ChallanPrint} noLayout />,
  <Route exact path="/process-vertical" component={ProcessVertical} />,
  <Route exact path="/material-sqft-report" component={MaterialSqftReport} />,
  <Route exact path="/job-payment-report" component={JobPaymentReport} />,
];
