export const tabs = [
  { id: "LEAD", name: "LEAD", color: "#757575" },
  { id: "CONVERTED", name: "CONVERTED", color: "#ef6c00" },
  { id: "PRODUCTION", name: "PRODUCTION", color: "#0d47a1" },
  { id: "PROCESSED", name: "PROCESSED", color: "#6d4c41" },
  { id: "PAYMENT_PENDING", name: "PAYMENT_PENDING", color: "#b71c1c" },
  { id: "CLOSED", name: "CLOSED", color: "#43a047" },
  { id: "ALL", name: "ALL" },
];

export const tabsProduction = [
  { id: "PRODUCTION", name: "PRODUCTION", color: "#0d47a1" },
  { id: "PROCESSED", name: "PROCESSED", color: "#6d4c41" },
];
