import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  UrlField,
  EditButton
} from "react-admin";

export default props => (
  <List {...props} bulkActionButtons={<ActionButtons />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <FunctionField
        label="Image"
        render={record =>
          record.image && (
            <img
              src={`https://drive.google.com/thumbnail?authuser=0&sz=w300&id=${record.image}`}
              alt={record.image}
            />
          )
        }
      />
      <UrlField source="link" />
      <EditButton />
    </Datagrid>
  </List>
);

const ActionButtons = () => <Fragment></Fragment>;
