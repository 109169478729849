/* eslint-disable camelcase */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Box from "@material-ui/core/Box";

export const getTruncatedTitle = (title) => {
  if (title.length > 80) {
    return title.substring(0, 80) + "...";
  }
  return title;
};
export default ({ data: row, showTotal }) => {
  const classes = useStyles();

  const permissions = localStorage.getItem("access") || "";

  return (
    <div>
      {row.material_thickness && (
        <Box display={{ xs: "block" }} my={{ xs: "1em" }}>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "1em" }}>
              <Typography variant="body2" className={classes.textMuted} display="block">
                Material
              </Typography>
              <Typography variant="subtitle1" className={classes.white} display="block">
                <b>{row.material_thickness}</b> <b>{row.material_type}</b>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box display={{ xs: "block", sm: "flex" }} my={{ xs: "1em" }}>
        <Box flex={1}>
          <Typography variant="subtitle1" className={classes.white}>
            <span style={{ color: "#afafaf" }}>ID</span> - <b>{row.id}</b>
          </Typography>
          <Typography variant="subtitle1" className={classes.white}>
            <span style={{ color: "#afafaf" }}>Qty</span> - <b>{row.quantity}</b>
            <span style={{ color: "#afafaf", marginLeft: 10 }}>
              {row.pcs > 0 ? `  (${row.quantity}x${row.pcs} Pcs)` : ""}
            </span>
          </Typography>
          {permissions !== "jaali_production" && showTotal && (
            <>
              <Typography variant="subtitle1" className={classes.white}>
                <span style={{ color: "#afafaf" }}> Rate</span> - <b>{row.rate}</b>
              </Typography>
              <Typography variant="subtitle1" className={classes.white}>
                <span style={{ color: "#afafaf" }}> Total</span> - <b>{row.total}</b>
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  textMuted: {
    color: "#afafaf",
  },
  white: {
    color: "#fff",
    display: "block",
  },
}));
