import { fetchUtils } from "react-admin";

export default (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({});
  }
  const token = localStorage.getItem("admin_token");
  options.headers.set("Accept", "application/json");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
