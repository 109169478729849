import React from "react";
import TextField from "@material-ui/core/TextField";

export default ({ value, label, fullWidth, className }) => (
  <TextField
    size="small"
    className={className}
    variant="filled"
    label={label}
    value={value}
    disabled
    fullWidth={fullWidth}
  />
);
