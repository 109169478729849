/* eslint-disable camelcase */
import * as React from "react";
import {
  Edit,
  TextInput,
  FormWithRedirect,
  Toolbar,
  FormDataConsumer,
  SaveButton,
  Button,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import ResourceCard from "./components/resourceCard";

export default (props) => {
  const redirect = () => {
    window.history.back();
  };
  const JobTitle = (props) => {
    return <GetTitle {...props} />;
  };
  return (
    <Edit component="div" title={<JobTitle />} undoable={false} {...props}>
      <SubJobForm redirect={redirect} />
    </Edit>
  );
};

const GetTitle = (props) => {
  const { record } = props;
  let id = "";
  if (record.job_id) id = `J${record.job_id}`;
  if (record.job && record.job.client) {
    if (record.job.client.first_name)
      id = `${id}-${record.job.client.first_name.toUpperCase()}`;
    if (record.job.client.last_name)
      id = `${id}-${record.job.client.last_name.toUpperCase()}`;
  }
  id = `${id}-S${record.id}`;
  return <span>{id}</span>;
};

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const SubJobForm = (props) => {
  const classes = useStyles();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <form>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={2} mr={{ xs: 0, sm: "1em" }}>
              <Button label="Back" onClick={formProps.redirect} />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: "1em" }}></Box>
          </Box>

          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={3} mr={{ xs: 0, sm: "1em" }}>
              <Card>
                <CardContent>
                  <FormDataConsumer {...formProps}>
                    {({ formData }) => (
                      <>
                        <Box display={{ xs: "block", sm: "flex" }}>
                          <Typography
                            variant="body2"
                            className={classes.textMuted}
                            display="block"
                          >
                            Width x Height
                          </Typography>
                        </Box>
                        <Box display={{ xs: "block", sm: "flex" }}>
                          <Typography display="block">
                            {`${
                              formData.dimensions[formData.dimensions.on].width
                            } x  ${
                              formData.dimensions[formData.dimensions.on].height
                            }${formData.dimensions.on}`}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </FormDataConsumer>
                  <FormDataConsumer {...formProps}>
                    {({ formData }) =>
                      formData.material_status !== "CLIENT" && (
                        <Box display={{ xs: "block", sm: "flex" }}></Box>
                      )
                    }
                  </FormDataConsumer>

                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={3.5}>
                      <TextInput
                        source="comments"
                        resource="sub_jobs"
                        multiline
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>

                  <Toolbar {...formProps} classes={classes}>
                    <SaveButton />
                  </Toolbar>
                </CardContent>
              </Card>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
              <ResourceCard
                records={formProps.record.resource}
                title="RESOURCES"
                id={formProps.record.id}
              />
            </Box>
          </Box>
        </form>
      )}
    />
  );
};
