import * as React from "react";
import { Fragment, useState } from "react";
import { Button, Confirm, useRefresh, useNotify, useUnselectAll, useUpdate } from "react-admin";

const BulkUpdateButton = ({ resource, selectedIds, status, title, content, label }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [update, { loading }] = useUpdate(resource, selectedIds, status, {
    onSuccess: () => {
      refresh();
      notify("Updated");
      unselectAll(resource);
    },
    onFailure: (error) => notify("Error: Not updated", "warning"),
  });
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    update();
    setOpen(false);
  };

  return (
    <Fragment>
      <Button label={label || "Proceed"} onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title={title || "Update Order"}
        content={content || "Are you sure you want to update the order(s)?"}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default BulkUpdateButton;
