export const getDimension = (type) => {
  switch (type) {
    case "2_POINTS":
      return {
        on: "mm",
        in: { width: 0, height: 0 },
        mm: { width: 0, height: 0 },
      };
    case "4_POINTS":
      break;
    case "8_POINTS":
      break;
    case "16_POINTS":
      break;
    default:
      break;
  }
};

export const PROCESSES = [
  { id: "cutting", name: "Cutting" },
  { id: "engraving", name: "Engraving" },
  { id: "chamfering", name: "Chamfering" },
  { id: "threed", name: "3d" },
  { id: "fluting", name: "Fluting" },
  { id: "coating", name: "Coating" },
  { id: "plating", name: "Plating" },
  { id: "buffing", name: "Buffing" },
  { id: "other", name: "Other" },
];

export const getYears = () => {
  const now = new Date().getUTCFullYear();
  const years = Array(now - (now - 20))
    .fill("")
    .map((v, idx) => now - idx);
  return years;
};
