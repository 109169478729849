import React from "react";
import { Button, useNotify, useRefresh, useDataProvider, useRedirect } from "react-admin";

import Add from "@material-ui/icons/Add";

function SubJobProductCreate({ id }) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  function handleClick() {
    dataProvider
      .create("sub_jobs", {
        data: { job_id: id, type: "2_POINTS", dimension: subjobConfig["2_POINTS"] },
      })
      .then(() => {
        redirect(`/jobs/${id}/2`);
        refresh();
      })
      .catch(() => {
        notify("Error: Sub Job not created", "warning");
      });
  }

  return (
    <>
      <Button onClick={handleClick} label="Sub Job">
        <Add />
      </Button>
    </>
  );
}

const subjobConfig = {
  "2_POINTS": {
    on: "mm",
    in: { width: 0, height: 0 },
    mm: { width: 0, height: 0 },
  },
};

export default SubJobProductCreate;
