import React from "react";

import { Box, useMediaQuery } from "@material-ui/core";

import {
  CreateButton,
  ExportButton,
  Filter,
  ListBase,
  Pagination,
  SearchInput,
  Title,
  TopToolbar,
  useListContext,
} from "react-admin";

import GridList from "./gridList";
import Aside from "./aside";

export const ProductFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const ListActions = ({ isSmall }) => (
  <TopToolbar>
    {isSmall && <ProductFilter context="button" />}
    <CreateButton basePath="/assets" />
    <ExportButton />
  </TopToolbar>
);

const ProductList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <ListBase
      filters={isSmall ? <ProductFilter /> : null}
      perPage={50}
      sort={{ field: "id", order: "ASC" }}
      {...props}
    >
      <ProductListView isSmall={isSmall} />
    </ListBase>
  );
};

const ProductListView = ({ isSmall }) => {
  const { defaultTitle } = useListContext();
  return (
    <>
      <Title defaultTitle={defaultTitle} />
      <ListActions isSmall={isSmall} />
      {isSmall && (
        <Box m={1}>
          <ProductFilter context="form" />
        </Box>
      )}
      <Box display="flex">
        <Aside />
        <Box width={isSmall ? "auto" : "calc(100% - 16em)"}>
          <GridList />
          <Pagination rowsPerPageOptions={[50, 100, 200]} />
        </Box>
      </Box>
    </>
  );
};
export default ProductList;
