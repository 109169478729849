import React, { useState } from "react";
import clsx from "clsx";
import { useDataProvider, useNotify, useRefresh } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import Box from "@material-ui/core/Box";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import red from "@material-ui/core/colors/red";
import SubJobButton from "../../subJob/createButton";
import SubJobButtonNormal from "../../subJob/createButtonNormal";

import SubJobProductButton from "../../subJob/createProductButton";

export default (props) => {
  const classes = useStyles();
  const [details, setDetails] = useState({});
  const { numSelected, selected, withGST } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    dataProvider
      .updateMany("sub_jobs", { ids: selected, data: details })
      .then(() => {
        refresh();
        notify("Elements Updated");
      })
      .catch(() => {
        notify("Error: Elements not updated", "warning");
      });
  };
  const permissions = (localStorage.getItem("access") || "") !== "jaali_production";
  const statusOptions = permissions
    ? [
        { id: "NA", name: "NA" },
        { id: "TO_BE_PROCESSED", name: "TO_BE_PROCESSED" },
        { id: "TOOL_PATH_CREATED", name: "TOOL_PATH_CREATED" },
        { id: "PROCESSED", name: "PROCESSED" },
        { id: "DISPATCHED", name: "DISPATCHED" },
        { id: "DELIVERED", name: "DELIVERED" },
      ]
    : [
        { id: "TO_BE_PROCESSED", name: "TO_BE_PROCESSED" },
        { id: "TOOL_PATH_CREATED", name: "TOOL_PATH_CREATED" },
        { id: "PROCESSED", name: "PROCESSED" },
        { id: "DISPATCHED", name: "DISPATCHED" },
      ];
  return (
    <Toolbar
      className={clsx(classes.toolbarRoot, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Box display={{ xs: "block", sm: "flex" }} justifyContent="flex-end">
          {permissions && (
            <>
              <Box flex={1} ml={{ xs: 0, sm: "2.5em" }}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Artwork</InputLabel>
                  <Select
                    inputProps={{
                      name: "artwork_status",
                      id: "artwork_status",
                    }}
                    value={details.artwork_status || ""}
                    onChange={handleChange}
                    className={classes.input}
                  >
                    <MenuItem value="NA">NA</MenuItem>
                    <MenuItem value="TO_CREATE">TO CREATE</MenuItem>
                    <MenuItem value="CREATED">CREATED</MenuItem>
                    <MenuItem value="SENT_FOR_APPROVAL">SENT_FOR_APPROVAL</MenuItem>
                    <MenuItem value="APPROVED">APPROVED</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box flex={1} ml={{ xs: 0, sm: "2.5em" }}>
                <FormControl className={classes.formControl}>
                  <TextField
                    type="date"
                    label="Scheduled Date"
                    className={classes.input}
                    onChange={handleChange}
                    inputProps={{
                      name: "scheduled_date",
                      id: "scheduled_date",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Box>
            </>
          )}
          <Box flex={1} ml={{ xs: 0, sm: "2.5em" }}>
            <FormControl className={classes.formControl}>
              <InputLabel>Unit</InputLabel>
              <Select
                inputProps={{
                  name: "unit",
                  id: "unit",
                }}
                value={details.unit || ""}
                onChange={handleChange}
                className={classes.input}
              >
                <MenuItem value="NA">NA</MenuItem>
                <MenuItem value="D1">D1</MenuItem>
                <MenuItem value="D2">D2</MenuItem>
                <MenuItem value="B">B</MenuItem>
                <MenuItem value="S">S</MenuItem>
                <MenuItem value="M">M</MenuItem>
                <MenuItem value="L-F">L-F</MenuItem>
                <MenuItem value="Q">Q</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "2.5em" }}>
            <FormControl className={classes.formControl}>
              <InputLabel>Job</InputLabel>
              <Select
                inputProps={{
                  name: "status",
                  id: "status",
                }}
                value={details.status || ""}
                onChange={handleChange}
                className={classes.input}
              >
                {statusOptions.map((s) => (
                  <MenuItem value={s.id} key={s.id}>
                    {s.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "2.5em" }}>
            <FormControl className={classes.formControl}>
              <InputLabel>Title</InputLabel>
              <TextField
                type="text"
                label="Subjob Title"
                className={classes.input}
                onChange={handleChange}
                inputProps={{
                  name: "subjob_title",
                  id: "subjob_title",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "2.5em" }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Box>
        </Box>
      ) : (
        <Box display={{ xs: "block", sm: "flex" }} className={classes.w_100}>
          <Box flex={1}>
            <Typography className={classes.title} variant="h6" id="tableTitle">
              SUB JOBS{" "}
              {permissions && (
                <>
                  <SubJobButtonNormal id={props.job_id} history={props.history} />
                  <SubJobButton id={props.job_id} history={props.history} />
                  <SubJobProductButton id={props.job_id} history={props.history} />
                </>
              )}
            </Typography>
          </Box>
          {permissions && (
            <Box alignSelf="flex-end">
               <Button
                variant="contained"
                color="primary"
                className={classes.button}
                href={`#/edit_job_challans/${props.job_id}`}
              >
                Create Challan
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                href={`#/quotations/${props.job_id}`}
              >
                Create Quote
              </Button>
              <Button
                variant="contained"
                // color="primary"
                className={classes.cssRoot}
                href={`#/job_invoices/${props.job_id}`}
                disabled={!withGST}
              >
                Create Invoice
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  w_100: {
    width: "100%",
  },
  highlight: {
    color: "#1a237e",
    backgroundColor: "#1a237e",
  },
  imageBox: {
    maxWidth: "100px",
    maxHeight: "100px",
  },
  textMuted: {
    color: "#fff",
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  button: {
    margin: theme.spacing(2),
  },
  cssRoot: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));
