import React from "react";
import { Datagrid, TextField } from "react-admin";

// import PanoramaWideAngleSelectIcon from '@mui/icons-material/PanoramaWideAngleSelect';
import { tabs } from "./tabs";

const postRowStyle = (record, tabs) => {
  const color = tabs.find((f) => f.id === record.status) || {};
  return {
    backgroundColor: color.color || "#eeeeee",
  };
};

export default (props) => {
  return (
    <Datagrid
      {...props}
      rowClick="edit"
      rowStyle={(record) => postRowStyle(record, tabs)}
    >
      <TextField source="job_id" />
      <TextField source="material_type" />
      <TextField source="material_thickness" />
      <TextField source="design" />
      <TextField source="status" />
    </Datagrid>
  );
};
