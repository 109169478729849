import Icon from "@material-ui/icons/WebAssetRounded";
import List from "./listView";
import Edit from "./edit";
import Create from "./create";

export default {
  list: List,
  edit: Edit,
  create: Create,
  icon: Icon,
  label: "Assets",
  options: { label: "Assets" },
  name: "assets",
};
