import React from "react";
import { Datagrid, TextField, FunctionField } from "react-admin";

import Icon from "@material-ui/icons/Stop";

import { tabs } from "./tabs";

const postRowStyle = (record, tabs) => {
  const color = tabs.find((f) => f.id === record.status) || {};
  return {
    backgroundColor: color.color || "#eeeeee",
  };
};

export default (props) => {
  return (
    <Datagrid
      {...props}
      rowClick="edit"
      rowStyle={(record) => postRowStyle(record, tabs)}
    >
      <TextField source="id" />
      <TextField source="invoice_id" />
      <FunctionField
        label="Name"
        render={(record) =>
          record.vendor && (
            <span>
              <b>
                {record.vendor.first_name} {record.vendor.last_name || ""}
              </b>
              <br />
              <b>fn</b>: {record.vendor.firm_name || ""}
            </span>
          )
        }
      />

      <TextField source="vendor.phone" label="Phone" />

      <FunctionField
        label="Payment"
        render={(record) => <PaymentStatus {...record} />}
      />
    </Datagrid>
  );
};

const PaymentStatus = (record) => {
  const { grand_total, paid } = record;

  if (paid === 0) {
    return <Icon style={{ color: "#f44336" }} />;
  }
  if (grand_total === paid) {
    return <Icon style={{ color: "#9ccc65" }} />;
  }
  return <Icon style={{ color: "orange" }} />;
};
