import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

function ChipStatus({ text, color }) {
  const classes = useStyles();
  return <Chip className={classes[color]} label={text} />;
}

const useStyles = makeStyles({
  blue: {
    backgroundColor: "rgba(52, 158, 235, 0.5)",
  },
  green: {
    backgroundColor: "rgba(52, 235, 116, 0.5)",
  },
  orange: {
    backgroundColor: "rgba(235, 174, 52, 0.5)",
  },
  red: {
    backgroundColor: "rgba(235, 52, 52, 0.5)",
  },
  grey: {
    backgroundColor: "rgba(217, 217, 217, 0.5)",
  },
});

export default ChipStatus;
