import React from "react";
import { Filter, List, NumberInput, Responsive, Pagination } from "react-admin";

import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ConfirmButton from "../../../components/confirmButton";
import DataGrid from "./dataList";
import { tabs as ts } from "./tabs";

const JobFilter = ({ ...props }) => (
  <Filter {...props}>
    <NumberInput source="job_id" label="Job Id" alwaysOn />
  </Filter>
);

const tabs = ts;
function TabbedDatagrid(props) {
  function handleChange(event, value) {
    const { filterValues, setFilters } = props;
    setFilters({ ...filterValues, status: value });
  }
  const { filterValues, resource } = props;
  const initValues =
    JSON.parse(localStorage.getItem(`${resource}_count`)) || {};
  return (
    <>
      <Tabs
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={`${choice.name} (${initValues[choice.name] || 0})`}
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <Responsive medium={<DataGrid {...props} />} />
    </>
  );
}

const PostBulkActionButtons = (props) => {
  return (
    <ConfirmButton {...props} options={tabs.filter((f) => f.id !== "ALL")} />
  );
};

const JobPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 150]} {...props} />
);

export default function SubJobList(props) {
  return (
    <List
      {...props}
      filterDefaultValues={{ status: tabs[0].id }}
      sort={{ field: "updated_at", order: "DESC" }}
      perPage={50}
      filters={<JobFilter />}
      pagination={<JobPagination />}
      bulkActionButtons={<PostBulkActionButtons />}
    >
      <TabbedDatagrid />
    </List>
  );
}
