import React from "react";
import { Toolbar, DeleteButton, SaveButton } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const SaveButtonAware = ({ disabled, ...rest }) => (
  <SaveButton disabled={disabled} {...rest} />
);

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButtonAware disabled={props.disabled} />
    <DeleteButton undoable={false} />
  </Toolbar>
);

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export default CustomToolbar;
export { SaveButtonAware };
