/* eslint-disable camelcase */
import React, { useState } from "react";
import Viewer from "react-viewer";

export default ({ data: row }) => {
  const [open, setOpen] = useState(false);
  const toggle = (st) => {
    setOpen(st);
  };
  return (
    <div>
      <div
        style={{
          aspectRatio: "1/1",
        }}
      >
        {row.resource.length > 0 && (
          <>
            <Viewer
              visible={open}
              activeIndex={0}
              onClose={() => toggle(false)}
              images={[
                {
                  src: `https://drive.google.com/thumbnail?authuser=0&sz=w700&id=${row.resource[0].resource}`,
                  
                  alt: row.resource[0].resource,
                },
              ]}
            />
            <img
              src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${row.resource[0].resource}`}
              alt={row.resource[0].name}
              style={{ maxHeight: "100%", maxWidth: "100%" }}
              onClick={() => toggle(true)}
            />
          </>
        )}
      </div>
    </div>
  );
};
