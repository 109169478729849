import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(1), width: "100%" },
}));
export default ({ value, label }) => {
  const classes = useStyles();
  return (
    <TextField
      size="small"
      className={classes.root}
      variant="filled"
      label={label}
      value={value}
      disabled
    />
  );
};
