/* eslint-disable react/jsx-key */
import React from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import { createMuiTheme } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";

import withClearCache from "./ClearCache";

import dataProvider from "./dataprovider";
import authProvider from "./authProvider";

import category from "./containers/enoch/category";
import subCategory from "./containers/enoch/subCategory";
import advertisement from "./containers/enoch/advertisement";
import dashboard from "./containers/enoch/dashboard";
import testimonial from "./containers/enoch/testimonial";
import product from "./containers/enoch/product";
import worksheet from "./containers/enoch/worksheet";
import productVariant from "./containers/enoch/productVariant";
import productField from "./containers/enoch/productField";
import tag from "./containers/enoch/tag";
import order from "./containers/enoch/order";
import user from "./containers/enoch/user";

import admin from "./containers/admin";

import color from "./containers/jaali/color";
import client from "./containers/jaali/client";
import job from "./containers/jaali/job";
import subJob from "./containers/jaali/subJob";
import resource from "./containers/jaali/resource";
import subResource from "./containers/jaali/subResource";
import comment from "./containers/jaali/comment";
import jobPayment from "./containers/jaali/jobPayment";
import jobTransport from "./containers/jaali/jobTransport";
// import brand from "./containers/jaali/brand";
import area from "./containers/jaali/area";
import material from "./containers/jaali/material";
import asset from "./containers/jaali/asset";
import assetCategory from "./containers/jaali/assetCategory";
import jaaliTag from "./containers/jaali/jaaliTag";
import jaaliProduct from "./containers/jaali/jaaliProduct";
import quotation, { quotationCreate } from "./containers/jaali/quotation";
import jobInvoice, { editJobInvoice } from "./containers/jaali/jobInvoice";
import note from "./containers/jaali/note";
import remnant from "./containers/jaali/remnant";
import vendor from "./containers/jaali/vendor";

import uploadStatusReducer from "./dataprovider/uploadStatusReducer";

import Layout from "./layout";
import LoginPage from "./containers/login";
import purchase from "./containers/jaali/purchase";
import purchasePayment from "./containers/jaali/purchasePayment";
import customRoutes from "./customRoutes";
import assetVariant from "./containers/jaali/assetVariant";
import jobRemnant from "./containers/jaali/jobRemnant";
import jobChallan, { editJobChallans } from "./containers/jaali/jobChallan";

const messages = {
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en", {
  allowMissing: true,
});

const MainApp = () => {
  const access = localStorage.getItem("access");
  const permissions = access || "";
  let theme;
  if (!(permissions === "enoch")) {
    theme = myTheme;
  }

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      customReducers={{ upload: uploadStatusReducer }}
      theme={theme}
      layout={Layout}
      loginPage={LoginPage}
      customRoutes={customRoutes}
    >
      {(perms) => getResourceList(perms)}
    </Admin>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function getResourceList(permissions) {
  let resources = [];
  if (permissions === "enoch") {
    resources = [
      <Resource {...order} />,
      <Resource name="sub_orders" />,
      <Resource {...category} />,
      <Resource {...subCategory} />,
      <Resource {...product} />,
      <Resource {...worksheet} />,
      <Resource {...productVariant} />,
      <Resource {...color} />,
      <Resource {...tag} />,
      <Resource {...dashboard} />,
      <Resource {...productField} />,
      <Resource {...testimonial} />,
      <Resource {...user} />,
      <Resource {...advertisement} />,
    ];
  }
  if (permissions === "jaali_production") {
    resources = [
      <Resource {...job} edit={job.editProduction} />,
      <Resource {...subJob} edit={subJob.editProduction} />,
      <Resource name="clients" />,
      <Resource name="ref_clients" />,
      <Resource {...resource} />,
      <Resource {...subResource} />,
      <Resource {...comment} />,
      <Resource {...remnant} />,
      <Resource {...note} />,
      ...resources,
    ];
  }
  if (permissions === "jaali") {
    resources = [
      <Resource {...job} />,
      <Resource {...subJob} />,
      <Resource {...client} />,
      <Resource {...vendor} />,
      <Resource {...purchase} />,
      <Resource {...purchasePayment} />,
      <Resource name="ref_clients" />,
      <Resource {...resource} />,
      <Resource {...subResource} />,
      <Resource {...comment} />,
      <Resource {...jobPayment} />,
      <Resource {...jobRemnant} />,
      <Resource {...jobTransport} />,
      <Resource {...area} />,
      <Resource {...material} />,
      <Resource {...quotation} />,
      <Resource {...quotationCreate} />,
      <Resource {...jobInvoice} />,
      <Resource {...editJobInvoice} />,
      <Resource {...jaaliTag} />,
      <Resource {...jaaliProduct} />,
      <Resource {...remnant} />,
      <Resource {...note} />,
      ...resources,
    ];
  }
  if (permissions === "jaali_admin") {
    resources = [
      <Resource {...job} />,
      <Resource {...subJob} />,
      <Resource {...remnant} />,
      <Resource {...jobInvoice} />,
      <Resource {...editJobChallans} />,
      <Resource {...jobChallan} />,
      <Resource {...client} />,
      <Resource {...vendor} />,
      <Resource {...purchase} />,
      <Resource {...purchasePayment} />,
      <Resource name="ref_clients" />,
      <Resource {...resource} />,
      <Resource {...subResource} />,
      <Resource {...comment} />,
      <Resource {...jobPayment} />,
      <Resource {...jobRemnant} />,
      <Resource {...jobTransport} />,
      <Resource {...material} />,
      <Resource {...area} />,
      <Resource {...asset} />,
      <Resource {...assetCategory} />,
      <Resource {...assetVariant} />,
      <Resource {...quotation} />,
      <Resource {...quotation} />,
      <Resource {...quotationCreate} />,
      <Resource {...editJobInvoice} />,
      <Resource {...jaaliTag} />,
      <Resource {...jaaliProduct} />,
      <Resource {...note} />,
      <Resource {...admin} />,
      ...resources,
    ];
  }
  if (permissions === "jaali_asset_admin") {
    resources = [
      <Resource {...material} />,
      <Resource {...area} />,
      <Resource {...asset} />,
      <Resource {...assetCategory} />,
      <Resource {...assetVariant} />,
      <Resource {...jaaliTag} />,
      <Resource {...note} />,
      ...resources,
    ];
  }
  return resources;
}

export default App;

const myTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: indigo[300],
      contrastText: grey[900],
    },
    secondary: {
      main: indigo[900],
      contrastText: grey[300],
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Arial", "sans-serif"].join(
      ","
    ),
  },
  overrides: {
    MuiButton: {
      root: {
        color: "light", // Some CSS
      },
      ".RaLayout-root-1": {
        minHeight: "98vh",
      },
    },
  },
  spacing: 0,
});
