import * as React from "react";

import { Button } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";

import { useNotify, useDataProvider, useRefresh, Confirm } from "react-admin";

export default ({ resource, id }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const handleConfirm = () => {
    setLoading(true);
    dataProvider
      .delete(resource, { id })
      .then(() => {
        refresh();
        notify("Element Deleted");
        setLoading(false);
      })
      .catch(() => {
        notify("Error: Not Deleted", "warning");
        setLoading(false);
      });
  };
  return (
    <>
      <Button
        style={{ color: "red" }}
        onClick={() => setOpen(true)}
        size="small"
      >
        <Delete />
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title="Delete Element"
        content="Are you sure you want to delete the item?"
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
