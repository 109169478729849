/* eslint-disable camelcase */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Chip from "../../../../../components/chip";

export default ({ data: row }) => {
  return (
    <div>
      <Box
        display={{ xs: "block", sm: "flex" }}
        mt={{ xs: "1em" }}
        style={{ justifyContent: "space-between" }}
      >
        <UnitStatus record={row.unit} />
      </Box>
      <Box
        display={{ xs: "block", sm: "flex" }}
        mt={{ xs: "1em" }}
        style={{ justifyContent: "space-between" }}
      >
        <ArtworkStatus record={row.artwork_status} />
      </Box>
      <Box
        display={{ xs: "block", sm: "flex" }}
        mt={{ xs: "1em" }}
        style={{ justifyContent: "space-between" }}
      >
        <MaterialStatus record={row.material_status} />
      </Box>
      <Box
        display={{ xs: "block", sm: "flex" }}
        mt={{ xs: "1em" }}
        style={{ justifyContent: "space-between" }}
      >
        <ProcessStatus record={row.status} />
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  textMuted: {
    color: "#afafaf",
    alignSelf: "center",
    marginRight: 3,
  },
  white: {
    color: "#fff",
  },
}));

const MaterialStatus = ({ record }) => {
  let color;
  const classes = useStyles();
  if (record === "OURS") color = "green";
  else if (record === "CLIENT") color = "orange";
  else if (record === "BOTH") color = "blue";
  else color = "grey";
  return (
    <>
      <Typography variant="body2" className={classes.textMuted} display="block">
        Material
      </Typography>
      <Chip color={color} text={record || "NA"} />
    </>
  );
};

const ArtworkStatus = ({ record }) => {
  let color;
  const classes = useStyles();
  if (record === "CREATED") color = "blue";
  else if (record === "TO_CREATE") color = "red";
  else if (record === "SENT_FOR_APPROVAL") color = "orange";
  else if (record === "APPROVED") color = "green";
  else if (record === "NA") color = "grey";
  else color = "red";
  return (
    <>
      <Typography variant="body2" className={classes.textMuted} display="block">
        Artwork
      </Typography>
      <Chip color={color} text={record || "TO CREATE"} />
    </>
  );
};
const ProcessStatus = ({ record }) => {
  let color;
  const classes = useStyles();
  if (record === "TO_BE_PROCESSED") color = "orange";
  else if (record === "PROCESSED") color = "green";
  else if (record === "TOOL_PATH_CREATED") color = "black";
  else if (record === "DISPATCHED") color = "pink";
  else if (record === "DELIVERED") color = "blue";
  else if (record === "NA") color = "grey";
  else color = "red";
  return (
    <>
      <Typography variant="body2" className={classes.textMuted} display="block">
        Job
      </Typography>
      <Chip color={color} text={record || "NO_STATUS"} />
    </>
  );
};

const UnitStatus = ({ record }) => {
  let color;
  const classes = useStyles();
  if (record) color = "green";
  if (record.unit === "NA") {
    color = "grey";
  } else color = "green";
  return (
    <>
      <Typography variant="body2" className={classes.textMuted} display="block">
        Unit
      </Typography>
      <Chip color={color} text={record || "NA"} />
    </>
  );
};
