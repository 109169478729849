/* eslint-disable camelcase */
import React from "react";

import TwoPoints from "../svgs/twoPoints";
import FourPoints from "../svgs/fourPoints";
import SixPoints from "../svgs/sixPoints";
import EightPoints from "../svgs/eightPoints";

export const getTruncatedTitle = (title) => {
  if (title.length > 80) {
    return title.substring(0, 80) + "...";
  }
  return title;
};
export default ({ data: row, setShowModal }) => {
  const dimensions = JSON.parse(row.dimensions);
  if (!dimensions) {
    return null;
  }
  return (
    <a
      onClick={() => {
        setShowModal({
          dimensions,
          type: row.type,
        });
      }}
    >
      <div style={{ display: "flex", maxHeight: "300px" }}>
        {row.type === "2_POINTS" && <TwoPoints dimensions={dimensions} />}
        {row.type === "4_POINTS" && <FourPoints dimensions={dimensions} />}
        {row.type === "6_POINTS" && <SixPoints dimensions={dimensions} />}
        {row.type === "8_POINTS" && <EightPoints dimensions={dimensions} />}
      </div>
    </a>
  );
};
