/* eslint-disable camelcase */
import React, { useCallback, useRef, useState } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import { AgGridReact as List, AgGridColumn as Field } from "ag-grid-react";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./style.css";

import Checkbox from "@material-ui/core/Checkbox";

import EnhancedTableToolbar from "./subJobsToolbar";
import SubJobCopyButton from "../../subJob/copyButton";
import Cell1 from "./subJobCell.js/cell1";
import Cell2 from "./subJobCell.js/cell2";
import Cell4 from "./subJobCell.js/cell4";
import Cell5 from "./subJobCell.js/cell5";
import Cell6 from "./subJobCell.js/cell6";
import httpClient from "../../../../dataprovider/httpClient";
import { apiUrl } from "../../../../constants";
import { Button, useNotify } from "react-admin";
import TwoPoints from "./svgs/twoPoints";
import FourPoints from "./svgs/fourPoints";
import SixPoints from "./svgs/sixPoints";
import EightPoints from "./svgs/eightPoints";

export default ({ records, job_id, showTotal, ...props }) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [showModal, setShowModal] = useState(null);
  const notify = useNotify();

  const gridRef = useRef();
  const rec = records.length > 0 ? records.sort((a, b) => a.priority - b.priority) : records;

  const handleClick = (event, id) => {
    if (event.target.type === "checkbox") {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    }
  };
  const permissions = localStorage.getItem("access") || "";
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleUpdate = async (orderedData) => {
    await httpClient(`${apiUrl}/sub_jobs_order`, {
      method: "POST",
      body: JSON.stringify({ orderedData }),
    })
      .then(() => {})
      .catch((err) => {
        notify(err.message, { type: "warning" });
      });
  };
  const onRowDragEnd = (e) => {
    const d = e.api.rowModel.rowsToDisplay.map((r) => r.data.id);
    handleUpdate(d);
  };

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const rowStyle = { background: "#424242" };

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = (params) => {
    if (params.node.data.is_active === 1) {
      return { background: "#012f1f" };
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          job_id={job_id}
          {...props}
        />
        <div className="ag-theme-material" style={{ height: `calc(100vh - 18em)`, width: "100%" }}>
          <List
            ref={gridRef}
            rowData={rec}
            suppressMoveWhenRowDragging={false}
            animateRows
            rowDragManaged
            onRowDragEnd={onRowDragEnd}
            suppressRowHoverHighlight
            autoHeight
            setHeaderHeight={0}
            onFirstDataRendered={onFirstDataRendered}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
          >
            <Field
              field={"id"}
              key={"id"}
              skipHeader
              rowDrag
              resizable={false}
              width={180}
              cellRendererFramework={({ data: row }) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${row.id}`;
                return (
                  <>
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                      color="primary"
                      className="selectCheckbox"
                      onChange={(e) => handleClick(e, row.id)}
                    />
                    {permissions !== "jaali_production" && (
                      <div style={{ paddingTop: "1rem" }}>
                        <SubJobCopyButton id={row.id} />
                      </div>
                    )}
                    {permissions !== "jaali_production" && (
                      <div style={{ paddingTop: "1rem" }}>
                        <Button
                          color="danger"
                          href={`/#/sub_jobs/${row.id}`}
                          label="Edit"
                          variant="contained"
                          size="small"
                        >
                          <EditIcon />
                        </Button>
                      </div>
                    )}
                  </>
                );
              }}
              autoHeight
            />
            <Field
              field={"info1"}
              key={"info1"}
              flex={1}
              skipHeader
              cellRendererFramework={({ data: row }) => <Cell1 data={row} showTotal={showTotal} />}
              autoHeight
            />
            <Field
              field={"info2"}
              key={"info2"}
              flex={1}
              skipHeader
              autoHeight
              cellRendererFramework={({ data: row }) => (
                <Cell6 data={row} setShowModal={setShowModal} />
              )}
            />
            <Field
              field={"info3"}
              key={"info3"}
              flex={1}
              wrapText
              skipHeader
              cellRendererFramework={({ data: row }) => <Cell2 data={row} />}
              autoHeight
            />
            <Field
              field={"info4"}
              key={"info4"}
              flex={0.9}
              skipHeader
              cellRendererFramework={({ data: row }) => <Cell5 data={row} />}
              autoHeight
            />
            <Field
              field={"info5"}
              key={"info5"}
              align="right"
              flex={1}
              cellRendererFramework={({ data: row }) => <Cell4 data={row} />}
              autoHeight
            />
          </List>
        </div>
      </Paper>
      {showModal && (
        <div className={classes.overlay}>
          <a onClick={() => setShowModal(null)}>
            <div style={{ position: "absolute", top: 10, right: 10 }}>
              <IconButton
                onClick={() => {
                  setShowModal(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div
              style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}
            >
              {showModal.type === "2_POINTS" && <TwoPoints dimensions={showModal.dimensions} />}
              {showModal.type === "4_POINTS" && <FourPoints dimensions={showModal.dimensions} />}
              {showModal.type === "6_POINTS" && <SixPoints dimensions={showModal.dimensions} />}
              {showModal.type === "8_POINTS" && <EightPoints dimensions={showModal.dimensions} />}
            </div>
          </a>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
  },
  inactiveRow: {
    background: "#004d40",
  },
  activeRow: {
    background: "#424242",
  },

  highlight: {
    color: "#1e2052",
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  imageBox: {
    maxWidth: "100px",
    maxHeight: "100px",
  },
  textMuted: {
    color: "#afafaf",
  },
  overlay: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 10000,
    background: "rgba(0,0,0)",
  },
}));
