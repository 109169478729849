import Edit from "./edit";

export default {
  edit: Edit,
  label: "Challans",
  options: { label: "Challans" },
  name: "job_challans",
};

export const editJobChallans = {
  edit: Edit,
  name: "edit_job_challans",
};
