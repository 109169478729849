import React from "react";
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from "react-admin";

export default (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm redirect={`/jaali_tags`}>
      <TextInput source="id" label="name" validate={required()} />
      <ReferenceInput
        source="category_id"
        reference="asset_categories"
        label="Category"
        perPage={1000}
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="is_filter" />
    </SimpleForm>
  </Create>
);
