import Icon from "@material-ui/icons/DoneOutline";
import List from "./list";
import Edit from "./edit";
import Create from "./create";

export default {
  list: List,
  edit: Edit,
  create: Create,
  icon: Icon,
  label: "Area",
  options: { label: "Area" },
  name: "areas",
};
