import React from "react";
import { Create, SimpleForm, required, TextInput } from "react-admin";

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect={`/testimonials`}>
      <TextInput source="name" validate={required()} />
      <TextInput source="title" validate={required()} />
      <TextInput source="company" validate={required()} />
      <TextInput source="testimonial" validate={required()} multiline />
    </SimpleForm>
  </Create>
);
