import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
} from "react-admin";

export default (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="type" />
      <NumberField source="thickness" label="Thickness(mm)" textAlign="left" />
      <TextField source="hsn" />
      <TextField source="brand" />
      <TextField source="color" />
      <NumberField
        source="price"
        label="Price"
        options={{ style: "currency", currency: "INR" }}
        textAlign="left"
      />

      <EditButton />
    </Datagrid>
  </List>
);
