import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  FormDataConsumer,
  Toolbar,
  required,
  SelectInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

export default (props) => {
  const classes = useStyles();
  const { id } = props;
  const redirect = () => `/jobs/${id}/1`;
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect={redirect} toolbar={<Toolbar alwaysEnableSaveButton />}>
        <TextInput source="id" validate={required()} />
        <TextInput
          source="client.first_name"
          label="Name"
          formClassName={classes.input2}
          validate={required()}
        />
        <TextInput
          source="client.last_name"
          label="Name"
          formClassName={classes.input2}
          validate={required()}
        />
        <TextInput source="client.firm_name" label="Firm" formClassName={classes.input2} />
        <TextInput
          source="client.phone"
          label="Phone"
          formClassName={classes.input2}
          validate={required()}
        />
        <TextInput source="client.address" label="Address" formClassName={classes.input2} />
        <SelectInput
          source="grid"
          choices={[
            { id: 1, name: "1x1" },
            { id: 2, name: "2x2" },
            { id: 3, name: "3x3" },
          ]}
          formClassName={classes.input2}
          validate={required()}
        />

        <ArrayInput source="sub_job">
          <SimpleFormIterator>
            <TextInput source="comment" label="Comment" formClassName={classes.input1} />
            <TextInput
              source="material_thickness"
              label="M-Thick"
              formClassName={classes.input}
              validate={required()}
            />
            <TextInput
              source="material_type"
              label="M-type"
              formClassName={classes.input}
              validate={required()}
            />
            <TextInput source="design" label="Design" formClassName={classes.input} />
            <NumberInput
              source="quantity"
              label="Qty"
              defaultValue={0}
              formClassName={classes.input}
              validate={required()}
            />
            <NumberInput source="pcs" label="PCS" disabled formClassName={classes.input} />
            <FormDataConsumer formClassName={classes.input}>
              {({ scopedFormData, getSource }) => (
                <SelectInput
                  source={getSource("image")}
                  label="Image"
                  choices={getImages(scopedFormData)}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={classes.input3}>
              {({ scopedFormData }) =>
                getDefaultImage(scopedFormData) ? (
                  <img
                    src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${
                      getDefaultImage(scopedFormData).resource
                    }`}
                    width="200px"
                    alt="remnant"
                  />
                ) : (
                  ""
                )
              }
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    display: "inline-flex",
    marginRight: theme.spacing(2),
    maxWidth: "7%",
  },
  input1: {
    display: "inline-flex",
    marginRight: theme.spacing(2),
    maxWidth: "50%",
  },
  input2: {
    display: "inline-flex",
    marginRight: theme.spacing(2),
    maxWidth: "25%",
  },
  input3: {
    display: "display-block",
    marginRight: theme.spacing(2),
    maxWidth: "7%",
  },
  flex: {
    display: "flex",
  },
  totals: {
    width: theme.spacing(25),
    marginBottom: "0 !important",
    padding: 0,
  },
}));

const getImages = (formData) => {
  if (!formData || !formData.resource) {
    return [];
  }
  return formData.resource.map((r) => ({
    id: r.resource,
    name: r.name,
  }));
};

const getDefaultImage = (formData) => {
  if (!formData || !formData.resource) {
    return false;
  }
  const defaultRes = formData.resource.filter((r) => r.is_default);
  if (defaultRes.length > 0) {
    return defaultRes[0];
  }
  return false;
};
