import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  Filter,
  SearchInput,
  FunctionField,
  BulkDeleteButton,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import Icon from "@material-ui/icons/FiberManualRecord";
import CopyButton from "./copyButton";

const ProductsFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput
      source="tags"
      reference="tags"
      sort={{ field: "id", order: "ASC" }}
      alwaysOn
    >
      <SelectInput optionText="id" />
    </ReferenceInput>
    <ReferenceInput
      source="category_id"
      reference="categories"
      sort={{ field: "id", order: "ASC" }}
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const ActionButtons = (props) => (
  <>
    <CopyButton {...props} />
    <BulkDeleteButton {...props} undoable={false} />
  </>
);

export default (props) => (
  <List
    {...props}
    filters={<ProductsFilter />}
    bulkActionButtons={<ActionButtons history={props.history} />}
  >
    <Datagrid>
      <FunctionField
        label="Image"
        render={(record) =>
          record.image_1 && (
            <img
              src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${record.image_1}`}
              alt={record.supc}
            />
          )
        }
      />
      <TextField label="Category" source="category.name" />
      <TextField label="Sub Category" source="sub_category.name" />

      <TextField source="title" />
      <FunctionField
        label="Price(with Gst)"
        render={(record) =>
          `₹${Math.round(record.selling_price * (1 + record.gst / 100))}`
        }
      />
      <NumberField source="stock" />
      <FunctionField
        label="Live"
        render={(record) =>
          record.is_enabled ? (
            <Icon style={{ color: "green" }} />
          ) : (
            <Icon style={{ color: "red" }} />
          )
        }
      />
      <FunctionField
        label="On Website"
        render={(record) =>
          record.on_website ? (
            <Icon style={{ color: "blue" }} />
          ) : (
            <Icon style={{ color: "red" }} />
          )
        }
      />
      <EditButton />
    </Datagrid>
  </List>
);
