import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  FormDataConsumer,
  Toolbar,
  required,
  SelectInput,
  BooleanInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "./textField";

export default (props) => {
  const classes = useStyles();
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect="" toolbar={<Toolbar alwaysEnableSaveButton />}>
        <TextInput
          source="invoice_id"
          disabled
          formClassName={classes.input2}
        />
        <TextInput
          source="job_id"
          label="Job ID"
          disabled
          formClassName={classes.input2}
        />
        <br />
        <TextInput
          source="client.first_name"
          label="Name"
          formClassName={classes.input2}
          validate={required()}
        />
        <TextInput
          source="client.last_name"
          label="Name"
          formClassName={classes.input2}
          validate={required()}
        />
        <TextInput
          source="client.firm_name"
          label="Firm"
          formClassName={classes.input2}
        />
        <TextInput
          source="client.phone"
          label="Phone"
          formClassName={classes.input2}
          validate={required()}
        />
        <TextInput
          source="client.address"
          label="Address"
          formClassName={classes.input2}
        />
        <TextInput
          source="client.gst"
          label="GSTIN"
          formClassName={classes.input2}
        />
        <SelectInput
          source="gst_type"
          choices={[
            { id: "IGST", name: "IGST" },
            { id: "CGST_SGST", name: "CGST_SGST" },
          ]}
          formClassName={classes.input2}
          validate={required()}
        />
        <FormDataConsumer formClassName={classes.input1}>
          {({ formData }) =>
            formData.image && <BooleanInput source="cover_image" />
          }
        </FormDataConsumer>

        <ArrayInput source="sub_job">
          <SimpleFormIterator>
            <TextInput
              source="comment"
              label="Comment"
              formClassName={classes.input1}
            />
            <TextInput
              source="material_thickness"
              label="M-Thick"
              formClassName={classes.input}
              validate={required()}
            />
            <TextInput
              source="material_type"
              label="M-type"
              formClassName={classes.input}
              validate={required()}
            />
            <TextInput
              source="design"
              label="Design"
              formClassName={classes.input}
            />
            <NumberInput
              source="quantity"
              label="Qty"
              defaultValue={0}
              formClassName={classes.input}
              validate={required()}
            />
            <NumberInput
              source="rate"
              label="Rate"
              defaultValue={0}
              formClassName={classes.input}
              validate={required()}
            />
            <FormDataConsumer formClassName={classes.input}>
              {({ scopedFormData, getSource }) => (
                <TextField
                  label="Total"
                  className={classes.flex}
                  value={calcRowTotal(scopedFormData)}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={classes.input}>
              {({ scopedFormData, getSource }) => (
                <SelectInput
                  source={getSource("image")}
                  label="Image"
                  choices={getImages(scopedFormData)}
                />
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>

        <FormDataConsumer>
          {({ formData }) => (
            <>
              <TextField
                label="Sub Total"
                value={calcSubTotal(formData)}
                className={classes.customInput}
              />
              <NumberInput
                source="pack"
                label="Packing"
                defaultValue={0}
                className={classes.input2}
              />
               <NumberInput
                source="misc"
                label="Miscellaneous"
                defaultValue={0}
                className={classes.input2}
              />
              <NumberInput
                source="shipping"
                label="Shipping"
                defaultValue={0}
                className={classes.input2}
              />

              <NumberInput
                source="discount"
                label="Discount"
                defaultValue={0}
                className={classes.input2}
              />
            </>
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) => (
            <>
              <TextField
                label="GST"
                value={calcGST(formData)}
                className={classes.customInput}
              />
              <TextField
                label="Grand Total"
                value={calcTotal(formData)}
                className={classes.customInput}
              />
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    display: "inline-flex",
    marginRight: theme.spacing(2),
    maxWidth: "7%",
  },
  customInput: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  input1: {
    display: "inline-flex",
    marginRight: theme.spacing(2),
    maxWidth: "50%",
  },
  input2: {
    display: "inline-flex",
    marginRight: theme.spacing(2),
    maxWidth: "25%",
  },

  flex: {
    display: "inline-flex",
  },
  totals: {
    width: theme.spacing(25),
    marginBottom: "0 !important",
    padding: 0,
  },
}));

const calcSubTotal = (formData) => {
  if (!formData) {
    return 0;
  }
  const subJobs = formData.sub_job;
  let total = 0;
  subJobs.map((s) => {
    if (!s) {
      return "";
    }
    total += s.quantity * s.rate;
    return "";
  });
  return total;
};

const calcTotalWithPackShipDiscount = (formData) => {
  const subTotal = calcSubTotal(formData);
  const { discount, pack, shipping } = formData;
  return subTotal + pack + shipping - discount;
};

const calcGST = (formData) => {
  const subTotal = calcTotalWithPackShipDiscount(formData);
  return subTotal * 0.18;
};

const calcRowTotal = (formData) => {
  if (!formData || !formData.quantity || !formData.rate) {
    return 0;
  }
  const { quantity, rate } = formData;
  return quantity * rate;
};

const calcTotal = (formData) => {
  if (!formData) {
    return 0;
  }
  const subTotal = calcTotalWithPackShipDiscount(formData);
  const gst = calcGST(formData);
  return subTotal + gst;
};

const getImages = (formData) => {
  if (!formData || !formData.resource) {
    return [];
  }
  return formData.resource.map((r) => ({
    id: r.resource,
    name: r.name,
  }));
};
