/* eslint-disable camelcase */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";

import CopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";

import { useNotify } from "react-admin";
import { getTruncatedTitle } from "./cell1";

export default ({ data: row }) => {
  const classes = useStyles();
  const notify = useNotify();
  const copyToClipboard = (comment) => {
    navigator.clipboard.writeText(comment || "");
    notify("Copied to Clipboard");
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Typography variant="body2" className={classes.textMuted} display="block">
        Title
        <IconButton aria-label="Copy" onClick={() => copyToClipboard(row.subjob_title)}>
          <CopyIcon fontSize="small" />
        </IconButton>
      </Typography>
      <Tooltip title={row.subjob_title} placement="bottom">
        <Typography variant="body2" className={classes.white} display="block">
          <b>{row.subjob_title ? getTruncatedTitle(row.subjob_title) : "-"}</b>
        </Typography>
      </Tooltip>
      <Typography variant="body2" className={classes.textMuted} display="block">
        Comments
        <IconButton aria-label="Copy" onClick={() => copyToClipboard(row.comments)}>
          <CopyIcon fontSize="small" />
        </IconButton>
      </Typography>
      <Tooltip title={row.subjob_title} placement="bottom">
        <Typography variant="body2" className={classes.white} display="block">
          <b>{row.comments ? getTruncatedTitle(row.comments) : "-"}</b>
        </Typography>
      </Tooltip>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  textMuted: {
    color: "#afafaf",
  },
  white: {
    color: "#fff",

    wordBreak: "break-word",
  },
}));
