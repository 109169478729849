import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/core/styles";
import Viewer from "react-viewer";
import moment from "moment";
import ResourceButton from "../../subResource/createButton";
import DeleteButton from "../../../../components/deleteButton";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  img: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
export default ({ records, title, id, category }) => {
  const [open, setOpen] = useState(false);
  const [curr, setCurr] = useState(0);

  const toggle = (val) => {
    setOpen(val);
  };
  const handleToggle = (i) => {
    setCurr(i);
    toggle(true);
  };
  const classes = useStyles();
  const permissions = (localStorage.getItem("access") || "") !== "jaali_production";
  return (
    <Card className={classes.root} id="Master">
      <Viewer
        visible={open}
        activeIndex={curr}
        onClose={() => toggle(false)}
        images={
          records
            ? records.map((res) => ({
                src: `https://drive.google.com/thumbnail?authuser=0&sz=w700&id=${res.resource}`,
                alt: res.name,
              }))
            : []
        }
      />

      <Box display={{ xs: "flex" }}>
        <Box flexGrow={1}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            {title}
          </Typography>
        </Box>
        <Box>
          <ResourceButton id={id} category={category} />
        </Box>
      </Box>
      <Table className={classes.table}>
        <TableBody>
          {records &&
            records.map((row, i) => (
              <TableRow key={row.name}>
                <TableCell scope="row" padding="none" size="small">
                  {row.is_default ? "DEFAULT" : ""}
                  <Button onClick={() => handleToggle(i)}>
                    <img
                      src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${row.resource}`}
                      alt={row.resource}
                      width="100px"
                    />
                  </Button>
                </TableCell>
                <TableCell scope="row">
                  <Typography variant="caption" display="block">
                    <b>{row.name}</b>
                  </Typography>
                  <Typography variant="caption" display="block">
                    {moment(row.created_at).format("lll")}
                  </Typography>
                </TableCell>
                <TableCell scope="row">
                  <IconButton
                    color="primary"
                    href={`https://drive.google.com/thumbnail?authuser=0&sz=w700&id=${row.resource}`}
                    size="small"
                  >
                    <DownloadIcon />
                  </IconButton>
                </TableCell>

                {permissions && (
                  <TableCell scope="row">
                    <DeleteButton resource="sub_resources" id={row.id} />
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  );
};
