import React from "react";
import { useNotify, useDataProvider, Button } from "react-admin";

function CreateButton(props) {
  const { label, history } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  function handleClick() {
    dataProvider
      .create("purchases", { data: {} })
      .then((res) => {
        history.push(`/purchases/${res.data.id}`);
        notify("Purchase Created");
      })
      .catch(() => {
        notify("Error: Purchase not created", "warning");
      });
  }

  return <Button label={label} onClick={handleClick} />;
}

export default CreateButton;
