/* eslint-disable camelcase */
import * as React from "react";
import {
  Edit,
  TextInput,
  NumberInput,
  FormWithRedirect,
  Toolbar,
  FormDataConsumer,
  DeleteButton,
  SaveButton,
  Button,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import { Box, Divider, Card, CardContent, Typography } from "@material-ui/core";

import ResourceCard from "./components/resourceCard";
import Design from "./components/design";
import AssetCard from "./components/assetCard";
import Material from "./components/material";
import Process from "./components/process";
import TextField from "./components/textField";
import FourPoints from "./components/dimensions/4Points";

import TwoPoints from "../job/components/svgs/twoPoints";
import SixPoints from "../job/components/svgs/sixPoints";
import FourPointsView from "../job/components/svgs/fourPoints";
import EightPoints from "../job/components/svgs/eightPoints";

export default (props) => {
  const redirect = () => {
    window.history.back();
  };
  const JobTitle = (props) => {
    return <GetTitle {...props} />;
  };
  return (
    <Edit component="div" title={<JobTitle />} undoable={false} {...props}>
      <SubJobForm redirect={redirect} />
    </Edit>
  );
};

const GetTitle = (props) => {
  const { record } = props;
  let id = "";
  if (record.job_id) id = `J${record.job_id}`;
  if (record.job && record.job.client) {
    if (record.job.client.first_name) id = `${id}-${record.job.client.first_name.toUpperCase()}`;
    if (record.job.client.last_name) id = `${id}-${record.job.client.last_name.toUpperCase()}`;
  }
  id = `${id}-S${record.id}`;
  return <span>{id}</span>;
};

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const SubJobForm = (props) => {
  const classes = useStyles();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <form>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={2} mr={{ xs: 0, sm: "1em" }}>
              <Button label="Back" onClick={formProps.redirect} />
            </Box>
          </Box>

          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={5} mr={{ xs: 0, sm: "1em" }}>
              <Card>
                <CardContent>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Design />

                    <Divider orientation="vertical" flexItem />
                    {/* <DimensionInput source="dimensions" title="Dimensions" /> */}

                    <FormDataConsumer {...formProps}>
                      {({ formData }) => (
                        <FourPoints
                          source="dimensions"
                          title="Dimensions"
                          type={formData.type}
                          formProps={formProps}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                  <Material formProps={formProps} calcMaterial={calcMaterial} />

                  <FormDataConsumer {...formProps}>
                    {({ formData }) =>
                      formData.material_status !== "CLIENT" && (
                        <Box display={{ xs: "block", sm: "flex" }}>
                          <Box flex={1.6} mr={{ xs: 0, sm: "0.5em" }}></Box>
                          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                            <TextInput
                              source="hsn"
                              resource="sub_jobs"
                              fullWidth
                              helperText={false}
                            />
                          </Box>
                          <Box flex={1} />
                          <Box flex={1} mr={{ xs: 0, sm: "1em" }}>
                            <TextField label="Cost x 32ft" value={calcMaterialx32(formData)} />
                          </Box>
                          <Box flex={3} />
                          <Box flex={1}>
                            <Typography variant="h4" align="right" style={{ marginRight: "2em" }}>
                              +
                            </Typography>
                          </Box>
                        </Box>
                      )
                    }
                  </FormDataConsumer>

                  <Process formProps={formProps} calcProcess={calcProcess} />
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={6}></Box>
                    <Box flex={1} mt={{ xs: 0, sm: "0.5em" }}>
                      <Typography variant="h4" align="right" style={{ marginRight: "0.4em" }}>
                        +
                      </Typography>
                    </Box>
                    <Box flex={1.9} ml={{ xs: 0, sm: "0.5em" }}>
                      <NumberInput
                        source="misc_charges"
                        resource="sub_jobs"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={3.5}>
                      <TextInput
                        source="comments"
                        resource="sub_jobs"
                        multiline
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1}></Box>
                    <Box flex={1} mt={{ xs: 0, sm: "0.5em" }}>
                      <Typography variant="h4" align="right" style={{ marginRight: "1em" }}>
                        =
                      </Typography>
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <NumberInput
                        source="quantity"
                        resource="sub_jobs"
                        fullWidth
                        initialValue={1}
                        helperText={false}
                      />
                    </Box>
                    <Box flex={0.3} mt={{ xs: 0, sm: "0.5em" }}>
                      <Typography variant="h2" align="center">
                        *
                      </Typography>
                    </Box>

                    <FormDataConsumer {...formProps}>
                      {({ formData }) => (
                        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                          <TextField label="Sub Total" value={calcRate(formData)} />
                        </Box>
                      )}
                    </FormDataConsumer>
                  </Box>

                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1.9} mr={{ xs: 0, sm: "0.5em" }}>
                      <NumberInput
                        source="pcs"
                        label="Pieces"
                        resource="sub_jobs"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={2.6}></Box>
                    <Box flex={1.5} ml={{ xs: 0, sm: "0.5em" }}>
                      <FormDataConsumer {...formProps}>
                        {({ formData }) => <TextField label="Total" value={calcTotal(formData)} />}
                      </FormDataConsumer>
                    </Box>
                  </Box>
                  <Toolbar {...formProps} classes={classes}>
                    <SaveButton redirect={`/jobs/${formProps.record.job_id}?tab=1`} />
                    <DeleteButton
                      redirect={`/jobs/${formProps.record.job_id}?tab=1`}
                      undoable={false}
                    />
                  </Toolbar>
                </CardContent>
              </Card>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
              <AssetCard record={formProps.record.asset} />
              <FormDataConsumer {...formProps}>
                {({ formData }) => (
                  <>
                    {formData.type === "2_POINTS" && <TwoPoints dimensions={formData.dimensions} />}
                    {formData.type === "4_POINTS" && (
                      <FourPointsView dimensions={formData.dimensions} />
                    )}
                    {formData.type === "6_POINTS" && <SixPoints dimensions={formData.dimensions} />}
                    {formData.type === "8_POINTS" && (
                      <EightPoints dimensions={formData.dimensions} />
                    )}
                  </>
                )}
              </FormDataConsumer>

              <ResourceCard
                records={formProps.record.resource}
                title="RESOURCES"
                id={formProps.record.id}
              />
            </Box>
          </Box>
        </form>
      )}
    />
  );
};

function calcRate(formData) {
  const { misc_charges } = formData;
  return calcMaterial(formData) + calcProcess(formData) + misc_charges;
}

function calcTotal(formData) {
  const { quantity } = formData;
  const rate = calcRate(formData);
  return Math.round(rate * quantity);
}

function calcMaterial(formData) {
  const { material_cost, material_sqft, sqft_req } = formData;
  if (material_sqft) {
    return Math.round(material_cost * material_sqft);
  }
  return Math.round(material_cost * sqft_req);
}

function calcMaterialx32(formData) {
  return (formData.material_cost || 0) * 32;
}

function calcProcess(formData) {
  const {
    cutting,
    engraving,
    chamfering,
    threed,
    fluting,
    coating,
    buffing,
    plating,
    other,
    process_sqft,
    sqft_req,
  } = formData;

  let sqft = sqft_req;

  if (process_sqft) {
    sqft = process_sqft;
  }
  return Math.round(
    (cutting + engraving + chamfering + threed + fluting + coating + buffing + plating + other) *
      sqft
  );
}
