import React from "react";
import ReactQuill from "react-quill";
import { Labeled, useInput } from "react-admin";
import "react-quill/dist/quill.snow.css";

export default function RichInput(props) {
  const { input } = useInput(props);

  const access = localStorage.getItem("access");
  const permissions = access ? access : [];
  let style;
  if (permissions !== "jaali_production") {
    style = { background: "#949494", color: "#fff" };
  }
  return (
    <Labeled label={props.title}>
      <div style={style}>
        <ReactQuill {...input} theme="snow" />
      </div>
    </Labeled>
  );
}
