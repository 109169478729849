import React from "react";
import { Datagrid, TextField, FunctionField, BooleanField } from "react-admin";
import DeleteButton from "../../../../components/deleteButton";

export default (props) => {
  return (
    <Datagrid {...props}>
      <TextField source="remnant_id" />
      <TextField source="remnant.type" label="Type" />
      <TextField source="remnant.width" label="Width" />
      <TextField source="remnant.height" label="Height" />
      <TextField source="remnant.lwidth" label="L Width" />
      <TextField source="remnant.lheight" label="L Height" />
      <TextField source="remnant.thickness" label="Thickness" />
      <BooleanField source="remnant.is_blocked" looseValue label="Blocked" />
      <BooleanField source="remnant.is_used" looseValue label="Used" />
      <FunctionField
        label=" "
        render={(record) =>
          !record.is_used ? <DeleteButton resource="job_remnants" id={record.id} /> : ""
        }
      />
    </Datagrid>
  );
};
