import List from "./list";
import Create from "./create";
import Edit from "./edit";
import Icon from '@material-ui/icons/RateReview';

export default {
  list: List,
  create: Create,
  edit: Edit,
  icon: Icon,
  label: "Testimonials",
  options: { label: "Testimonials" },
  name: "testimonials",
};
