import Icon from "@material-ui/icons/LocalGroceryStore";
import List from "./list";
import Edit from "./edit";
import EditProduction from "./editproduction";
// import Show from "./show";

export default {
  list: List,
  edit: Edit,
  editProduction: EditProduction,
  icon: Icon,
  label: "Jobs",
  options: { label: "Jobs" },
  name: "jobs",
};
