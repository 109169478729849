import Edit from "./edit";

export default {
  edit: Edit,
  label: "Quotations",
  options: { label: "Quotations" },
  name: "job_quotations",
};

export const quotationCreate = {
  edit: Edit,
  name: "quotations",
};
