/* eslint-disable camelcase */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export default ({ dimensions }) => {
  const classes = useStyles();

  const dimensionIN = dimensions.in;
  const on = dimensions.on;

  const { width, height } = dimensionIN || {};
  const { width: wt1, height: ht1 } = dimensions[dimensions.on] || {};
  if (width && height) {
    const viewBox = getViewBox(height, width);
    const offset = 20;
    const textOffset = 10;
    return (
      <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="meet"
      >
        {/* w1 - h1 */}
        <rect width={width} height={height} className={classes.fil1} x={offset} y={offset} />
        <text x={offset + width / 2} y={textOffset} className={classes.tfil1}>
          {wt1}
          {on}
        </text>
        <text
          x={textOffset}
          y={offset + height / 2}
          transform={`rotate(271 ${textOffset} ${offset + height / 2})`}
          className={classes.tfil1}
        >
          {ht1}
          {on}
        </text>
      </svg>
    );
  }
  return (
    <img
      src={require(`../../../../../assets/2_POINTS.png`)}
      alt="img"
      style={{ padding: 10 }}
      width="100%"
    />
  );
};

const getViewBox = (w, h) => {
  return `0 0 ${h + 40} ${w + 40}`;
};

const useStyles = makeStyles(() => ({
  fil1: {
    fill: "none",
    stroke: "#00A0E3",
  },
  tfil1: {
    fill: "#00A0E3",
    fontSize: 5,
  },
}));
