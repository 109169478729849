import React from "react";
import { Chart } from "react-google-charts";

export function ReportChart({ data, columns }) {
  const options = {
    title: "Process Vertical",
    chartArea: { width: "50%" },
    hAxis: {
      title: "Total",
      minValue: 0,
    },
    vAxis: {
      title: "Year",
    },
  };

  return (
    <Chart
      chartType="Bar"
      width="100%"
      height="100%"
      data={[columns, ...data]}
      options={options}
      render={({ renderChart }) => {
        return (
          <div style={{ display: "flex", padding: "10px", minHeight: 400 }}>
            <div style={{ width: "100%" }}>{renderChart()}</div>
          </div>
        );
      }}
    />
  );
}
