import * as React from "react";
import {
  Edit,
  NumberInput,
  SelectInput,
  Toolbar,
  FormWithRedirect,
  required,
  TextInput,
  FormDataConsumer,
  SaveButton,
  BooleanInput,
} from "react-admin";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import VendorReferenceInput from "./vendorButton";

import SubPurchasesTable from "./components/subPurchasesTable";
import PaymentCard from "./components/paymentCard";
import PaymentButton from "../purchasePayment/createButton";
import TextField from "./components/textField";
import { tabs } from "./tabs";

const useStyles = makeStyles((theme) => ({
  fullWidth: { width: "100%" },
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
  block: { display: "block" },
  textField: {
    width: "256px",
    color: "#000",
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  commentCard: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    padding: theme.spacing(2.5),
  },
  paymentDiv: {
    overflowY: "auto",
    height: "250px",
  },
  commentDiv: {
    overflowY: "auto",
    height: "800px",
  },
  title: {
    margin: theme.spacing(2),
  },
  purchase: {
    display: "flex",
    marginBottom: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(3),
  },
  alignCenter: {
    alignSelf: "center",
  },
}));

const PurchaseForm = ({ history, ...props }) => {
  const classes = useStyles();
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <form>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={3} m={0} mr={{ xs: 0, sm: "1em" }}>
              <Card className={classes.card}>
                <CardContent>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={3} className={classes.alignCenter}>
                      <Typography variant="h6" gutterBottom>
                        VENDOR / PURCHASE DETAILS{" "}
                      </Typography>
                      <Typography
                        variant="p"
                        gutterBottom
                        className={classes.purchase}
                      >
                        Created By:{" "}
                        {formProps.record.created_by &&
                          formProps.record.created_by.name}
                        <br />
                        Updated By:{" "}
                        {formProps.record.updated_by &&
                          formProps.record.updated_by.name}
                        <br />
                        Purchase Date: {formProps.record.created_at}
                      </Typography>
                    </Box>
                    <Box flex={1}>
                      <Toolbar {...formProps} classes={classes}>
                        <SaveButton />
                      </Toolbar>
                    </Box>
                  </Box>

                  <Box display={{ xs: "block", sm: "flex" }} m={0}>
                    <Box flex={1}>
                      <NumberInput
                        source="id"
                        resource="purchases"
                        disabled
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={6} ml={{ xs: 0, sm: "0.5em" }}>
                      <VendorReferenceInput
                        source="vendor_id"
                        reference="vendors"
                        perPage={9999}
                        validate={required()}
                        history={history}
                        optionRenderer={optionRenderer}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <SelectInput
                        source="status"
                        resource="purchases"
                        choices={tabs.slice(0, -1)}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1}>
                      <TextInput
                        source="invoice_id"
                        resource="purchases"
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}></Box>
                  <Box>
                    <Typography variant="h6">
                      PAYMENT DETAILS{" "}
                      <PaymentButton
                        id={formProps.record.id}
                        history={formProps.history}
                      />
                    </Typography>
                    <Box display={{ xs: "block", sm: "flex" }}>
                      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                        <Box display={{ xs: "block", sm: "flex" }}>
                          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                            <TextInput
                              source="total"
                              label="Total"
                              defaultValue={0}
                              fullWidth
                              disabled
                              helperText={false}
                            />
                          </Box>
                          <Box flex={1}>
                            <NumberInput
                              source="discount"
                              label="Discount"
                              defaultValue={0}
                              fullWidth
                              helperText={false}
                            />
                          </Box>
                        </Box>
                        <Box display={{ xs: "block", sm: "flex" }}>
                          <BooleanInput
                            source="with_gst"
                            label="W"
                            option={{ size: "small" }}
                            fullWidth
                            helperText={false}
                          />
                        </Box>
                        <Box display={{ xs: "block", sm: "flex" }}>
                          <Box flex={1} mt={{ xs: 0, sm: "0.5em" }}>
                            <FormDataConsumer {...formProps}>
                              {({ formData }) => (
                                <TextField
                                  label="GST@18"
                                  value={gstCalc(formData)}
                                />
                              )}
                            </FormDataConsumer>
                          </Box>
                          <Box flex={1} mt={{ xs: 0, sm: "0.5em" }}>
                            <FormDataConsumer {...formProps}>
                              {({ formData }) => (
                                <TextField
                                  label="Grand Total"
                                  value={totalCalc(formData)}
                                />
                              )}
                            </FormDataConsumer>
                          </Box>
                        </Box>

                        <Box display={{ xs: "block", sm: "flex" }}>
                          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                            <TextInput
                              source="paid"
                              label="Paid"
                              fullWidth
                              disabled
                            />
                          </Box>
                          <Box flex={1} mt={{ xs: 0, sm: "0.5em" }}>
                            <FormDataConsumer {...formProps}>
                              {({ formData }) => (
                                <TextField
                                  label="Balance Due"
                                  value={balanceCalc(formData)}
                                />
                              )}
                            </FormDataConsumer>
                          </Box>
                        </Box>
                      </Box>

                      {formProps.record.purchase_payment && (
                        <Box flex={1} className={classes.paymentDiv}>
                          <PaymentCard
                            records={formProps.record.purchase_payment}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <FormDataConsumer {...formProps}>
                {({ formData }) => (
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <SubPurchasesTable
                      records={formData.sub_purchase || []}
                      purchase_id={formData.id}
                      withGST={formData.with_gst}
                      history={formProps.history}
                    />
                  </Box>
                )}
              </FormDataConsumer>
            </Box>
          </Box>
        </form>
      )}
    />
  );
};

const PurchaseTitle = (props) => <GetPurchaseName {...props} />;

export default (props) => (
  <Edit component="div" title={<PurchaseTitle />} undoable={false} {...props}>
    <PurchaseForm history={props.history} redirect="" margin="none" />
  </Edit>
);

const GetPurchaseName = (props) => {
  const { record } = props;
  let id = "";
  if (record.id) id = `J${record.id}`;
  if (record.vendor) {
    if (record.vendor.first_name)
      id = `${id}-${record.vendor.first_name.toUpperCase()}`;
    if (record.vendor.last_name)
      id = `${id}-${record.vendor.last_name.toUpperCase()}`;
    if (record.vendor.firm_name)
      id = `${id}-${record.vendor.firm_name.toUpperCase()}`;
  }
  return <span>{id}</span>;
};

function gstCalc(data) {
  const { with_gst: withGST } = data;
  const sub = subTotal(data);
  if (!withGST) return 0;
  return (sub * 0.18).toFixed(1);
}

function totalCalc(data) {
  const sub = subTotal(data);
  const gst = parseFloat(gstCalc(data), 10);
  return sub + gst;
}

function balanceCalc(data) {
  const { paid } = data;
  const sub = subTotal(data);
  const gst = parseFloat(gstCalc(data), 10);
  return (sub + gst - paid).toFixed(1);
}

const optionRenderer = (choice) =>
  choice &&
  `${choice.first_name || ""} ${choice.last_name || ""} - ${choice.phone} ${
    choice.firm_name ? choice.firm_name : ""
  }`;

function subTotal(data) {
  const { total, discount } = data;
  return total - discount;
}
