import React, { useState } from "react";
import {
  Button,
  TextInput,
  NumberInput,
  useDataProvider,
  useNotify,
  useRefresh,
  useCreate,
} from "react-admin";
import Add from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import Search from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/icons/Stop";

const useStyles = makeStyles((theme) => ({
  input: {
    width: theme.spacing(12),
    marginRight: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  row: {
    cursor: "pointer",
  },
}));

function RemnantButton({ id, history }) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const dataProvider = useDataProvider();
  const [details, setDetails] = useState({});
  const [remnants, setRemnants] = useState([]);
  const [noResult, setNoResult] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const [create] = useCreate("job_remnants");
  const selectMaterial = async (values) => {
    const params = {
      remnant_id: values.id,
    };
    create(
      { payload: { data: { ...params, job_id: id } } },
      {
        onSuccess: () => {
          setShowDialog(false);
          refresh();
          history.push(`/jobs/${id}`);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const onChangeDetails = (e, name) => {
    setDetails({ ...details, [name]: e.target.value });
  };

  const searchMaterial = () => {
    setNoResult(false);
    dataProvider
      .getList("remnants", {
        pagination: { page: 1, perPage: 10 },
        sort: {},
        filter: { ...details, is_used: 0 },
      })
      .then(({ data }) => {
        if (data.length === 0) {
          setNoResult(true);
        }
        setRemnants(data);
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <Button onClick={handleClick} label="Remnants">
        <Add />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Select Remnant">
        <DialogTitle>Select Remnant</DialogTitle>

        <DialogContent>
          <TextInput
            source="type"
            label="type"
            onChange={(e) => onChangeDetails(e, "type")}
            className={classes.input}
          />
          <NumberInput
            source="thickness"
            label="thickness"
            onChange={(e) => onChangeDetails(e, "thickness")}
            className={classes.input}
          />
          <NumberInput
            source="width"
            label="width"
            onChange={(e) => onChangeDetails(e, "width")}
            className={classes.input}
          />
          <NumberInput
            source="height"
            label="height"
            onChange={(e) => onChangeDetails(e, "height")}
            className={classes.input}
          />
          <NumberInput
            source="lwidth"
            label="lwidth"
            onChange={(e) => onChangeDetails(e, "lwidth")}
            className={classes.input}
          />
          <NumberInput
            source="lheight"
            label="lheight"
            onChange={(e) => onChangeDetails(e, "lheight")}
            className={classes.input}
          />
          <Search onClick={searchMaterial} variant="contained" className={classes.button}>
            <SearchIcon />
          </Search>
          {noResult && <div>No remnants found</div>}
          {remnants.length > 0 && (
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Thickness</TableCell>
                  <TableCell>Width</TableCell>
                  <TableCell>Height</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {remnants.map((r) => (
                  <TableRow
                    className={classes.row}
                    key={r.id}
                    onClick={() => {
                      if (!r.is_blocked) {
                        selectMaterial(r);
                      }
                    }}
                    hover
                  >
                    <TableCell>{r.type}</TableCell>
                    <TableCell>{r.thickness}</TableCell>
                    <TableCell>{r.width}</TableCell>
                    <TableCell>{r.height}</TableCell>
                    <TableCell>
                      <PaymentStatus status={!r.is_blocked} />
                    </TableCell>
                    <TableCell>{r.job_id}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

const PaymentStatus = ({ status }) => {
  console.log(status);
  if (status) {
    return <Icon style={{ color: "#9ccc65" }} />;
  }
  return <Icon style={{ color: "#f44336" }} />;
};
export default RemnantButton;
