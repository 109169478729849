import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customInput: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
}));
export default ({ value, label, fullWidth }) => {
  const classes = useStyles();
  return (
    <TextField
      size="small"
      className={classes.customInput}
      variant="filled"
      label={label}
      value={value}
      disabled
      fullWidth={fullWidth}
    />
  );
};
