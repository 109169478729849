import React from "react";
import {
  ReferenceField,
  TextField,
  NumberField,
  Show,
  DateField,
  SimpleShowLayout,
} from "react-admin";
import SubOrderShow from "../subOrder/show";

export default (props) => (
  <Show {...props} aside={<SubOrderShow {...props} />}>
    <SimpleShowLayout>
      <TextField source="order_status" />
      <ReferenceField source="user_id" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <NumberField
        source="sub_total"
        options={{ style: "currency", currency: "INR" }}
      />
      <NumberField
        source="shipping"
        options={{ style: "currency", currency: "INR" }}
      />
      <NumberField
        source="discount"
        options={{ style: "currency", currency: "INR" }}
      />
      <NumberField
        source="total"
        options={{ style: "currency", currency: "INR" }}
      />
      <TextField source="payment_status" />
      <TextField source="tracking" />
      <DateField source="delivery_date" />
    </SimpleShowLayout>
  </Show>
);
