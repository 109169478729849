import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
const optionRenderer = (choice) => {
  if (!choice.phone) {
    return "";
  }
  return `${choice.first_name} ${choice.last_name} ${choice.phone}`;
};
const VendorFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="vendor_id"
      reference="vendors"
      perPage={10}
      fullWidth
      alwaysOn
    >
      <AutocompleteInput optionText={optionRenderer} resettable />
    </ReferenceInput>
  </Filter>
);

export default (props) => (
  <List {...props} filters={<VendorFilter />}>
    <Datagrid rowClick="edit">
      <FunctionField
        source="first_name"
        label="Name"
        render={({ first_name, last_name }) =>
          `${first_name} ${last_name || ""}`
        }
      />

      <TextField source="firm_name" />
      <TextField source="phone" />
      <TextField source="email" />
      <EditButton />
    </Datagrid>
  </List>
);
