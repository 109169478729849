import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Button,
  ReferenceArrayInput,
  SelectArrayInput,
  FileInput,
  FileField,
  FormDataConsumer,
} from "react-admin";
import CategoryReferenceInput from "../../../components/createButton";
import RichTextInput from "../../../components/richInputText";
import { withStyles } from "@material-ui/core";
import uploadProps, { uploadMedia } from "../../../dataprovider/uploadProps";
import UploadProgress from "../../../components/uploadProgress";

const styles = {
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
  inlineImage: { display: "block", marginRight: "1rem" },
};

const redirect = (basePath, id, data) => `/assets/${data.parent_id}/1`;

export default withStyles(styles)(({ classes, history, ...props }) => {
  const [progress, setProgress] = useState();
  const transform = async (data) => {
    const obj = data;
    if (data.image instanceof Object) {
      const resp = await uploadMedia(data.image, "Assets");
      obj.imagev2 = resp.data;
    }
    return await uploadProps(data, setProgress, "ASSETS");
  };
  return (
    <>
      <Edit
        {...props}
        undoable={false}
        transform={transform}
        actions={<Button label="Back" onClick={() => history.goBack()} />}
      >
        <SimpleForm redirect={redirect}>
          <TextInput source="id" />
          <TextInput source="name" />
          <CategoryReferenceInput
            source="category_id"
            reference="asset_categories"
            perPage={9999}
            field="name"
          />
          <ReferenceArrayInput perPage={99999} source="tags" reference="jaali_tags" label="Tags">
            <SelectArrayInput optionText="id" />
          </ReferenceArrayInput>
          <FileInput source="image" accept="image/*" placeholder={<p>Drop your file here</p>}>
            <FileField source="src" title="title" />
          </FileInput>
          <FormDataConsumer>
            {({ formData }) =>
              formData.image &&
              typeof formData.image === "string" && (
                <img
                  src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${formData.image}`}
                  alt={formData.image}
                  style={{ position: "relative" }}
                />
              )
            }
          </FormDataConsumer>
          <FileInput source="file" placeholder={<p>Drop your file here</p>}>
            <FileField source="src" title="title" />
          </FileInput>
          <FormDataConsumer>
            {({ formData }) =>
              formData.file &&
              typeof formData.file === "string" && (
                <img
                  src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${formData.file}`}
                  alt={formData.file}
                  style={{ position: "relative" }}
                />
              )
            }
          </FormDataConsumer>
          <RichTextInput source="description" title="Description" />
        </SimpleForm>
      </Edit>
      <UploadProgress progress={progress} />
    </>
  );
});
