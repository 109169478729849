import React from "react";
import MuiGridList from "@material-ui/core/GridList";

import { Datagrid } from "react-admin";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import GridListForm from "./gridListForm";

const useStyles = makeStyles((theme) => ({
  gridList: {
    margin: 0,
  },
  tileBar: {
    background: "",
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: "100%",
  },
  price: {
    display: "inline",
    fontSize: "1em",
  },
  link: {
    color: "#fff",
  },
  tile: {
    padding: theme.spacing(2),
    maxHeight: "350px",
  },
}));

export default (props) => <Datagrid {...props} body={<MyDatagridBody />} />;

const MyDatagridBody = ({ ids, data }) => {
  const classes = useStyles();
  if (!ids || !data) return null;
  console.log(ids);
  const idsToIterate = ids.sort();
  return (
    <MuiGridList cellHeight={390} cols={4} className={classes.gridList}>
      {idsToIterate.map((id) => (
        <div style={{ width: "300px" }}>
          <div
            style={{
              height: "300px",
              width: "300px",
            }}
          >
            <a href={`#/assets/${data[id].id}`}>
              <img
                src={data[id].imagev2}
                alt={data[id].name}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              />
            </a>
          </div>
          <div
            style={{
              height: "50px",
              width: "300px",
            }}
          >
            <Typography variant="body2" gutterBottom style={{ color: "white" }}>
              {data[id].name}
            </Typography>
            <Typography variant="body1" gutterBottom style={{ color: "white" }}>
              {data[id].id}
            </Typography>

            <br />
          </div>
          <GridListForm data={data[id]} />
        </div>
      ))}
    </MuiGridList>
  );
};

// export default (props) => (
//   <Datagrid {...props}>
//     <EditButton />
//     <FunctionField
//       label="Image"
//       render={(record) =>
//         record.imagev2 && (
//           <img src={record.imagev2} alt={record.supc} style={{ maxWidth: "250px" }} />
//         )
//       }
//     />
//     <TextField source="id" />
//     <BooleanField
//       source="display_on_app"
//       label="Display on app"
//       TrueIcon={TrueIcon}
//       FalseIcon={FalseIcon}
//     />
//     <BooleanField source="is_premium" label="Premium" TrueIcon={TrueIcon} FalseIcon={FalseIcon} />
//     <FunctionField
//       label=" "
//       render={(record) => <DeleteButton resource="asset_variants" id={record.id} />}
//     />
//   </Datagrid>
// );
