import React, { useState } from "react";
import clsx from "clsx";
import { useDataProvider, useNotify, useRefresh } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import Box from "@material-ui/core/Box";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import red from "@material-ui/core/colors/red";
import SubPurchaseButton from "../../subPurchase/createButton";

export default (props) => {
  const classes = useStyles();
  const [details, setDetails] = useState({});
  const { numSelected, selected } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    dataProvider
      .updateMany("sub_purchases", { ids: selected, data: details })
      .then(() => {
        refresh();
        notify("Elements Updated");
      })
      .catch(() => {
        notify("Error: Elements not updated", "warning");
      });
  };

  const statusOptions = [
    { id: "NA", name: "NA" },
    { id: "TO_BE_PROCESSED", name: "TO_BE_PROCESSED" },
    { id: "TOOL_PATH_CREATED", name: "TOOL_PATH_CREATED" },
    { id: "PROCESSED", name: "PROCESSED" },
    { id: "DISPATCHED", name: "DISPATCHED" },
    { id: "DELIVERED", name: "DELIVERED" },
  ];
  return (
    <Toolbar
      className={clsx(classes.toolbarRoot, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Box display={{ xs: "block", sm: "flex" }} justifyContent="flex-end">
          <Box flex={1} ml={{ xs: 0, sm: "2.5em" }}>
            <FormControl className={classes.formControl}>
              <InputLabel>Purchase</InputLabel>
              <Select
                inputProps={{
                  name: "status",
                  id: "status",
                }}
                value={details.status || ""}
                onChange={handleChange}
                className={classes.input}
              >
                {statusOptions.map((s) => (
                  <MenuItem value={s.id} key={s.id}>
                    {s.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box flex={1} ml={{ xs: 0, sm: "2.5em" }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Box>
        </Box>
      ) : (
        <Box display={{ xs: "block", sm: "flex" }} className={classes.w_100}>
          <Box flex={1}>
            <Typography className={classes.title} variant="h6" id="tableTitle">
              SUB PURCHASES{" "}
              <SubPurchaseButton
                onChange={() => refresh()}
                purchaseId={props.purchase_id}
              />
            </Typography>
          </Box>
        </Box>
      )}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  w_100: {
    width: "100%",
  },
  highlight: {
    color: "#1a237e",
    backgroundColor: "#1a237e",
  },
  imageBox: {
    maxWidth: "100px",
    maxHeight: "100px",
  },
  textMuted: {
    color: "#fff",
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  button: {
    margin: theme.spacing(2),
  },
  cssRoot: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));
