import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  required,
  TextField,
  TextInput,
  FileInput,
  ImageField,
  FormDataConsumer,
  Toolbar,
} from "react-admin";
import uploadProps from "../../../dataprovider/uploadProps";
import UploadProgress from "../../../components/uploadProgress";
import { SaveButtonAware } from "../../../components/toolbar";

export default (props) => {
  const [progress, setProgress] = useState();
  const transform = async (data) =>
    await uploadProps(data, setProgress, "ADVERTISEMENTS");
  return (
    <>
      <Edit {...props} undoable={false} transform={transform}>
        <SimpleForm
          redirect={`/advertisements`}
          toolbar={<EditToolbar disabled={!!progress} />}
        >
          <TextField source="name" label="Name" />
          <FileInput
            source="image"
            accept="image/*"
            placeholder={<p>Drop your file here</p>}
            validate={required()}
          >
            <ImageField source="src" title="title" />
          </FileInput>
          <FormDataConsumer>
            {({ formData }) =>
              formData.image &&
              typeof formData.image === "string" && (
                <img
                  src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${formData.image}`}
                  alt={formData.image}
                  style={{ position: "relative" }}
                />
              )
            }
          </FormDataConsumer>
          <TextInput source="link" label="Link" validate={required()} />
        </SimpleForm>
      </Edit>
      <UploadProgress progress={progress} />
    </>
  );
};

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButtonAware undoable={false} disabled={props.disabled} />
  </Toolbar>
);
