import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useUpdate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";

const styles = () => ({
  colorSwitchBase: {
    color: red[500],
    "&$colorChecked": {
      color: green[500],
      "& + $colorBar": {
        backgroundColor: green[500],
      },
    },
  },
  colorBar: {
    color: red[500],
  },
  colorChecked: {
    color: green[500],
  },
});
const GridListForm = ({ data, classes }) => {
  const [update] = useUpdate();

  const [app, setApp] = useState(data.display_on_app);
  const [premium, setPremium] = useState(data.is_premium);
  const updateFunc = (id, diff, prevData = {}) => {
    update("assets", id, diff, prevData);
  };
  return (
    <div
      style={{
        height: "30px",
        width: "300px",
        display: "flex",
      }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={app}
            onChange={() => {
              updateFunc(data.id, { display_on_app: !app, is_premium: premium }, { ...data });
              setApp(!app);
            }}
            value="checkedB"
            classes={{
              switchBase: classes.colorSwitchBase,
              checked: classes.colorChecked,
              bar: classes.colorBar,
            }}
            color="primary"
          />
        }
        label={<span style={{ fontSize: 20 }}>App</span>}
        style={{ color: "white" }}
      />
      <FormControlLabel
        control={
          <Switch
            checked={premium}
            onChange={() => {
              updateFunc(data.id, { is_premium: !premium, display_on_app: app }, { ...data });
              setPremium(!premium);
            }}
            value="checkedB"
            classes={{
              switchBase: classes.colorSwitchBase,
              checked: classes.colorChecked,
              bar: classes.colorBar,
            }}
            color="primary"
          />
        }
        label={<span style={{ fontSize: 20 }}>Premium</span>}
        style={{ color: "white" }}
      />
    </div>
  );
};

export default withStyles(styles)(GridListForm);
