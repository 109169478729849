import React from 'react';
import { TextField, Edit, TextInput, SimpleForm, required } from 'react-admin';

export default props => (
	<Edit {...props} undoable={false}>
		<SimpleForm>
			<TextField source="name" />
			<TextField source="email" />
			<TextField source="phone" />
			<TextField source="address" />
			<TextField source="city" />
			<TextField source="state" />
			<TextField source="country" />
			<TextField source="zipcode" />
			<TextInput source="is_active" validate={required()} />
		</SimpleForm>
	</Edit>
);
