import React from "react";
import {
  NumberInput,
  Edit,
  SelectInput,
  TextInput,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  DateInput,
  required,
  FormDataConsumer,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import UserReferenceInput from "./userCreate";
import SubOrderList from "../subOrder/list";
import SubOrderCreateButton from "../subOrder/createButton";
import TextField from "./textField";

const useStyles = makeStyles((theme) => ({
  fullWidth: { width: "100%" },
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
  block: { display: "block" },
  textField: {
    width: "256px",
    color: "#000",
  },
}));

function OrderEdit({ ...props }) {
  const classes = useStyles();
  return (
    <Edit {...props} undoable={false}>
      <TabbedForm>
        <FormTab label="Details">
          <ReferenceManyField
            className={classes.fullWidth}
            label="Items"
            reference="sub_orders"
            target="order_id"
          >
            <SubOrderList />
          </ReferenceManyField>
          <FormDataConsumer>
            {({ formData }) => <SubOrderCreateButton data={formData} />}
          </FormDataConsumer>
          <UserReferenceInput
            source="user_id"
            reference="users"
            perPage={9999}
            validate={required()}
          />
          <TextInput source="billing_name" validate={required()} />

          <TextInput disabled source="sub_total" label="SubTotal (Rs)" />
          <NumberInput source="discount" label="Discount (Rs)" />
          <NumberInput source="shipping" label="Shipping (Rs)" />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <TextField
                label="Total (Rs)"
                value={
                  formData.sub_total - formData.discount + formData.shipping
                }
              />
            )}
          </FormDataConsumer>
          <NumberInput source="paid" label="Paid (Rs)" />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <TextField
                label="Balance Due (Rs)"
                value={
                  formData.sub_total -
                  formData.discount +
                  formData.shipping -
                  formData.paid
                }
              />
            )}
          </FormDataConsumer>
          <SelectInput
            source="order_status"
            choices={[
              { id: "PREPARING", name: "PREPARING" },
              { id: "ON_HOLD", name: "ON_HOLD" },
              { id: "READY_FOR_DISPATCH", name: "READY_FOR_DISPATCH" },
              { id: "DISPATCHED", name: "DISPATCHED" },
              { id: "DELIVERED", name: "DELIVERED" },
            ]}
            validate={required()}
          />
          <TextInput source="tracking" label="Tracking Id" />
          <DateInput source="delivery_date" label="Delivery Date" />
        </FormTab>
        <FormTab label="Billing">
          <TextInput source="billing_address" />
          <TextInput source="billing_city" />
          <TextInput source="billing_state" />
          <NumberInput source="billing_zipcode" />
          <TextInput source="billing_country" />
          <TextInput source="billing_tel" />
        </FormTab>
        <FormTab label="Shipping">
          <TextInput source="shipping_name" />
          <TextInput source="shipping_address" />
          <TextInput source="shipping_city" />
          <TextInput source="shipping_state" />
          <NumberInput source="shipping_zipcode" />
          <TextInput source="shipping_country" />
          <TextInput source="shipping_tel" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

export default OrderEdit;
