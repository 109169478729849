export default (
  previousState = { visible: false, value: 0 },
  { type, payload }
) => {
  if (type === "UPDATE_STATUS") {
    return { visible: true, value: payload.value };
  }
  if (type === "UPDATE_STATUS_SUCCESS") {
    return { visible: false, value: 0 };
  }
  return previousState;
};
