import React from "react";
import {
  Edit,
  SimpleForm,
  NumberInput,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
  required,
  Button,
} from "react-admin";
import { withStyles } from "@material-ui/core";
import RichTextInput from "../../../components/richInputText";

const styles = {
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
  inlineImage: { display: "block", marginRight: "1rem" },
  inputFont: { width: "250px" },
};

const redirect = (basePath, id, data) => `/products/${data.product_id}/4`;

export default withStyles(styles)(({ classes, history, ...props }) => (
  <Edit
    {...props}
    undoable={false}
    actions={<Button label="Back" onClick={() => history.goBack()} />}
  >
    <SimpleForm redirect={redirect}>
      <SelectInput
        source="type"
        choices={[
          { id: "TEXT", name: "TEXT" },
          { id: "IMAGE", name: "IMAGE" },
          { id: "SELECT", name: "SELECT" },
          { id: "COLOR", name: "COLOR" },
        ]}
        formClassName={classes.inlineBlock}
        validate={required()}
      />
      <TextInput source="title" formClassName={classes.inlineBlock} />
      <RichTextInput source="sub_text" title="Sub Text" />
      <FormDataConsumer formClassName={classes.inlineBlock}>
        {({ formData, ...rest }) =>
          formData.type === "TEXT" && (
            <NumberInput
              source="max_count"
              label="Max Characters"
              formClassName={classes.inlineBlock}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer formClassName={classes.inlineBlock}>
        {({ formData, ...rest }) =>
          formData.type === "SELECT" && (
            <ReferenceArrayInput
              source="font"
              reference="fonts"
              label="Font"
              perPage={99999}
            >
              <SelectArrayInput optionText="id" />
            </ReferenceArrayInput>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer formClassName={classes.inlineBlock}>
        {({ formData, ...rest }) =>
          formData.type === "COLOR" && (
            <ReferenceArrayInput
              source="color"
              reference="colors"
              label="Color"
              perPage={99999}
            >
              <SelectArrayInput optionText="id" />
            </ReferenceArrayInput>
          )
        }
      </FormDataConsumer>
      <NumberInput source="priority" label="Priority" validate={required()} />
      <BooleanInput source="is_optional" label="Optional" />
    </SimpleForm>
  </Edit>
));
