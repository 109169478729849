import React from "react";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles(() => ({
  card: {
    marginBottom: "5px",
  },
}));

export default ({ records }) => {
  const classes = useStyles();
  return records.map((payment) => (
    <Card className={classes.card} key={payment.id}>
      <CardContent>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={2}>
            <Typography variant="p" component="p">
              {moment(payment.created_at).format("lll")}
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="p" component="p">
              {payment.created_by.name}
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="p" component="p">
              {payment.mode}
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="p" component="p">
              {`₹${payment.amount}`}
            </Typography>
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Typography variant="p" component="p">
            {payment.comment}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  ));
};
