import List from "./list";
import Edit from "./edit";
import Create from "./create";
import Icon from "@material-ui/icons/AccountBox";

export default {
  list: List,
  edit: Edit,
  create: Create,
  icon: Icon,
  label: "Clients",
  options: { label: "Clients" },
  name: "clients",
};
