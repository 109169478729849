import React from "react";
import { Edit, TextInput, NumberInput, SimpleForm } from "react-admin";

export default (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect="list">
      <TextInput
        source="type"
        format={(v) => (v ? v.toUpperCase() : "")}
        parse={(v) => (v ? v.toUpperCase() : "")}
      />
      <TextInput
        source="brand"
        format={(v) => (v ? v.toUpperCase() : "")}
        parse={(v) => (v ? v.toUpperCase() : "")}
      />
      <TextInput
        source="hsn"
        format={(v) => (v ? v.toUpperCase() : "")}
        parse={(v) => (v ? v.toUpperCase() : "")}
      />
      <TextInput
        source="color"
        format={(v) => (v ? v.toUpperCase() : "")}
        parse={(v) => (v ? v.toUpperCase() : "")}
      />
      <NumberInput source="thickness" />
      <NumberInput source="price" label="Price" />
    </SimpleForm>
  </Edit>
);
