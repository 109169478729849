import React from "react";
import { useNotify, useDataProvider, useRefresh } from "react-admin";
import { Button } from "@material-ui/core";
import Add from "@material-ui/icons/Add";

function FieldButton(props) {
  const { data, history } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  function handleClick() {
    dataProvider
      .getOne("create_field", { id: data.id })
      .then(() => {
        history.replace(`/products/${data.id}/4`);
        refresh();
      })
      .catch(() => {
        notify("Error: Variant not created", "warning");
      });
  }

  return (
    <Button color="primary" onClick={handleClick} size="small">
      <Add /> Field
    </Button>
  );
}

export default FieldButton;
