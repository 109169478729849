import React from "react";
import { Create, TextInput, SimpleForm, NumberInput } from "react-admin";

export default ({ history, ...props }) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput
        source="type"
        format={(v) => (v ? v.toUpperCase() : "")}
        parse={(v) => (v ? v.toUpperCase() : "")}
      />
      <TextInput
        source="brand"
        format={(v) => (v ? v.toUpperCase() : "")}
        parse={(v) => (v ? v.toUpperCase() : "")}
      />
      <TextInput
        source="hsn"
        format={(v) => (v ? v.toUpperCase() : "")}
        parse={(v) => (v ? v.toUpperCase() : "")}
      />
      <TextInput
        source="color"
        format={(v) => (v ? v.toUpperCase() : "")}
        parse={(v) => (v ? v.toUpperCase() : "")}
      />
      <NumberInput source="thickness" />
      <NumberInput source="price" label="Price" />
    </SimpleForm>
  </Create>
);
