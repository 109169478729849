import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  FunctionField,
  Pagination,
  downloadCSV,
  NumberInput,
  Filter,
  Button,
} from "react-admin";
import CancelInvoice from "./cancelInvoice";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import Print from "@material-ui/icons/Print";

import jsonExport from "jsonexport/dist";

const PostPagination = (props) => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;
const JobFilter = ({ ...props }) => (
  <Filter {...props}>
    <NumberInput source="job_id" label="Job Id" defaultValue="" alwaysOn />
  </Filter>
);

const InvoiceBulkActionButtons = (props) => {
  return <CancelInvoice {...props} />;
};

const exporter = (invoices) => {
  const invoicesForExport = invoices.map((i) => {
    const { sub_jobs, job, ...iForEx } = i;
    iForEx.billTo =
      job && job.client ? `${job.client.first_name} ${job.client.last_name || ""}` : ""; // add a field
    return iForEx;
  });
  jsonExport(
    invoicesForExport,
    {
      headers: [
        "invoice_id",
        "job_id",
        "billTo",
        "total",
        "shipping",
        "pack",
        "discount",
        "gst",
        "grand_total",
        "created_at",
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "invoices"); // download as 'posts.csv` file
    }
  );
};

const postRowStyle = (record) => {
  const color = record.status === "CANCELLED" && "#b71c1c";
  return {
    backgroundColor: color || "",
  };
};

const InvoicePanel = ({ record }) => (
  <Box>
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Img</TableCell>
          <TableCell>Qty</TableCell>
          <TableCell>Rate</TableCell>
          <TableCell>Misc Charges</TableCell>
          <TableCell>Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.sub_jobs.map((s) => (
          <TableRow key={s.id}>
            <TableCell component="th" scope="row">
              {s.id}
            </TableCell>
            <TableCell component="th" scope="row">
              {`${s.material_thickness}mm ${s.material_type} ${s.processes || ""} ${s.size || ""} ${
                s.design || ""
              } ${s.material_status === "OURS" ? "With Material" : "Without Material"}  ${
                s.comment || ""
              }`}
            </TableCell>
            <TableCell component="th" scope="row">
              {s.image ? (
                <img
                  src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${s.image}`}
                  alt={record.supc}
                />
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell component="th" scope="row">
              {s.quantity.toLocaleString("en-US")}
            </TableCell>
            <TableCell component="th" scope="row">
              {`₹${s.rate.toLocaleString("en-US")}`}
            </TableCell>
            <TableCell component="th" scope="row">
              {`₹${(s.quantity * s.rate).toLocaleString("en-US")}`}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>
);

export default (props) => (
  <List
    {...props}
    pagination={<PostPagination />}
    exporter={exporter}
    bulkActionButtons={<InvoiceBulkActionButtons />}
    filters={<JobFilter />}
  >
    <Datagrid rowClick="" rowStyle={postRowStyle} expand={<InvoicePanel />}>
      <TextField source="invoice_id" />
      <TextField source="job_id" />
      <FunctionField
        label="Name"
        render={(record) =>
          record.job &&
          record.job.client &&
          `${record.job.client.first_name} ${record.job.client.last_name || ""}`
        }
      />
      <NumberField source="total" options={{ style: "currency", currency: "INR" }} />
      <NumberField source="shipping" options={{ style: "currency", currency: "INR" }} />
      <NumberField
        source="pack"
        label="Packaging"
        options={{ style: "currency", currency: "INR" }}
      />
      <NumberField
        source="misc"
        label="Miscellaneous"
        options={{ style: "currency", currency: "INR" }}
      />
      <NumberField source="discount" options={{ style: "currency", currency: "INR" }} />
      <NumberField source="gst" label="GST@18" options={{ style: "currency", currency: "INR" }} />
      <NumberField source="grand_total" options={{ style: "currency", currency: "INR" }} />
      <TextField source="created_at" label="Date" />
      <FunctionField
        render={(record) => (
          <Button onClick={() => props.history.push(`/edit_job_invoices/${record.id}`)}>
            <EditIcon />
          </Button>
        )}
      />
      <FunctionField
        render={(record) => (
          <Button
            onClick={() => {
              window.open(`#/print-invoice/?id=${record.id}`, "_blank");
            }}
          >
            <Print />
          </Button>
        )}
      />
    </Datagrid>
  </List>
);
