import React from "react";
import { TextInput, Edit, SelectInput, SimpleForm } from "react-admin";
import menu from "./menu";

export default (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="mobile" />
      <TextInput source="change_password" />
      <SelectInput label="Access" source="access" choices={menu} />
    </SimpleForm>
  </Edit>
);
