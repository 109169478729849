/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { apiUrl } from "../constants";
import httpClient from "./httpClient";

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      sort: field,
      order,
      perPage,
      page,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json: { data, meta, links } }) => {
      if (["orders", "jobs", "sub_jobs"].includes(resource)) {
        const ord = new URLSearchParams(links.first.substring(links.first.indexOf("?") + 1));
        localStorage.setItem(`${resource}_count`, JSON.stringify(Object.fromEntries(ord)));
      }
      return {
        data,
        total: meta.total,
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json: { data } }) => ({ data })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json: { data } }) => ({ data }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      sort: field,
      order,
      perPage,
      page,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json: { data, meta } }) => ({
      data,
      total: meta.total,
    }));
  },

  update: async (resource, params) => {
    if (["job_invoices", "edit_job_invoices"].includes(resource)) {
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "PUT",
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        const linkSource = `data:application/pdf;base64,${json.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `${json.name}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        return { data: { id: 1 } };
      });
    }
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json: { data } }) => ({ data: { id: data } }));
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then((response) => ({ data: [response.data] }));
  },

  create: async (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json: { data } }) => ({
      data: { ...params.data, id: data.id },
    }));
  },

  createMany: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/bulk-upload`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json: { data } }) => ({ data })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json: { data } }) => ({ data })),

  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
          body: JSON.stringify(params.data),
        })
      )
    ).then(() => ({ data: "" })),

  deleteImage: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/delete-image?${stringify(params)}`).then(() => ({
      data: "",
    })),
  pdfDownload: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}/${params.name}?${stringify(query)}`).then(
      (response) => {
        const linkSource = `data:application/pdf;base64,${response.body}`;
        const downloadLink = document.createElement("a");
        const fileName = `${params.name}-${Date.now()}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        return { data: [] };
      }
    );
  },
};
