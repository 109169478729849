import React, { useState } from "react";
import { Button, TextInput, NumberInput, useDataProvider } from "react-admin";
import Add from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import Search from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    width: theme.spacing(12),
    marginRight: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  row: {
    cursor: "pointer",
  },
}));

function RemnantButton({ change }) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const dataProvider = useDataProvider();
  const [details, setDetails] = useState({});
  const [materials, setMaterials] = useState([]);
  const [noResult, setNoResult] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    change("m_type", undefined);
    change("m_thickness", undefined);
    setShowDialog(false);
  };

  const onChangeDetails = (e, name) => {
    setDetails({ ...details, [name]: e.target.value });
  };

  const searchMaterial = () => {
    setNoResult(false);
    dataProvider
      .getList("materials", {
        pagination: { page: 1, perPage: 10 },
        sort: {},
        filter: details,
      })
      .then(({ data }) => {
        if (data.length === 0) {
          setNoResult(true);
        }
        setMaterials(data);
      })
      .catch((e) => console.log(e));
  };

  const selectMaterial = (value) => {
    change("material_type", value.type);
    change("material_thickness", value.thickness);
    change("material_cost", value.price);
    change("hsn", value.hsn);

    handleCloseClick();
  };

  return (
    <>
      <Button onClick={handleClick} label="Materials">
        <Add />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Select Materials"
      >
        <DialogTitle>Select Materials</DialogTitle>

        <DialogContent>
          <TextInput
            source="m_type"
            label="type"
            onChange={(e) => onChangeDetails(e, "type")}
            className={classes.input}
          />
          <NumberInput
            source="m_thickness"
            label="thickness"
            onChange={(e) => onChangeDetails(e, "thickness")}
            className={classes.input}
          />
          <Search
            onClick={searchMaterial}
            variant="contained"
            className={classes.button}
          >
            <SearchIcon />
          </Search>
          {noResult && <div>No Materials found</div>}
          {materials.length > 0 && (
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Thickness</TableCell>
                  <TableCell>Color</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {materials.map((r) => (
                  <TableRow
                    className={classes.row}
                    key={r.id}
                    onClick={() => selectMaterial(r)}
                    hover
                  >
                    <TableCell>{r.type}</TableCell>
                    <TableCell>{r.thickness}</TableCell>
                    <TableCell>{r.color}</TableCell>
                    <TableCell>{r.brand}</TableCell>
                    <TableCell>Rs{r.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default RemnantButton;
