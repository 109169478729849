import React, { useState } from "react";
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  useRefresh,
  FormWithRedirect,
  BooleanInput,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import uploadProps from "../../../dataprovider/uploadProps";
import UploadProgress from "../../../components/uploadProgress";
import ImageInput from "../../../components/imageInput";

function ResourceQuickCreateButton({ id }) {
  const [progress, setProgress] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("sub_resources");
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    const { title } = values.resource;
    const data = await uploadProps(values, setProgress, "RESOURCES");
    data.name = title;
    data.sub_job_id = id;
    data.is_default = values.is_default;
    create(
      { payload: { data } },
      {
        onSuccess: () => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="Upload">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Resource"
      >
        <DialogTitle>Add Resource</DialogTitle>

        <FormWithRedirect
          resource="sub_resources"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <ImageInput source="resource" label="Resource" />
                <BooleanInput source="is_default" />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
      <UploadProgress progress={progress} />
    </>
  );
}

export default ResourceQuickCreateButton;
