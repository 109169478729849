import * as React from "react";
import {
  DateInput,
  Edit,
  NumberInput,
  SelectInput,
  Toolbar,
  FormWithRedirect,
  required,
  TextInput,
  FormDataConsumer,
  SaveButton,
  Button,
  BooleanInput,
  ReferenceManyField,
  useRefresh,
} from "react-admin";

import { IconButton } from "@material-ui/core";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { Box, Card, CardContent, Typography, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Viewer from "react-viewer";

import ClientReferenceInput from "./clientButton";
import CoverButton from "./coverButton";
import SubJobsTable from "./components/subJobsTable";
import CommentCard from "./components/commentCard";
import PaymentCard from "./components/paymentCard";
import ResourceCard from "./components/resourceCard";
import CommentButton from "../comment/createButton";
import PaymentButton from "../jobPayment/createButton";
import TransportButton from "../jobTransport/createButton";
import TextField from "./components/textField";
import DeleteImageButton from "../../../components/deleteImageButton";
import QuotationList from "./components/quotationList";
import { tabs } from "./tabs";
import InvoiceList from "./components/invoiceList";
import RemnantCard from "./components/remnantCard";
import RemnantButton from "../jobRemnant/createButton";
import RemnantList from "./components/remnantList";
import ChallanList from "./components/challanList";

const useStyles = makeStyles((theme) => ({
  block: { display: "block" },
  textField: {
    width: "256px",
    color: "#000",
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  commentCard: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    padding: theme.spacing(2.5),
  },
  paymentDiv: {
    overflowY: "auto",
    height: "250px",
  },
  title: {
    margin: theme.spacing(2),
  },
  job: {
    display: "flex",
    marginBottom: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(3),
  },
  alignCenter: {
    alignSelf: "center",
  },
}));

function a11yProps(index) {
  return {
    id: index,
    "aria-controls": index,
  };
}

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={index} aria-labelledby={index}>
      {value === index && children}
    </div>
  );
}

const JobsForm = ({ history, location, showTotal, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab");
  const [value, setValue] = React.useState(parseInt(tab, 10) || 0);

  const refresh = useRefresh();
  const handleChange = (event, newValue) => {
    history.push(`${location.pathname}?tab=${newValue}`);
    setValue(newValue);
    refresh();
  };
  const toggle = (val) => {
    setOpen(val);
  };
  const classes = useStyles();
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        return (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Jobs" {...a11yProps(0)} />
                <Tab label="SubJobs" {...a11yProps(1)} />
                <Tab label="Quotations" {...a11yProps(2)} />
                <Tab label="Invoices" {...a11yProps(3)} />
                <Tab label="Challans" {...a11yProps(4)} />
                <Tab label="Remnants" {...a11yProps(5)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <form>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={3} m={0} mr={{ xs: 0, sm: "1em" }}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Box display={{ xs: "block", sm: "flex" }}>
                          <Box flex={5} className={classes.alignCenter}>
                            <Typography variant="h6" gutterBottom>
                              CLIENT / JOB DETAILS{" "}
                              <CoverButton id={formProps.record.id} data={formProps.record} />
                              <RemnantButton id={formProps.record.id} history={formProps.history} />
                            </Typography>
                            <Typography variant="p" gutterBottom className={classes.job}>
                              Created By:{" "}
                              {formProps.record.created_by && formProps.record.created_by.name}
                              <br />
                              Updated By:{" "}
                              {formProps.record.updated_by && formProps.record.updated_by.name}
                              <br />
                              Order Date: {formProps.record.order_date}
                            </Typography>
                          </Box>
                          <Box flex={2}>
                            <Toolbar {...formProps} classes={classes}>
                              <SaveButton />
                            </Toolbar>
                          </Box>
                        </Box>

                        <Box display={{ xs: "block", sm: "flex" }} m={0}>
                          <Box flex={1}>
                            <NumberInput
                              source="id"
                              resource="jobs"
                              disabled
                              fullWidth
                              helperText={false}
                            />
                          </Box>
                          <Box flex={6} ml={{ xs: 0, sm: "0.5em" }}>
                            <ClientReferenceInput
                              source="client_id"
                              reference="clients"
                              perPage={9999}
                              validate={required()}
                              history={history}
                              optionRenderer={optionRenderer}
                              client={formProps.record.client}
                              fullWidth
                            />
                          </Box>
                        </Box>
                        <Box display={{ xs: "block", sm: "flex" }}>
                          <Box flex={1}>
                            <SelectInput
                              source="status"
                              resource="jobs"
                              choices={tabs.slice(0, -1)}
                              fullWidth
                            />
                          </Box>

                          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                            <DateInput source="delivery_date" resource="jobs" fullWidth />
                          </Box>
                          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                            <TextInput source="remnant_id" resource="jobs" fullWidth />
                          </Box>
                        </Box>
                        <Box display={{ xs: "block", sm: "flex" }}>
                          <Box flex={1}>
                            <TextInput source="site_name" resource="jobs" fullWidth />
                          </Box>
                          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                            <TextInput source="area" resource="jobs" fullWidth />
                          </Box>
                          <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
                            <ClientReferenceInput
                              source="contact_id"
                              label="Contact Person"
                              reference="clients"
                              perPage={9999}
                              history={history}
                              optionRenderer={optionRenderer}
                              fullWidth
                            />
                          </Box>
                        </Box>
                        <Box>
                          <Typography variant="h6">
                            PAYMENT DETAILS{" "}
                            <PaymentButton id={formProps.record.id} history={formProps.history} />
                            <TransportButton
                              id={formProps.record.id}
                              history={formProps.history}
                              transports={formProps.record.job_transport}
                            />
                          </Typography>
                          <Box display={{ xs: "block", sm: "flex" }}>
                            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                              <Box display={{ xs: "block", sm: "flex" }}>
                                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                                  <TextInput
                                    source="total"
                                    label="Total"
                                    defaultValue={0}
                                    fullWidth
                                    disabled
                                    helperText={false}
                                  />
                                </Box>

                                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                                  <NumberInput
                                    source="shipping"
                                    label="Shipping"
                                    defaultValue={0}
                                    fullWidth
                                    helperText={false}
                                  />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                                  <NumberInput
                                    source="pack"
                                    label="Pack"
                                    fullWidth
                                    defaultValue={0}
                                    helperText={false}
                                  />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                                  <NumberInput
                                    source="misc"
                                    label="Misc"
                                    fullWidth
                                    defaultValue={0}
                                    helperText={false}
                                  />
                                </Box>
                                <Box flex={1}>
                                  <NumberInput
                                    source="discount"
                                    label="Discount"
                                    defaultValue={0}
                                    fullWidth
                                    helperText={false}
                                  />
                                </Box>
                              </Box>
                              <Box display={{ xs: "block", sm: "flex" }}>
                                <BooleanInput
                                  source="with_gst"
                                  label="W"
                                  option={{ size: "small" }}
                                  fullWidth
                                  helperText={false}
                                />
                              </Box>
                              <Box display={{ xs: "block", sm: "flex" }}>
                                <Box flex={1} mt={{ xs: 0, sm: "0.5em" }}>
                                  <FormDataConsumer {...formProps}>
                                    {({ formData }) => (
                                      <TextField label="GST@18" value={gstCalc(formData)} />
                                    )}
                                  </FormDataConsumer>
                                </Box>
                                <Box flex={1} mt={{ xs: 0, sm: "0.5em" }}>
                                  <FormDataConsumer {...formProps}>
                                    {({ formData }) => (
                                      <TextField label="Grand Total" value={totalCalc(formData)} />
                                    )}
                                  </FormDataConsumer>
                                </Box>
                              </Box>

                              <Box display={{ xs: "block", sm: "flex" }}>
                                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                                  <TextInput source="paid" label="Paid" fullWidth disabled />
                                </Box>
                                <Box flex={1} mt={{ xs: 0, sm: "0.5em" }}>
                                  <FormDataConsumer {...formProps}>
                                    {({ formData }) => (
                                      <TextField
                                        label="Balance Due"
                                        value={balanceCalc(formData)}
                                      />
                                    )}
                                  </FormDataConsumer>
                                </Box>
                              </Box>
                              <Box display={{ xs: "block", sm: "flex" }}>
                                <Box display={{ xs: "block", sm: "flex" }}>
                                  <BooleanInput
                                    source="is_invoice_created"
                                    label="Invoice Created"
                                    option={{ size: "small" }}
                                    fullWidth
                                    helperText={false}
                                  />
                                </Box>
                              </Box>
                            </Box>

                            {formProps.record.job_payment && (
                              <Box flex={1} className={classes.paymentDiv}>
                                <PaymentCard records={formProps.record.job_payment} />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>

                  <Box flex={1} className={classes.commentDiv}>
                    <Typography variant="p" gutterBottom className={classes.job}>
                      <GetJobName {...formProps} />
                    </Typography>

                    <Card>
                      {formProps.record.image && (
                        <Box display={{ xs: "block", sm: "flex" }}>
                          <div
                            style={{
                              position: "absolute",
                              top: "125px",
                              right: "70px",
                              zIndex: 2,
                            }}
                          >
                            <DeleteImageButton
                              resource="jobs"
                              id={formProps.record.id}
                              image_id={formProps.record.image}
                              image="image"
                            />
                          </div>
                          <div style={{ width: "250px", height: "250px" }}>
                            <Button onClick={() => toggle(true)}>
                              <img
                                src={`https://drive.google.com/thumbnail?authuser=0&sz=w350&id=${formProps.record.image}`}
                                alt={formProps.record.image}
                                style={{ padding: "10px" }}
                                width="100%"
                              />
                            </Button>
                          </div>
                        </Box>
                      )}
                      <Typography variant="h6" gutterBottom className={classes.title}>
                        COMMENTS{" "}
                        <CommentButton id={formProps.record.id} history={formProps.history} />
                      </Typography>
                    </Card>
                    {formProps.record.comment && <CommentCard records={formProps.record.comment} />}
                  </Box>
                </Box>
                {formProps.record.job_remnant && (
                  <>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                      Remants
                      <RemnantButton id={formProps.record.id} history={formProps.history} />
                    </Typography>
                    <Box display={{ xs: "block", sm: "flex" }}>
                      <RemnantCard records={formProps.record.job_remnant} />
                    </Box>
                  </>
                )}
                {formProps.record.image && (
                  <Viewer
                    visible={open}
                    onClose={() => toggle(false)}
                    images={[
                      {
                        src: `https://drive.google.com/thumbnail?authuser=0&sz=w1200&id=${formProps.record.image}`,
                        alt: formProps.record.image,
                      },
                    ]}
                  />
                )}
                {formProps.record.resource && (
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1}>
                      <ResourceCard
                        records={formProps.record.resource.filter(
                          (r) => r.category === "PRODUCTION"
                        )}
                        title="PRODUCTION"
                        category="PRODUCTION"
                        id={formProps.record.id}
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "2.5em" }}>
                      <ResourceCard
                        records={formProps.record.resource.filter(
                          (r) => r.category === "FINANCIAL"
                        )}
                        title="FINANCE & QUOTES"
                        category="FINANCIAL"
                        id={formProps.record.id}
                      />
                    </Box>

                    <Box flex={1} ml={{ xs: 0, sm: "2.5em" }}>
                      <ResourceCard
                        records={formProps.record.resource.filter((r) => r.category === "COMMON")}
                        title="COMMON"
                        category="COMMON"
                        id={formProps.record.id}
                      />
                    </Box>
                  </Box>
                )}
              </form>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <FormDataConsumer {...formProps}>
                {({ formData }) => (
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <SubJobsTable
                      records={formData.sub_job || []}
                      job_id={formData.id}
                      withGST={formData.with_gst}
                      history={formProps.history}
                      showTotal={showTotal}
                    />
                  </Box>
                )}
              </FormDataConsumer>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ReferenceManyField
                fullWidth
                label=""
                reference="job_quotations"
                target="job_id"
                sort={{ field: "updated_at", order: "DESC" }}
              >
                <QuotationList />
              </ReferenceManyField>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ReferenceManyField fullWidth label="" reference="job_invoices" target="job_id">
                <InvoiceList />
              </ReferenceManyField>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ReferenceManyField fullWidth label="" reference="job_challans" target="job_id">
                <ChallanList />
              </ReferenceManyField>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <ReferenceManyField fullWidth label="" reference="job_remnants" target="job_id">
                <RemnantList />
              </ReferenceManyField>
            </TabPanel>
          </>
        );
      }}
    />
  );
};

const JobTitle = (props) => {
  return (
    <>
      <GetJobName {...props} />
      <span>
        <IconButton
          onClick={() => {
            props.setShowTotal(!props.showTotal);
            localStorage.setItem("showTotal", !props.showTotal);
          }}
        >
          {props.showTotal ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </span>
    </>
  );
};

export default (props) => {
  const showTotal = localStorage.getItem("showTotal");
  const [showTotalLocal, setShowTotal] = React.useState(showTotal === "true");
  return (
    <Edit
      component="div"
      title={<JobTitle showTotal={showTotalLocal} setShowTotal={setShowTotal} />}
      undoable={false}
      {...props}
    >
      <JobsForm
        history={props.history}
        location={props.location}
        redirect=""
        margin="none"
        showTotal={showTotalLocal}
      />
    </Edit>
  );
};

const GetJobName = (props) => {
  const { record } = props;
  let id = "";
  if (record.id) id = `J${record.id}`;
  if (record.client) {
    if (record.client.first_name) id = `${id}-${record.client.first_name.toUpperCase()}`;
    if (record.client.last_name) id = `${id}-${record.client.last_name.toUpperCase()}`;
    if (record.client.firm_name) id = `${id}-${record.client.firm_name.toUpperCase()}`;
  }
  return <span style={{ marginRight: 100 }}>{id}</span>;
};

function gstCalc(data) {
  const { with_gst: withGST } = data;
  const sub = subTotal(data);
  if (!withGST) return 0;
  return (sub * 0.18).toFixed(1);
}

function totalCalc(data) {
  const sub = subTotal(data);
  const gst = parseFloat(gstCalc(data), 10);
  return sub + gst;
}

function balanceCalc(data) {
  const { paid } = data;
  const sub = subTotal(data);
  const gst = parseFloat(gstCalc(data), 10);
  return (sub + gst - paid).toFixed(1);
}

const optionRenderer = (choice) => {
  return (
    choice &&
    `${choice.first_name || ""} ${choice.last_name || ""} - ${choice.phone} ${
      choice.firm_name ? choice.firm_name : ""
    }`
  );
};

function subTotal(data) {
  const { total, discount, shipping, pack, misc } = data;
  return total - discount + shipping + pack + misc;
}
