import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: "red",
  },
}));

export const ReactSelectAdapter = ({ input, options, meta, label, multiple, ...rest }) => {
  const classes = useStyles();
  return (
    <FormControl variant="filled" className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select {...input} {...rest} className={classes.input}>
        {options.map((p) => (
          <MenuItem value={p.value} key={p.value}>
            {p.label}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && <span className={classes.error}>{meta.error}</span>}
    </FormControl>
  );
};
