import React, { useEffect, useState } from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
// import "./styles.css";
import Logo from "./logo.png";
import { NumericFormat } from "react-number-format";
import { useDataProvider } from "react-admin";

export const RupeeValue = ({ value }) => (
  <NumericFormat value={value} displayType={"text"} thousandSeparator={true} prefix={"₹"} />
);

function LabelPrint(props) {
  let params = new URLSearchParams(props.location.search);
  const id = params.get("id");
  const dataProvider = useDataProvider();
  const [q, setInvoice] = useState({});

  useEffect(() => {
    dataProvider
      .getOne("edit_job_invoices", { id })
      .then(({ data }) => {
        setInvoice(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const client = q?.client || {};
  const subJobs = q?.sub_job || [];
  const title = `TAX-INVOICE-${q.invoice_id || ""}-${q.job_id || ""}`;

  const groupBy = (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj["subjob_title"];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  const subJobMap = groupBy(subJobs);

  return (
    <div className="bodydiv">
      <div id="wrapper"></div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <header class="clearfix">
        <div id="logo">
          <img src={Logo} />
        </div>

        <h1 className="title">TAX-INVOICE</h1>

        <div class="address-to">
          <h3>
            <div>
              <b>To</b>
            </div>
            <div>
              {client.first_name} {client.last_name}
            </div>
            {client.firm_name && <div>{client.firm_name}</div>}
            {client.address && <div>{client.address}</div>}
            <div>{client.phone}</div>
            {client.gst && (
              <div>
                <b>GSTIN- {client.gst}</b>
              </div>
            )}
          </h3>
        </div>
        <div class="quote-date">
          <div>
            <h3>
              <b>Date</b>: {moment(q["created_at"]).format("DD-MM-YYYY hh:ss")}
              <br />
              <b>Invoice#</b>: {q.invoice_id}
              <br />
              <b>JobId#</b>: {q.job_id}
            </h3>
          </div>
        </div>
      </header>
      <main style={{ padding: "10px" }}>
        <div className="qtablediv">
          <table className="qtable">
            <thead>
              <tr>
                <th class="service id">ID</th>
                <th colspan="5">DESCRIPTION</th>
                <th colspan="3">HSN</th>
                <th>QTY</th>
                <th>RATE</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(subJobMap)
                .sort()
                .map((subJobTitle) => (
                  <>
                    {(subJobTitle !== "undefined" || subJobTitle !== null) && (
                      <tr>
                        <td colSpan={13} rowSpan={1}>
                          <h3 style={{ lineHeight: "1px" }}>{subJobTitle}</h3>
                        </td>
                      </tr>
                    )}
                    {subJobMap[subJobTitle].map((sub, index) => (
                      <tr>
                        <td class="qty">{index + 1}</td>
                        <td class="service" colspan="5">
                          {sub["material_thickness"]}mm {sub["material_type"]} {sub?.processes}{" "}
                          {sub?.size} {sub?.design}{" "}
                          {sub?.material_status && sub?.material_status === "OURS"
                            ? "With Material"
                            : "Without Material"}{" "}
                          <br />
                          {sub?.comment}
                        </td>
                        <td colspan="3">{sub["hsn"]}</td>
                        <td class="qty">{sub["quantity"]}</td>
                        <td class="rate qty">
                          <RupeeValue value={sub["rate"]} />
                        </td>
                        <td class="unit">
                          <RupeeValue value={Math.round(sub["quantity"] * sub["rate"])} />
                        </td>
                      </tr>
                    ))}
                  </>
                ))}

              {q.subTotal !== q.total ? (
                <tr>
                  <td colspan="10"></td>
                  <td colspan="1" class="grand">
                    SUBTOTAL
                  </td>
                  <td class="total">
                    <RupeeValue value={q["total"]} />
                  </td>
                </tr>
              ) : null}
              {q.discount ? (
                <tr>
                  <td colspan="10"></td>
                  <td colspan="1" class="grand">
                    DISCOUNT
                  </td>
                  <td class="total">
                    <RupeeValue value={q["discount"]} />
                  </td>
                </tr>
              ) : null}
              {q.pack ? (
                <tr>
                  <td colspan="10"></td>
                  <td colspan="1" class="grand">
                    PACKAGING
                  </td>
                  <td class="total">
                    <RupeeValue value={q["pack"]} />
                  </td>
                </tr>
              ) : null}
              {q.misc ? (
                <tr>
                  <td colspan="10"></td>
                  <td colspan="1" class="grand">
                    MISCELLANEOUS
                  </td>
                  <td class="total">
                    <RupeeValue value={q["misc"]} />
                  </td>
                </tr>
              ) : null}
              {q.shipping ? (
                <tr>
                  <td colspan="10"></td>
                  <td colspan="1" class="grand">
                    SHIPPING
                  </td>
                  <td class="total">
                    <RupeeValue value={q["shipping"]} />
                  </td>
                </tr>
              ) : null}
              {q.gst_type === "IGST" ? (
                <tr>
                  <td colspan="10"></td>
                  <td colspan="1" class="grand">
                    GST@18%
                  </td>
                  <td class="total">
                    <RupeeValue value={q["gst"]} />
                  </td>
                </tr>
              ) : (
                <>
                  <tr>
                    <td colspan="10"></td>
                    <td colspan="1" class="grand">
                      CGST@9%
                    </td>
                    <td class="total">
                      <RupeeValue value={q["gst"] / 2} />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="10"></td>
                    <td colspan="1" class="grand">
                      SGST@9%
                    </td>
                    <td class="total">
                      <RupeeValue value={q["gst"] / 2} />
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td colspan="10"></td>
                <td colspan="1" class="grand">
                  TOTAL
                </td>
                <td class="total">
                  <RupeeValue value={q["grand_total"]} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 id="notices">
          <div>
            <b>GSTIN : 27AAQPD8698D1Z6</b>
          </div>
          <br />
          <br />
          <div>
            <b>Bank Details</b>
          </div>
          <br />
          <div>
            <b>A/c Name</b> : Jaali.in{" "}
          </div>
          <div>
            <b>A/c No.</b> : 10541131003463,
          </div>
          <div>
            <b>A/c Type</b> : Current Account
          </div>
          <div>
            <b>Bank Name</b> : Punjab National Bank
          </div>
          <div>
            <b>Branch</b> : Camp
          </div>
          <div>
            <b>IFSC Code</b> : PUNB0038600
          </div>

          <br />
          <div>
            <b>Terms & Conditions:</b>
          </div>
          <div>
            Goods once sold will not be taken back or exchange. Subject to Pune Jurisdiction only.
            Our responsibility ceases when goods leave our premises.
          </div>
        </h3>
      </main>
      <footer className="footerDiv">
        <div>
          <b>Quresh</b>: +91 9923 80 8585 <b>Murtaza</b>: +91 9923 80 8989 <b>Email</b>:
          jaali.in@gmail.com
        </div>
        <div>
          <b>Postal Address</b>: #13, Iqra Bldg., 2390-B, New Modikhana, Near Poona College, Pune -
          411001.
        </div>
      </footer>
    </div>
  );
}

export default LabelPrint;
