import React from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  useNotify,
  useRefresh,
} from "react-admin";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    display: "inline-flex",
    marginRight: theme.spacing(2),
    width: "260px",
  },
  fullInput: {
    width: "532x",
  },
}));

export default ({ history, ...props }) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = (props) => {
    notify(`Vendor Added SuccessFully`);
    history.goBack();
    refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput source="first_name" formClassName={classes.input} />
        <TextInput source="last_name" formClassName={classes.input} />
        <TextInput source="firm_name" formClassName={classes.input} />
        <TextInput
          source="phone"
          validate={required()}
          formClassName={classes.input}
        />

        <TextInput source="email" formClassName={classes.input} />

        <TextInput source="address" multiline formClassName={classes.input} />
        <TextInput source="gst" formClassName={classes.input} label="GSTIN" />
        <TextInput source="city" formClassName={classes.input} />
        <TextInput source="state" formClassName={classes.input} />
        <TextInput source="country" formClassName={classes.input} />
        <TextInput source="pincode" formClassName={classes.input} />
      </SimpleForm>
    </Create>
  );
};
