import React from "react";
import { useNotify, useDataProvider, useRefresh, Button } from "react-admin";
import EditIcon from "@material-ui/icons/FileCopy";
function CopyButton({ id }) {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  function handleClick() {
    dataProvider
      .getOne("sub_job_copy", { id: id })
      .then(() => {
        refresh();
      })
      .catch(() => {
        notify("Error: field not created", "warning");
      });
  }

  return (
    <Button variant="contained" label="Copy" onClick={handleClick}>
      <EditIcon />
    </Button>
  );
}

export default CopyButton;
