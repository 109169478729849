import Edit from "./edit";
import Icon from "@material-ui/icons/LibraryBooks";
import editProduction from "./editProduction";
import List from "./list";

export default {
  list: List,
  edit: Edit,
  editProduction: editProduction,
  name: "sub_jobs",
  options: { label: "SubJobs" },
  icon: Icon,
};
