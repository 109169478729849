import React, { useState } from "react";
import {
  Create,
  NumberInput,
  ImageInput,
  ImageField,
  TabbedForm,
  FormTab,
  TextInput,
  required,
  FormDataConsumer,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import RichTextInput from "../../../components/richInputText";
import CategoryReferenceInput from "../../../components/createButton";
import uploadProps from "../../../dataprovider/uploadProps";
import UploadProgress from "../../../components/uploadProgress";
import Toolbar from "../../../components/toolbar";
import SubCatReferenceInput from "../subCategory/createButton";

export default (props) => {
  const [progress, setProgress] = useState();
  const transform = async (data) =>
    await uploadProps(data, setProgress, "PRODUCTS");
  return (
    <>
      <Create {...props} undoable="false" transform={transform}>
        <TabbedForm toolbar={<Toolbar disabled={!!progress} />}>
          <FormTab label="Details">
            <CategoryReferenceInput
              source="category_id"
              reference="categories"
              perPage={9999}
              field="name"
            />
            <FormDataConsumer>
              {({ formData }) =>
                formData.category_id && (
                  <SubCatReferenceInput
                    source="sub_category_id"
                    reference="sub_categories"
                    resource="sub_categories"
                    perPage={9999}
                    filter={{ categories_id: formData.category_id }}
                    field="name"
                  />
                )}
            </FormDataConsumer>
            <TextInput source="title" validate={required()} />
            <TextInput source="supc" validate={required()} />
            <NumberInput source="stock" validate={required()} />
            <NumberInput source="cost_price" validate={required()} />
            <NumberInput source="selling_price" validate={required()} />
            <NumberInput source="gst" validate={required()} />
            <TextInput source="hsn" validate={required()} />
            <FormDataConsumer>
              {({ formData }) => (
                <TextInput
                  label="SP+GST (Rs)"
                  value={Math.round(
                    (formData.selling_price || 0)
                      * (1 + (formData.gst || 0) / 100)
                  )}
                  disabled
                />
              )}
            </FormDataConsumer>
            <NumberInput source="mrp" validate={required()} />
            <NumberInput source="priority" validate={required()} />
            <BooleanInput source="is_enabled" />
            <BooleanInput source="on_website" label="Show on Website" />
            <BooleanInput source="show_stock" label="Show Stock on Website" />
          </FormTab>
          <FormTab label="Image">
            <ImageInput
              source="image_1"
              label="Image_1"
              accept="image/*"
              multiple={false}
              placeholder={<p>Drop your file here</p>}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput
              source="image_2"
              label="Image_2"
              accept="image/*"
              multiple={false}
              placeholder={<p>Drop your file here</p>}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput
              source="image_3"
              label="Image_3"
              accept="image/*"
              multiple={false}
              placeholder={<p>Drop your file here</p>}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput
              source="image_4"
              label="Image_4"
              accept="image/*"
              multiple={false}
              placeholder={<p>Drop your file here</p>}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput
              source="image_5"
              label="Image_5"
              accept="image/*"
              multiple={false}
              placeholder={<p>Drop your file here</p>}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput
              source="image_6"
              label="Image_6"
              accept="image/*"
              multiple={false}
              placeholder={<p>Drop your file here</p>}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput
              source="image_7"
              label="Image_7"
              accept="image/*"
              multiple={false}
              placeholder={<p>Drop your file here</p>}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput source="video" label="Video Id" />
          </FormTab>
          <FormTab label="Description">
            <RichTextInput
              source="detailed_description"
              title="Detailed Description"
              validate={required()}
            />
            <RichTextInput source="contents" title="Contents in the box" />
            <RichTextInput
              source="s_description"
              title="Special Need Description"
            />
            <ReferenceArrayInput
              source="tags"
              reference="tags"
              label="Tags"
              perPage={99999}
            >
              <SelectArrayInput optionText="id" />
            </ReferenceArrayInput>
          </FormTab>
          <FormTab label="Size">
            <NumberInput source="width" label="Width (in)" />
            <NumberInput source="height" label="Height (in)" />
            <NumberInput source="weight" label="Weight (gm)" />

            <TextInput source="size" label="Any Other Size" />
          </FormTab>
        </TabbedForm>
      </Create>
      <UploadProgress progress={progress} />
    </>
  );
};
