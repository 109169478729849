import React from "react";
import {
  Filter,
  List,
  NumberInput,
  Responsive,
  TopToolbar,
  ReferenceInput,
  AutocompleteInput,
  Pagination,
  DateInput,
  Button,
} from "react-admin";

import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ConfirmButton from "../../../components/confirmButton";
import DataGrid from "./dataList";
import JobCreateButton from "./createButton";
import { tabs as ts, tabsProduction } from "./tabs";

const permissions = (localStorage.getItem("access") || "") !== "jaali_production";
const optionRenderer = (choice) => {
  if (!choice.phone) {
    return "";
  }
  return `${choice.first_name} ${choice.last_name} ${permissions ? choice.phone : ""} ${
    permissions ? choice.firm_name || "" : ""
  }`;
};
const JobFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <NumberInput source="id" label="Job Id" alwaysOn />
      <ReferenceInput source="client_id" reference="clients" perPage={50} fullWidth alwaysOn>
        <AutocompleteInput optionText={optionRenderer} resettable />
      </ReferenceInput>
      <DateInput source="from" label="From" alwaysOn resettable />
      <DateInput source="to" label="To" alwaysOn resettable />
      <Button onClick={() => props.setFilters({})} label="Clear Filters" alwaysOn />
    </Filter>
  );
};

const tabs = !permissions ? tabsProduction : ts;
function TabbedDatagrid(props) {
  function handleChange(event, value) {
    const { filterValues, setFilters } = props;
    setFilters({ ...filterValues, status: value });
  }
  const { filterValues, resource } = props;
  const initValues = JSON.parse(localStorage.getItem(`${resource}_count`)) || {};
  return (
    <>
      <Tabs centered value={filterValues.status} indicatorColor="primary" onChange={handleChange}>
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={`${choice.name} (${initValues[choice.name] || 0})`}
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <Responsive medium={<DataGrid {...props} />} />
    </>
  );
}

const PostBulkActionButtons = (props) => {
  return <ConfirmButton {...props} options={tabs.filter((f) => f.id !== "ALL")} />;
};

const JobActions = (props) => (
  <TopToolbar>
    <JobCreateButton withGst label="Create Job" {...props} />
  </TopToolbar>
);

const JobPagination = (props) => <Pagination rowsPerPageOptions={[50, 100, 150]} {...props} />;

export default function SubJobList(props) {
  return (
    <List
      {...props}
      filterDefaultValues={{ status: tabs[0].id }}
      sort={{ field: "updated_at", order: "DESC" }}
      perPage={50}
      actions={permissions && <JobActions {...props} />}
      filters={<JobFilter />}
      pagination={<JobPagination />}
      bulkActionButtons={<PostBulkActionButtons />}
    >
      <TabbedDatagrid />
    </List>
  );
}
