import List from './list';
import Edit from './edit';
import Create from './create';
import Icon from "@material-ui/icons/LibraryBooks";

export default {
	list: List,
	edit: Edit,
	create: Create,
	icon: Icon,
	label: 'JaaliProduct',
	options: { label: 'JaaliProduct' },
	name: 'jaali_products',
};
