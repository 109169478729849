import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  required,
  TextInput,
  NumberInput,
  FileInput,
  FileField,
  FormDataConsumer,
  Toolbar,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import uploadProps from "../../../dataprovider/uploadProps";
import UploadProgress from "../../../components/uploadProgress";
import { SaveButtonAware } from "../../../components/toolbar";
import RichTextInput from "../../../components/richInputText";

export default (props) => {
  const [progress, setProgress] = useState();
  const transform = async (data) =>
    await uploadProps(data, setProgress, "ADVERTISEMENTS");
  return (
    <>
      <Edit {...props} undoable={false} transform={transform}>
        <SimpleForm
          redirect={`/worksheets`}
          toolbar={<EditToolbar disabled={!!progress} />}
        >
          <ReferenceInput
            label="Category"
            source="category_id"
            reference="categories"
            perPage={9999}
            filter={{ is_worksheet: 1 }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="title" label="title" />
          <FileInput
            source="file"
            label="file"
            placeholder={<p>Drop your file here</p>}
          >
            <FileField source="src" title="title" />
          </FileInput>
          <FormDataConsumer>
            {({ formData }) =>
              formData.file &&
              typeof formData.file === "string" && (
                <img
                  src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${formData.file}`}
                  alt={formData.file}
                />
              )
            }
          </FormDataConsumer>
          <RichTextInput
            source="description"
            title="Description"
            validate={required()}
          />
          <NumberInput source="priority" />
        </SimpleForm>
      </Edit>
      <UploadProgress progress={progress} />
    </>
  );
};

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButtonAware undoable={false} disabled={props.disabled} />
  </Toolbar>
);
