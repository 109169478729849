import React, { useState } from "react";
import {
  required,
  Button,
  SaveButton,
  useCreate,
  useNotify,
  useRefresh,
  FormWithRedirect,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
} from "react-admin";
import IconCancel from "@material-ui/icons/Cancel";
import Add from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

function SubJobProductCreate({ id, history }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("sub_jobs");
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: { ...values, job_id: id } } },
      {
        onSuccess: () => {
          refresh();
          history.push(`/jobs/${id}`);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  const optionRenderer = (choice) =>
    choice &&
    `${choice.design} ${choice.material_type} - ${choice.material_thickness}mm ${choice.width}"x${choice.height}"`;
  return (
    <>
      <Button onClick={handleClick} label="Product">
        <Add />
      </Button>
      <Dialog
        
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Jaali Product Subjob"
      >
        <DialogTitle>Create Jaali Product Subjob</DialogTitle>

        <FormWithRedirect
          resource="sub_jobs"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <ReferenceInput
                  source="jaali_product_id"
                  reference="jaali_products"
                  perPage={25}
                  validate={required()}
                  fullWidth
                >
                  <AutocompleteInput optionText={optionRenderer} />
                </ReferenceInput>
                <NumberInput
                  source="quantity"
                  validate={required()}
                  defaultValue={1}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default SubJobProductCreate;
