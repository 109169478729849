import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { NumberInput, FormDataConsumer } from "react-admin";
import TextField from "./textField";

export default ({ formProps, calcProcess }) => (
  <>
    <Typography variant="h6">PROCESS</Typography>
    <Box display={{ xs: "block", sm: "flex" }}>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <NumberInput source="cutting" resource="sub_jobs" fullWidth helperText={false} />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <NumberInput source="engraving" resource="sub_jobs" fullWidth helperText={false} />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <NumberInput source="chamfering" resource="sub_jobs" fullWidth helperText={false} />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <NumberInput source="threed" label="3d" resource="sub_jobs" fullWidth helperText={false} />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <NumberInput source="fluting" resource="sub_jobs" fullWidth helperText={false} />
      </Box>

      <Box flex={3}></Box>
    </Box>
    <Box display={{ xs: "block", sm: "flex" }}>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <NumberInput source="coating" resource="sub_jobs" fullWidth helperText={false} />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <NumberInput source="plating" resource="sub_jobs" fullWidth helperText={false} />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <NumberInput source="buffing" resource="sub_jobs" fullWidth helperText={false} />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <NumberInput source="other" resource="sub_jobs" fullWidth helperText={false} />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <NumberInput source="process_sqft" resource="sub_jobs" fullWidth helperText={false} />
      </Box>
      <Box flex={1.2}></Box>
      <Box flex={1.8} ml={{ xs: 0, sm: "0.1em" }}>
        <FormDataConsumer {...formProps}>
          {({ formData }) => <TextField label="Process Total" value={calcProcess(formData)} />}
        </FormDataConsumer>
      </Box>
    </Box>
  </>
);
