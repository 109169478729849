import * as React from "react";
import {
  DateInput,
  Edit,
  NumberInput,
  SelectInput,
  Toolbar,
  FormWithRedirect,
  required,
  TextInput,
  FormDataConsumer,
  SaveButton,
  Button,
} from "react-admin";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Viewer from "react-viewer";

import ClientReferenceInput from "./clientButton";
import SubJobsTable from "./components/subJobsTable";

import ResourceCard from "./components/resourceCard";
import { tabsProduction } from "./tabs";
import RemnantsButton from "./components/remnantButton";

const useStyles = makeStyles((theme) => ({
  fullWidth: { width: "100%" },
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
  block: { display: "block" },
  textField: {
    width: "256px",
    color: "#000",
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  commentCard: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    padding: theme.spacing(2.5),
  },
  paymentDiv: {
    overflowY: "auto",
    height: "250px",
  },
  commentDiv: {
    overflowY: "auto",
    height: "800px",
  },
  title: {
    margin: theme.spacing(2),
  },
  job: {
    display: "flex",
    marginBottom: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(3),
  },
  alignCenter: {
    alignSelf: "center",
  },
}));

const JobsForm = ({ history, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const toggle = (val) => {
    setOpen(val);
  };
  const classes = useStyles();
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <form>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={3} m={0} mr={{ xs: 0, sm: "1em" }}>
              <Card className={classes.card}>
                <CardContent>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={6} className={classes.alignCenter}>
                      <Typography variant="h6" gutterBottom>
                        CLIENT / JOB DETAILS{" "}
                      </Typography>
                      <Typography variant="p" gutterBottom className={classes.job}>
                        Created By:{" "}
                        {formProps.record.created_by && formProps.record.created_by.name}
                        <br />
                        Updated By:{" "}
                        {formProps.record.updated_by && formProps.record.updated_by.name}
                      </Typography>
                    </Box>
                    <Box flex={1}>
                      <Toolbar {...formProps} classes={classes}>
                        <SaveButton />
                        <RemnantsButton change={formProps.form.change} />
                      </Toolbar>
                    </Box>
                  </Box>

                  <Box display={{ xs: "block", sm: "flex" }} m={0}>
                    <Box flex={1}>
                      <NumberInput
                        source="id"
                        resource="jobs"
                        disabled
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={6} ml={{ xs: 0, sm: "0.5em" }}>
                      <ClientReferenceInput
                        source="client_id"
                        reference="clients"
                        perPage={9999}
                        validate={required()}
                        history={history}
                        optionRenderer={optionRenderer}
                        fullWidth
                        disabled
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1}>
                      <TextInput source="site_name" resource="jobs" fullWidth disabled />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <SelectInput
                        source="status"
                        resource="jobs"
                        choices={tabsProduction}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <DateInput source="created_at" label="Order Date" disabled fullWidth />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <DateInput source="delivery_date" resource="jobs" fullWidth disabled />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <DateInput source="remnant_id" resource="jobs" fullWidth />
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <FormDataConsumer {...formProps}>
                {({ formData }) => (
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <SubJobsTable
                      records={formData.sub_job || []}
                      job_id={formData.id}
                      withGST={formData.with_gst}
                      history={formProps.history}
                    />
                  </Box>
                )}
              </FormDataConsumer>
            </Box>

            <Box flex={1} className={classes.commentDiv}>
              <Card>
                {formProps.record.image && (
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Button onClick={() => toggle(true)}>
                      <img
                        src={`https://drive.google.com/thumbnail?authuser=0&sz=w350&id=${formProps.record.image}`}
                        alt={formProps.record.image}
                        style={{ padding: "10px" }}
                        width="100%"
                      />
                    </Button>
                  </Box>
                )}
              </Card>
            </Box>
          </Box>
          {formProps.record.image && (
            <Viewer
              visible={open}
              onClose={() => toggle(false)}
              images={[
                {
                  src: `https://drive.google.com/thumbnail?authuser=0&sz=w1200&id=${formProps.record.image}`,
                  alt: formProps.record.image,
                },
              ]}
            />
          )}
          {formProps.record.resource && (
            <Box display={{ xs: "block", sm: "flex" }}>
              <ResourceCard
                records={formProps.record.resource.filter((r) => r.category === "PRODUCTION")}
                title="PRODUCTION"
                category="PRODUCTION"
                id={formProps.record.id}
              />
            </Box>
          )}
        </form>
      )}
    />
  );
};

const JobTitle = (props) => <GetJobName {...props} />;

export default (props) => (
  <Edit component="div" title={<JobTitle />} undoable={false} {...props}>
    <JobsForm history={props.history} redirect="" margin="none" />
  </Edit>
);
const optionRenderer = (choice) => choice && `${choice.first_name || ""} ${choice.last_name || ""}`;

const GetJobName = (props) => {
  const { record } = props;
  let id = "";
  if (record.id) id = `J${record.id}`;
  if (record.client) {
    if (record.client.first_name) id = `${id}-${record.client.first_name.toUpperCase()}`;
    if (record.client.last_name) id = `${id}-${record.client.last_name.toUpperCase()}`;
  }
  return <span>{id}</span>;
};
