import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  BooleanField,
  TextInput,
  NumberInput,
  Filter,
  ReferenceField,
  FunctionField,
  useNotify,
} from "react-admin";
import Icon from "@material-ui/icons/FiberManualRecord";
import IconButton from "@material-ui/core/IconButton";

import CopyIcon from "@material-ui/icons/FileCopy";
import BulkUpdateButton from "./bulkUpdateButton";

const postRowStyle = (record) => {
  let color;
  if (record.is_used) {
    color = "red";
  } else if (record.is_blocked) {
    color = "orange";
  }
  return {
    backgroundColor: color,
  };
};

const ProductsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="type" alwaysOn resettable />
    <NumberInput source="thickness" alwaysOn resettable />
    <NumberInput source="width" alwaysOn resettable />
    <NumberInput source="height" alwaysOn resettable />
  </Filter>
);

const TrueIcon = () => <Icon style={{ color: "red" }} />;
const FalseIcon = () => <Icon style={{ color: "green" }} />;

function PostBulkActionButtons(props) {
  const status = { is_used: 1 };
  return (
    <>
      <BulkUpdateButton
        label="Mark Used"
        {...props}
        status={status}
        title="Mark Used"
        content="are you sure you want to mark these remnants as used"
      />
    </>
  );
}

export default (props) => {
  const notify = useNotify();
  return (
    <List {...props} filters={<ProductsFilter />} bulkActionButtons={<PostBulkActionButtons />}>
      <Datagrid rowClick="" rowStyle={postRowStyle}>
        <TextField source="id" />
        <ReferenceField source="job_id" reference="jobs">
          <TextField source="id" />
        </ReferenceField>
        <TextField source="type" />
        <NumberField source="thickness" label="Thickness(mm)" textAlign="left" />
        <TextField source="color" />
        <NumberField source="width" label="Width (in)" />
        <NumberField source="height" label="Height (in)" />
        <NumberField source="lwidth" label="L Width (in)" />
        <NumberField source="lheight" label="L Height (in)" />
        <BooleanField
          source="is_blocked"
          label="Blocked"
          TrueIcon={TrueIcon}
          FalseIcon={FalseIcon}
        />
        <BooleanField source="is_used" label="Used" TrueIcon={TrueIcon} FalseIcon={FalseIcon} />

        <FunctionField
          label=" "
          render={(record) => (
            <IconButton aria-label="Copy" onClick={() => copyToClipboard(record, notify)}>
              <CopyIcon fontSize="small" />
            </IconButton>
          )}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const copyToClipboard = (record, notify) => {
  navigator.clipboard.writeText(
    `*Remnant Details*
Remnant ID: ${record.id}
Type: ${record.type}
Thickness: ${record.thickness}
Color: ${record.color}
Dimension : ${record.width}"x${record.height}"
L-Dimension: ${record.lwidth}"x${record.lheight}"`
  );
  notify("Copied to Clipboard");
};
