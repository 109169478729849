import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Grid from "@material-ui/core/Grid";
import "./styles.css";
import Typography from "@material-ui/core/Typography";
import { useDataProvider } from "react-admin";
import moment from "moment";

function LabelPrint(props) {
  let params = new URLSearchParams(props.location.search);
  const id = params.get("id");
  const dataProvider = useDataProvider();
  const [q, setChallan] = useState({});
  useEffect(() => {
    dataProvider
      .getOne("job_challans", { id })
      .then(({ data }) => {
        setChallan(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const client = JSON.parse(q?.client || "{}");
  const subJobs = JSON.parse(q?.sub_jobs || "[]");
  const title = `C${q.job_id}-${client.first_name || ""}-${client.last_name || ""}-${
    client.firm_name || ""
  }`.toUpperCase();

  const getDimensions = (sub) => {
    const dimension = JSON.parse(sub.dimensions || "[]");
    if (dimension.on === "mm") {
      return dimension.mm ? `${dimension.mm.width}mm x${dimension.mm.height}mm` : "";
    }
    return dimension.in ? `${dimension.in.width}in x${dimension.in.height}in` : "";
  };

  const calcQtyTotal = () => {
    let total = 0;
    subJobs.map((s) => {
      total += s.quantity * s.pcs;
    });
    return total;
  };

  const DefaultImage = ({ resources = [] }) => {
    const defaultImg = resources.filter((r) => r.is_default);
    if (defaultImg.length > 0) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            aspectRatio: "1/1",
            marginBottom: 10,
            // maxWidth: "500px", // Adjust the max-width as per your requirement
          }}
        >
          <img
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            src={`https://drive.google.com/thumbnail?authuser=0&sz=w700&id=${defaultImg[0]["resource"]}`}
            
            alt="product"
          />
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          aspectRatio: "1/1",
          marginBottom: 10,
          // maxWidth: "500px", // Adjust the max-width as per your requirement
        }}
      ></div>
    );
  };

  return (
    <div className="bodydiv">
      <div id="wrapper"></div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <header class="clearfix">
        <h1 className="title">DELIVERY CHALLAN</h1>
      </header>
      <main style={{ padding: "10px", paddingBottom: "4rem" }}>
        <Grid container spacing={2} style={{ padding: "0 70px" }}>
          <Grid item xs={12} style={{ border: "1px solid #afafaf" }}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="body2" display="block">
                  Job ID
                  <br />
                  <b>{q.job_id}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" display="block">
                  Client
                  <br />
                  <b>
                    {client.first_name} {client.last_name}
                  </b>
                </Typography>
              </Grid>
              {q.site && (
                <Grid item>
                  <Typography variant="body2" display="block">
                    Site
                    <br />
                    <b>{q.site}</b>
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Typography variant="body2" display="block" style={{ textAlign: "right" }}>
                  Total Qty
                  <br />
                  <b style={{ fontSize: "20px" }}>{calcQtyTotal()}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {subJobs.map((sub, index) => (
            <Grid
              item
              xs={12 / q.grid}
              className="infoDiv"
              style={{ position: "relative", justifyContent: "flex-end" }}
              key={index}
            >
              {sub?.image ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    aspectRatio: "1/1",
                    marginBottom: 10,
                    // maxWidth: "500px", // Adjust the max-width as per your requirement
                  }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    src={`https://drive.google.com/thumbnail?authuser=0&sz=w700&id=${sub["image"]}`}
                    alt="product"
                  />
                </div>
              ) : (
                <DefaultImage resources={sub.resource} />
              )}
              <div>
                <Typography variant="body2" display="block">
                  Material -
                  <b>
                    {sub.material_thickness} {sub.material_type}
                  </b>
                </Typography>
                <Typography variant="body2" display="block">
                  Size - <b>{getDimensions(sub)}</b>
                </Typography>
                <Typography variant="body2" display="block">
                  ID - <b>{sub.id}</b>{" "}
                  <span style={{ marginLeft: 10 }}>
                    QTY - <b>{sub.quantity}</b>{" "}
                    {sub.pcs > 0 ? (
                      <>
                        {`(${sub.quantity}x${sub.pcs})`} <b>{sub.quantity * sub.pcs}Pcs</b>
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </Typography>
                {sub.subjob_title && (
                  <Typography variant="body2" display="block">
                    Title - <b>{sub.subjob_title}</b>
                  </Typography>
                )}
              </div>
            </Grid>
          ))}
        </Grid>

        <footer className="footerDiv">
          <Grid container spacing={2} style={{ padding: "10px 10px 10px" }} justify="space-between">
            <Grid item>
              <Typography variant="body2" display="block" style={{ textAlign: "left" }}>
                Date: {moment(q.created_at).format("DD/MM/YYYY")}
                <br />
                Mode:
              </Typography>
              <br />
            </Grid>
            <Grid item>
              <Typography variant="h6" display="block" style={{ textAlign: "center" }}>
                Receiver
              </Typography>
              <br />
            </Grid>
            <Grid item>
              <Typography variant="h6" display="block" style={{ textAlign: "right" }}>
                Jaali.in
              </Typography>
              <br />
            </Grid>
          </Grid>

          <div>Received the above mentioned material in good order and condition</div>
        </footer>
      </main>
    </div>
  );
}

export default LabelPrint;
