import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

export default ({ record }) => {
  if (!record) return "";
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom style={{ marginRight: "1em" }}>
          ASSET
        </Typography>
        <img
          src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${record.image}`}
          alt={record.image}
        />
      </CardContent>
    </Card>
  );
};
