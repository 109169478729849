import React from "react";
import { Create, SimpleForm, required, TextInput } from "react-admin";

export default props => (
  <Create {...props} undoable={false}>
    <SimpleForm redirect={`/colors`}>
      <TextInput source="id" label="name" validate={required()} />
    </SimpleForm>
  </Create>
);
