import React, { useState } from "react";
import { Button, TextInput, NumberInput, useDataProvider } from "react-admin";
import Add from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import Search from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    width: theme.spacing(12),
    marginRight: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  row: {
    cursor: "pointer",
  },
}));

function RemnantButton({ change }) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const dataProvider = useDataProvider();
  const [details, setDetails] = useState({});
  const [remnants, setRemnants] = useState([]);
  const [noResult, setNoResult] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    change("remnant_type", undefined);
    change("remnant_thickness", undefined);
    change("remnant_width", undefined);
    change("remnant_height", undefined);
    setShowDialog(false);
  };

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const searchRemnant = () => {
    setNoResult(false);
    dataProvider
      .getList("remnants", {
        pagination: { page: 1, perPage: 100 },
        sort: {},
        filter: details,
      })
      .then(({ data }) => {
        if (data.length === 0) {
          setNoResult(true);
        }
        setRemnants(data);
      })
      .catch((e) => console.log(e));
  };
  const selectRemnant = (id) => {
    change("remnant_id", id);
    handleCloseClick();
  };

  return (
    <>
      <Button onClick={handleClick} label="Remnants">
        <Add />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Select Remnant"
      >
        <DialogTitle>Select Remnant</DialogTitle>

        <DialogContent>
          <TextInput
            source="remnant_type"
            label="type"
            onChange={(e) => onChangeDetails(e, "type")}
            className={classes.input}
          />
          <NumberInput
            source="remnant_thickness"
            label="thickness"
            onChange={(e) => onChangeDetails(e, "thickness")}
            className={classes.input}
          />
          <NumberInput
            source="remnant_width"
            label="width"
            onChange={(e) => onChangeDetails(e, "width")}
            className={classes.input}
          />
          <NumberInput
            source="remnant_height"
            label="height"
            onChange={(e) => onChangeDetails(e, "height")}
            className={classes.input}
          />
          <Search
            onClick={searchRemnant}
            variant="contained"
            className={classes.button}
          >
            <SearchIcon />
          </Search>
          {noResult && <div>No Remnants found</div>}
          {remnants.length > 0 && (
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Thickness</TableCell>
                  <TableCell>Color</TableCell>
                  <TableCell>Width</TableCell>
                  <TableCell>Height</TableCell>
                  <TableCell>L Width</TableCell>
                  <TableCell>L Height</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {remnants.map((r) => (
                  <TableRow
                    className={classes.row}
                    key={r.id}
                    onClick={() => selectRemnant(r.id)}
                    hover
                  >
                    <TableCell>{r.type}</TableCell>
                    <TableCell>{r.thickness}</TableCell>
                    <TableCell>{r.color}</TableCell>
                    <TableCell>{r.width}</TableCell>
                    <TableCell>{r.height}</TableCell>
                    <TableCell>{r.lwidth}</TableCell>
                    <TableCell>{r.lheight}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default RemnantButton;
