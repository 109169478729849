// import React, { Component, Fragment } from "react";
// import { useNotify, useDataProvider } from "react-admin";
// import { Button } from "@material-ui/core";
// import IconContentAdd from "@material-ui/icons/Add";

// class CreateButton extends Component {
//   handleClick = () => {
//     const { push, withGst } = this.props;
//     const dataProvider = useDataProvider();
//     const notify = useNotify();
//     dataProvider(CREATE, "orders", { data: { with_gst: withGst } })
//       .then((res) => {
//         push(`/orders/${res.data.id}`);
//       })
//       .catch((e) => {
//         showNotification("Error: field not created", "warning");
//       });
//   };

//   render() {
//     const { classes, withGst } = this.props;
//     return (
//       <Fragment>
//         <Button
//           color="primary"
//           onClick={this.handleClick}
//           className={classes.button}
//         >
//           <IconContentAdd className={classes.icon} />
//           {`${withGst ? "GST BILL" : "CASH MEMO"}`}
//         </Button>
//       </Fragment>
//     );
//   }
// }

// export default CreateButton;

import React from "react";
import { useNotify, useDataProvider, Button } from "react-admin";

function CreateButton(props) {
  const { withGst, label, history } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  function handleClick() {
    dataProvider
      .create("orders", { data: { with_gst: withGst } })
      .then((res) => {
        history.push(`/orders/${res.data.id}`);
        notify("Order Created");
      })
      .catch((err) => {
        console.log(err);
        notify("Error: Order not created", "warning");
      });
  }

  return <Button label={label} onClick={handleClick} />;
}

export default CreateButton;
