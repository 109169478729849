import React, { useState } from "react";
import {
  Button,
  useUpdateMany,
  useUnselectAll,
  useNotify,
  useRefresh,
} from "react-admin";
import IconSave from "@material-ui/icons/Save";
import IconCancel from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";

function ConfirmButton({ resource, selectedIds }) {
  const [showDialog, setShowDialog] = useState(false);
  const [price, setPrice] = useState();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const classes = useStyles();
  const handleChange = (e) => {
    setPrice(e.target.value);
  };
  const handleClick = () => {
    setShowDialog(true);
  };
  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const [updateMany, { loading }] = useUpdateMany(
    resource,
    selectedIds,
    { price },
    {
      onSuccess: () => {
        refresh();
        notify("Updated");
        unselectAll(resource);
      },
      onFailure: (error) => notify("Error: Not updated", "warning"),
    }
  );
  return (
    <>
      <Button onClick={handleClick} label="Change Price" />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Change Status"
      >
        <DialogTitle>Change Price</DialogTitle>
        <DialogContent>
          <TextField
            required
            id="price"
            label="Price"
            name="price"
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleCloseClick}
            disabled={loading}
            className={classes.button}
          >
            <IconCancel />
          </Button>
          <Button
            label="ra.action.confirm"
            onClick={updateMany}
            variant="contained"
            disabled={!price}
            className={classes.button}
          >
            <IconSave />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    height: "50px",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default ConfirmButton;
