import React from "react";
import {
  Edit,
  SimpleForm,
  required,
  ReferenceInput,
  SelectInput,
  Toolbar,
  SaveButton
} from "react-admin";

export default props => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect={`/dashboards`} toolbar={<EditToolbar />}>
      <ReferenceInput
        source="tag1"
        reference="tags"
        label="tag 1"
        perPage={1000}
        validate={required()}
      >
        <SelectInput optionText="id" />
      </ReferenceInput>
      <ReferenceInput
        source="tag2"
        reference="tags"
        label="tag 2"
        perPage={1000}
        validate={required()}
      >
        <SelectInput optionText="id" />
      </ReferenceInput>
      <ReferenceInput
        source="tag3"
        reference="tags"
        label="tag 3"
        perPage={1000}
        validate={required()}
      >
        <SelectInput optionText="id" />
      </ReferenceInput>
      <ReferenceInput
        source="tag4"
        reference="tags"
        label="tag 4"
        perPage={1000}
        validate={required()}
      >
        <SelectInput optionText="id" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
