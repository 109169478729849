import React from "react";
import { List, Datagrid, TextField, DateField, EmailField, BulkDeleteButton } from "react-admin";
import BulkLogoutButton from "../../components/bulkLogoutButton";

export default (props) => {
  const AdminBulkAction = (props) => (
    <React.Fragment>
      <BulkLogoutButton {...props} />
      {/* default bulk delete action */}
      <BulkDeleteButton {...props} />
    </React.Fragment>
  );
  return (
    <List {...props} bulkActionButtons={<AdminBulkAction />}>
      <Datagrid
        rowClick="edit"
        isRowSelectable={(record) => !["enoch", "admins"].includes(record.access)}
      >
        <TextField source="id" />
        <TextField source="name" />
        <EmailField source="email" />
        <TextField source="mobile" />
        <TextField source="access" />
        <DateField source="created_at" />
      </Datagrid>
    </List>
  );
};
