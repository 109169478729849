import React, { useState } from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceArrayInput,
  SelectArrayInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  required,
  BooleanInput,
} from "react-admin";
import uploadProps, { uploadMedia } from "../../../dataprovider/uploadProps";
import UploadProgress from "../../../components/uploadProgress";
import Toolbar from "../../../components/toolbar";
import RichTextInput from "../../../components/richInputText";
import CategoryReferenceInput from "../../../components/createButton";

const redirect = () => `/assets/create`;
export default ({ history, ...props }) => {
  const [progress, setProgress] = useState();
  const transform = async (data) => {
    const obj = data;
    if (data.image instanceof Object) {
      const resp = await uploadMedia(data.image, "Assets");
      obj.imagev2 = resp.data;
    }
    return await uploadProps(obj, setProgress, "ASSETS");
  };
  return (
    <>
      <Create {...props} transform={transform}>
        <SimpleForm toolbar={<Toolbar disabled={!!progress} />} redirect={redirect}>
          <TextInput source="id" validate={required()} />
          <TextInput source="name" />
          <CategoryReferenceInput
            source="category_id"
            reference="asset_categories"
            perPage={9999}
            field="name"
          />
          <ReferenceArrayInput perPage={99999} source="tags" reference="jaali_tags" label="Tags">
            <SelectArrayInput optionText="id" />
          </ReferenceArrayInput>
          <ImageInput
            source="image"
            accept="image/*"
            multiple={false}
            placeholder={<p>Drop your file here</p>}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <FileInput source="file" label="File">
            <FileField source="src" title="title" />
          </FileInput>

          <RichTextInput source="description" title="Description" />
          <BooleanInput source="display_on_app" />
          <BooleanInput source="is_premium" />
        </SimpleForm>
      </Create>
      <UploadProgress progress={progress} />
    </>
  );
};
