import React, { useState } from "react";
import { Button, useCreate, useNotify, useRefresh } from "react-admin";
import Add from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

function SubJobProductCreate({ id, history }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("sub_jobs");
  const [type, setType] = React.useState("");

  const handleChange = (event) => {
    setType(event.target.value);
  };
  const notify = useNotify();
  const refresh = useRefresh();
  // const redirect = useRedirect();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async () => {
    create(
      { payload: { data: { type, job_id: id, dimension: subjobConfig[type] } } },
      {
        onSuccess: () => {
          refresh();
          setShowDialog(false);
          history.push(`/jobs/${id}`);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  // function handleClick() {
  //   dataProvider
  //     .create("sub_jobs", { data: { job_id: id } })
  //     .then(() => {
  //       redirect(`/jobs/${id}/2`);
  //       refresh();
  //     })
  //     .catch(() => {
  //       notify("Error: Sub Job not created", "warning");
  //     });
  // }

  return (
    <>
      <Button onClick={handleClick} label="M Sub Job">
        <Add />
      </Button>
      <Dialog
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Jaali Product Subjob"
        style={{ minWidth: "200px" }}
      >
        <DialogTitle>Select Jaali Subjob Type</DialogTitle>
        <div style={{ padding: "20px", display: "flex", flexDirection: "column" }}>
          <InputLabel id="demo-simple-select-label">Select Sub Job type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            onChange={handleChange}
          >
            <MenuItem value="2_POINTS">REGULAR (WxH)</MenuItem>
            <MenuItem value="4_POINTS">L TYPE</MenuItem>
            <MenuItem value="6_POINTS">C TYPE</MenuItem>
            <MenuItem value="8_POINTS">FRAME TYPE</MenuItem>
          </Select>
        </div>
        <div style={{ padding: "20px", display: "flex", flexDirection: "column" }}>
          <Button label="Submit" onClick={handleSubmit} disabled={loading}></Button>
        </div>
      </Dialog>
    </>
  );
}

const subjobConfig = {
  "2_POINTS": {
    on: "mm",
    in: { width: 0, height: 0 },
    mm: { width: 0, height: 0 },
  },
  "4_POINTS": {
    on: "mm",
    in: { w1: 0, h1: 0, w2: 0, h2: 0 },
    mm: { w1: 0, h1: 0, w2: 0, h2: 0 },
  },
  "6_POINTS": {
    on: "mm",
    in: { w1: 0, h1: 0, w2: 0, h2: 0, w3: 0, h3: 0 },
    mm: { w1: 0, h1: 0, w2: 0, h2: 0, w3: 0, h3: 0 },
  },
  "8_POINTS": {
    on: "mm",
    in: { w1: 0, h1: 0, w2: 0, h2: 0, w3: 0, h3: 0, w4: 0, h4: 0 },
    mm: { w1: 0, h1: 0, w2: 0, h2: 0, w3: 0, h3: 0, w4: 0, h4: 0 },
  },
};

export default SubJobProductCreate;
