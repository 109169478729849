/* eslint-disable one-var */
/* eslint-disable one-var-declaration-per-line */
import React, { useEffect, useState } from "react";
import { useInput } from "react-admin";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const sanitizeObj = (val) => {
  let { in: inches = {}, mm = {} } = val || {};
  ["br", "bl", "bt", "bb"].map((e) => {
    delete inches[e];
    delete mm[e];
    return null;
  });
  return {
    ...val,
    in: inches,
    mm: mm,
  };
};

export default (props) => {
  const {
    input: { value: iValue, onChange },
  } = useInput(props);
  const { type, formProps } = props;

  const { change } = formProps.form;
  const value = sanitizeObj(iValue) || {
    on: "mm",
    in: { width: 0, height: 0 },
    mm: { width: 0, height: 0 },
  };

  const [sw, setSw] = useState(value.on || "mm");
  const toggle = (e) => {
    const s = e.target.checked ? "in" : "mm";
    setSw(s);
    onChange({
      ...value,
      on: e.target.checked ? "in" : "mm",
    });
  };

  useEffect(() => {
    setSw(value.on);
  }, [value]);
  const sqft = subjobConfig[type].calcSqft(value.in);

  useEffect(() => {
    change("sqft_req", sqft);
  }, []);

  const onChangeInput = (unit, name, e) => {
    let valMM, valIN;
    if (unit === "in") {
      valIN = parseFloat(e.target.value || 0);
      valMM = parseFloat((parseFloat(e.target.value || 0) * 25.4).toFixed(0));
    } else {
      valMM = parseFloat(e.target.value || 0);
      valIN = parseFloat((parseFloat(e.target.value || 0) * 0.0393701).toFixed(1));
    }

    const newVal = {
      ...value,
      in: { ...value.in, [name]: valIN },
      mm: { ...value.mm, [name]: valMM },
    };

    onChange(newVal);
    change("sqft_req", subjobConfig[type].calcSqft(newVal.in));
  };

  return (
    <Box flex={3} ml={{ xs: 0, sm: "0.5em" }}>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Typography variant="h6" style={{ marginRight: "10px" }}>
          SIZE
        </Typography>
        <FormControlLabel
          style={{ marginLeft: 30 }}
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <>
              <Typography>MM</Typography>
              <Switch checked={sw === "in"} onChange={toggle} color="primary" />
              <Typography>IN</Typography>
            </>
          }
        />
      </Box>
      <Box display={{ xs: "block", sm: "block" }}></Box>
      <Grid container spacing={2}>
        <Grid item xs={6} style={{ borderRight: "1px solid #afafaf" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">MM</Typography>
            </Grid>
            {value.mm &&
              Object.keys(value.mm).map((k) => (
                <Grid item xs={6} key={k}>
                  <TextField
                    label={`${k.toUpperCase()}`}
                    value={value.mm[k]}
                    onChange={(e) => onChangeInput("mm", k, e)}
                    type="number"
                    margin="dense"
                    variant="filled"
                    disabled={sw === "in"}
                    style={{ background: getInputColor(k) }}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">IN</Typography>
            </Grid>
            {value.in &&
              Object.keys(value.in).map((k) => (
                <Grid item xs={6} key={k}>
                  <TextField
                    label={`${k.toUpperCase()}`}
                    value={value.in[k]}
                    onChange={(e) => onChangeInput("in", k, e)}
                    type="number"
                    margin="dense"
                    variant="filled"
                    disabled={sw === "mm"}
                    style={{ background: getInputColor(k) }}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1}>
          <Box display={{ xs: "block", sm: "block" }}>
            <TextField
              label="Sq(ft)"
              value={sqft}
              disabled
              margin="dense"
              variant="filled"
              fullWidth
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const getInputColor = (k) => {
  if (k === "w1" || k === "width" || k === "height" || k === "h1") {
    return "#0e8dc444";
  }
  if (k === "w2" || k === "h2") {
    return "#b0cb1f44";
  }
  if (k === "w3" || k === "h3") {
    return "#e5087e44";
  }
  if (k === "w4" || k === "h4") {
    return "#fccb0344";
  }
};

const subjobConfig = {
  "2_POINTS": {
    calcSqft: (dimension) => {
      return parseFloat(((dimension.height * dimension.width) / 144).toFixed(1));
    },
  },
  "4_POINTS": {
    calcSqft: ({ w1, h1, w2, h2 }) => {
      const x1 = w1 * h1;
      const x2 = (w2 - w1) * h2;
      return parseFloat(((x1 + x2) / 144).toFixed(1));
    },
  },
  "6_POINTS": {
    calcSqft: ({ w1, h1, w2, h2, w3, h3 }) => {
      const x1 = w1 * h1;
      const x2 = (w2 - (w1 + w3)) * h2;
      const x3 = w3 * h3;
      return parseFloat(((x1 + x2 + x3) / 144).toFixed(1));
    },
  },
  "8_POINTS": {
    calcSqft: ({ w1, h1, w2, h2, w3, h3, w4, h4 }) => {
      const x1 = w1 * h1;
      const x2 = (w2 - (w1 + w3)) * h2;
      const x3 = w3 * h3;
      const x4 = (w4 - (w1 + w3)) * h4;
      return parseFloat(((x1 + x2 + x3 + x4) / 144).toFixed(1));
    },
  },
};
