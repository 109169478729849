import React from "react";
import {
  Edit,
  TextInput,
  TabbedForm,
  SelectArrayInput,
  SelectInput,
  required,
  BooleanInput,
  FormTab,
  regex,
  FormDataConsumer,
} from "react-admin";
import CreateButton from "./createButton";
import { makeStyles } from "@material-ui/core/styles";
import ClientList from "./clientList";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    display: "inline-flex",
    marginRight: theme.spacing(2),
    width: "260px",
  },
  fullInput: {
    width: "532x",
  },
}));

export default (props) => {
  const classes = useStyles();
  const validatePhone = [required(), regex(/^(\+91)?[789]\d{9}$/, "Must be a valid Phone Number")];
  return (
    <Edit {...props} undoable={false}>
      <TabbedForm>
        <FormTab label="Client Info">
          <TextInput source="first_name" formClassName={classes.input} />
          <TextInput source="last_name" formClassName={classes.input} />
          <TextInput source="firm_name" formClassName={classes.input} />
          <TextInput source="phone" validate={validatePhone} formClassName={classes.input} />
          <TextInput source="secondary_phone" formClassName={classes.input} />
          <TextInput source="email" formClassName={classes.input} />
          <TextInput source="secondary_email" formClassName={classes.input} />
          <SelectArrayInput
            source="title"
            choices={[
              { id: "Architect", name: "Architect" },
              { id: "Artist", name: "Artist" },
              { id: "Builder", name: "Builder" },
              { id: "Carpenter", name: "Carpenter" },
              { id: "Contractor", name: "Contractor" },
              { id: "Dealer", name: "Dealer" },
              { id: "End User", name: "End User" },
              { id: "Interior Designer", name: "Interior Designer" },
              { id: "Painter", name: "Painter" },
              { id: "Vendor", name: "Vendor" },
              { id: "Other", name: "Other" },
            ]}
            formClassName={classes.input}
          />
          <SelectInput
            source="source"
            label="Enquiry Source"
            choices={[
              { id: "India Mart", name: "India Mart" },
              { id: "Just Dial", name: "Just Dial" },
              { id: "Whatsapp", name: "Whatsapp" },
              { id: "Website", name: "Website" },
              { id: "Walk In", name: "Walk In" },
              { id: "Reference", name: "Reference" },
              { id: "Dealer", name: "Dealer" },
            ]}
            formClassName={classes.input}
          />
          <TextInput source="address" multiline formClassName={classes.input} />
          <TextInput source="gst" formClassName={classes.input} label="GSTIN" />
          <TextInput source="area" />
          <TextInput source="city" formClassName={classes.input} />
          <TextInput source="state" formClassName={classes.input} />
          <TextInput source="country" formClassName={classes.input} />
          <TextInput source="pincode" formClassName={classes.input} />
          <SelectInput
            source="paymentPriority"
            label="Payment Priority"
            choices={[
              {
                id: "ADVANCE",
                name: "Advance + Immediate Payment before delivery",
              },
              {
                id: "NOADVANCE",
                name: "Advance not needed + Payment to be followed up and collected later",
              },
              {
                id: "FOLLOWUP",
                name: "Payment to be followed up only by QMD & MMD",
              },
            ]}
            formClassName={classes.input}
          />
          <BooleanInput source="full_access" />
          <CreateButton source="ref" reference="ref_clients" perPage={9999} label="References" />
        </FormTab>
        <FormTab label="Client App Lists">
          <FormDataConsumer>
            {({ formData, ...rest }) => <ClientList clientId={formData.id} />}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
