import React from "react";
import { Edit, SimpleForm, required, TextInput } from "react-admin";

export default (props) => (
  <Edit {...props}>
    <SimpleForm redirect={`/testimonials`}>
      <TextInput source="name" validate={required()} />
      <TextInput source="title" validate={required()} />
      <TextInput source="company" validate={required()} />
      <TextInput source="testimonial" validate={required()} multiline />
    </SimpleForm>
  </Edit>
);
