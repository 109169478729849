import React, { useState, useCallback } from "react";
import {
  required,
  Button,
  SaveButton,
  NumberInput,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  SelectArrayInput,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

function ClientQuickCreateButton({ onChange }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("ref_clients");
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };
  const classes = useStyles();

  return (
    <>
      <Button onClick={handleClick} label="Create Ref">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create client"
      >
        <DialogTitle>Create client</DialogTitle>

        <FormWithRedirect
          resource="ref_clients"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput source="name" className={classes.input} />
                <TextInput source="firm_name" className={classes.input} />
                <NumberInput
                  min={10}
                  max={10}
                  source="phone"
                  validate={required()}
                  className={classes.input}
                />
                <TextInput source="email" className={classes.input} />
                <SelectArrayInput
                  source="title"
                  choices={[
                    { id: "Architect", name: "Architect" },
                    { id: "Artist", name: "Artist" },
                    { id: "Builder", name: "Builder" },
                    { id: "Carpenter", name: "Carpenter" },
                    { id: "Contractor", name: "Contractor" },
                    { id: "Dealer", name: "Dealer" },
                    { id: "End User", name: "End User" },
                    { id: "Interior Designer", name: "Interior Designer" },
                    { id: "Painter", name: "Painter" },
                    { id: "Vendor", name: "Vendor" },
                    { id: "Other", name: "Other" },
                  ]}
                  className={classes.input}
                />
                <SelectInput
                  source="source"
                  label="Enquiry Source"
                  choices={[
                    { id: "India Mart", name: "India Mart" },
                    { id: "Just Dial", name: "Just Dial" },
                    { id: "Whatsapp", name: "Whatsapp" },
                    { id: "Website", name: "Website" },
                    { id: "Walk In", name: "Walk In" },
                    { id: "Reference", name: "Reference" },
                    { id: "Dealer", name: "Dealer" },
                  ]}
                  className={classes.input}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    width: "520px",
  },
  input: {
    marginRight: theme.spacing(2),
    width: "260px",
  },
  fullInput: {
    width: "532x",
  },
}));

const ClientReferenceInput = (props) => {
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <div className={classes.root}>
      <ReferenceArrayInput key={version} {...props}>
        <AutocompleteArrayInput optionText="firm_name" optionValue="id" />
      </ReferenceArrayInput>
      <ClientQuickCreateButton onChange={handleChange} />
    </div>
  );
};

export default ClientReferenceInput;
