import React from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  NumberInput,
  required,
  BooleanInput,
} from "react-admin";

export default ({ history, ...props }) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <NumberInput source="id" helperText={false} validate={required()} />
      <TextInput
        source="type"
        format={(v) => (v ? v.toUpperCase() : "")}
        parse={(v) => (v ? v.toUpperCase() : "")}
        helperText={false}
        validate={required()}
      />
      <NumberInput
        source="thickness"
        helperText={false}
        validate={required()}
      />
      <TextInput source="color" helperText={false} />
      <NumberInput
        source="width"
        label="Width (in)"
        helperText={false}
        validate={required()}
      />
      <NumberInput
        source="height"
        label="Height (in)"
        helperText={false}
        validate={required()}
      />
      <NumberInput source="lwidth" label="L Width (in)" helperText={false} />
      <NumberInput source="lheight" label="L Height (in)" helperText={false} />
      <BooleanInput source="is_blocked" helperText={false} />
    </SimpleForm>
  </Create>
);
