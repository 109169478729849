import React from "react";
import { Button, ReferenceInput, AutocompleteInput } from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}));

const ClientReferenceInput = ({ history, optionRenderer, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ReferenceInput {...props} helperText={false}>
        <AutocompleteInput optionText={optionRenderer} />
      </ReferenceInput>

      <Button
        onClick={() => history.push("/vendors/create")}
        label="ra.action.create"
      >
        <IconContentAdd />
      </Button>
    </div>
  );
};

export default ClientReferenceInput;
