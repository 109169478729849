import * as React from "react";

import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, getResources, usePermissions } from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import LabelIcon from "@material-ui/icons/Label";
import SubMenu from "./subMenu";

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const [isOpen, setIsOpen] = React.useState(false);
  const { permissions } = usePermissions();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {resources
        .filter((r) => r.hasList)
        .map((resource) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={(resource.options && resource.options.label) || resource.name}
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
      {permissions === "jaali_admin" && (
        <SubMenu
          icon={<LabelIcon />}
          sidebarIsOpen={open}
          isOpen={isOpen}
          handleToggle={handleToggle}
          name="Reports"
          dense={false}
        >
          {reportRoutes.map((r) => (
            <MenuItemLink
              to={r.to}
              primaryText={r.text}
              leftIcon={<LabelIcon />}
              onClick={onMenuClick}
            />
          ))}
        </SubMenu>
      )}

      {isXSmall && logout}
    </div>
  );
};

const reportRoutes = [
  { to: "/process-vertical", text: "Process Vertical" },

  { to: "/material-sqft-report", text: "Material Sqft Vertical" },
  { to: "/job-payment-report", text: "Job Payments Vertical" },
];

export default Menu;
