import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

export default props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Name" />
      <EditButton />
    </Datagrid>
  </List>
);
