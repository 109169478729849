/* eslint-disable camelcase */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export default ({ dimensions }) => {
  const classes = useStyles();

  const dimensionIN = dimensions.in;
  const on = dimensions.on;

  const { w1, h1, w2, h2, w3, h3 } = dimensionIN || {};
  const { w1: wt1, h1: ht1, w2: wt2, h2: ht2, w3: wt3, h3: ht3 } = dimensions[dimensions.on] || {};
  if (w1 && h1 && w2 && h2 && w3 && h3) {
    const viewBox = getViewBox(h1, w2);
    const offset = 20;
    const textOffset = 10;
    console.log(offset);
    return (
      <svg viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
        {/* w1 - h1 */}
        <rect width={w1} height={h1} className={classes.fil1} x={offset} y={offset} />
        <text x={offset + w1 / 2} y={textOffset} className={classes.tfil1}>
          {wt1}
          {on}
        </text>
        <text
          x={textOffset}
          y={offset + h1 / 2}
          transform={`rotate(271 ${textOffset} ${offset + h1 / 2})`}
          className={classes.tfil1}
        >
          {ht1}
          {on}
        </text>
        {/* w2 - h2 */}
        <rect width={w2} height={h2} className={classes.fil2} y={h1 - h2 + offset} x={offset} />
        <text x={w2 / 2 + offset} y={h1 + h2 + textOffset} className={classes.tfil2}>
          {wt2}
          {on}
        </text>
        <text
          x={textOffset}
          y={offset + h1 - h2 / 2}
          transform={`rotate(271 ${textOffset} ${offset + h1 - h2 / 2})`}
          className={classes.tfil2}
        >
          {ht2}
          {on}
        </text>
        {/* w3 - h3 */}
        <rect width={w3} height={h3} className={classes.fil3} x={w2 - w1 + offset} y={offset} />
        <text x={offset + w2 - w3 / 2} y={textOffset} className={classes.tfil3}>
          {wt3}
          {on}
        </text>
        <text
          x={offset + w2 + textOffset}
          y={offset + h3 / 2}
          transform={`rotate(90 ${offset + w2 + textOffset} ${offset + h3 / 2})`}
          className={classes.tfil3}
        >
          {ht3}
          {on}
        </text>
      </svg>
    );
  }
  return (
    <img
      src={require(`../../../../../assets/6_POINTS.png`)}
      alt="img"
      style={{ padding: 10 }}
      width="100%"
    />
  );
};

const getViewBox = (w, h) => {
  return `0 0 ${h + 40} ${w + 40}`;
};

const useStyles = makeStyles(() => ({
  fil1: {
    fill: "none",
    stroke: "#00A0E3",
  },
  tfil1: {
    fill: "#00A0E3",
    fontSize: 5,
  },
  fil2: {
    fill: "none",
    stroke: "#B0CB1F",
    strokeWidth: 1.5,
  },
  tfil2: {
    fill: "#B0CB1F",
    fontSize: 5,
  },
  fil3: {
    fill: "none",
    stroke: "#E50974",
  },
  tfil3: {
    fill: "#E50974",
    fontSize: 5,
  },
}));
