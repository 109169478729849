import React from "react";
import { List, Datagrid, TextField, EditButton, Pagination } from "react-admin";

const PostPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100]} {...props} />
);

export default (props) => (
  <List {...props} pagination={<PostPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Name" />
      <TextField source="category" />
      <TextField source="priority" />
      <EditButton />
    </Datagrid>
  </List>
);
